import React from 'react'

import SignInPage from '../login/sign_in_page'
import ChangePwdPage from '../login/change_pwd_page'

import { Route, Switch, Redirect } from "react-router-dom";

export default function LoginRootPage(props) {
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Switch>
                <Route exact path='/login/sign_in' component={SignInPage} />
                <Route exact path='/login/change_pwd' component={ChangePwdPage} />
                <Route>
                    <Redirect to='/login/sign_in' />
                </Route>
            </Switch>
        </div>
    )
}