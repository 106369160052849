import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import IcBattery100 from '../../assets/battery/ic_battery_100.svg';
import IcBattery75 from '../../assets/battery/ic_battery_75.svg';
import IcBattery50 from '../../assets/battery/ic_battery_50.svg';
import IcBattery25 from '../../assets/battery/ic_battery_25.svg';
import IcBatteryAlert from '../../assets/battery/ic_battery_alert.svg';

// function getBatteryIcon(level) {
//     if (level >= 90) return IcBattery100;
//     else if (level >= 75) return IcBattery75;
//     else if (level >= 50) return IcBattery50;
//     else if (level >= 25) return IcBattery25;
//     return IcBatteryAlert;
// }
function getBatteryIcon(level) {
    if (level >= 75) return IcBattery100;
    else if (level >= 50) return IcBattery75;
    else if (level >= 25) return IcBattery50;
    else if (level >= 10) return IcBattery25;
    return IcBatteryAlert;
}

BatteryIcon.propsType = {
    level: PropTypes.number.isRequired,
}

export default function BatteryIcon(props) {

    const classes = useStyles(props);
    const [blinkAnim, setBlinkAnim] = useState(false);

    useEffect(() => {
        let timeout;
        setBlinkAnim(false);
        if (props.level < 25) {
            timeout = setTimeout(() => {
                setBlinkAnim(true);
            }, 500);
        }
        return () => {
            clearTimeout(timeout);
        }
    }, [props.level]);

    return (
        <img src={getBatteryIcon(props.level)}
            alt='icon_battery'
            style={props.style}
            className={`${props.className} ${blinkAnim ? classes.blink : ''}`} />
    );
}

const useStyles = makeStyles({
    '@keyframes blink-animation': {
        '0%': { opacity: 0, },
        '100%': { opacity: 1, },
    },
    blink: {
        animationName: '$blink-animation',
        animationDuration: '0.7s',
        animationTimingFunction: 'ease-in-out',
        animationIterationCount: 'infinite',
        animationDirection: 'alternate',
    },
});