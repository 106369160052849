import React, { useEffect, useState, useRef, Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import intl from 'react-intl-universal'

import * as Rx from 'rxjs';
import { ManagementContext } from '../../../utils/context_utils';
import AddPadDialogBloc from '../../../bloc/main/management/add_pad_dialog_bloc';
import Gender from '../../../model/data/gender';
import PadConfig from '../../../model/data/pad_config';

import * as Dimens from '../../../styles/dimens';
import * as Colors from '../../../styles/colors';
import Stack from '../../../components/layout/stack';
import Column from '../../../components/layout/column';
import Row from '../../../components/layout/row';
import PadConfigWidget from '../../../components/widgets/pad_config_widget';

import BlocBuilder from 'bloc-builder-react';
import { Dialog, ButtonBase } from '@material-ui/core';
import Lottie from 'react-lottie';

import AnimLoading from '../../../assets/lottie/textfield_loading.json';

var startsWith = require('starts-with');

AddPadDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
    onPadAdded: PropTypes.func,
}

const _bloc = new AddPadDialogBloc();
let _disposables = new Rx.Subscription();

const AddPadDialogId = {
    btnSave: 0,
};

export default function AddPadDialog(props) {
    const classes = useStyles(props);
    const isWeb = useMediaQuery('(min-width: 768px)');

    const _managementBloc = useContext(ManagementContext).bloc;
    const [init, setInit] = useState(false);

    let _config = useRef(new PadConfig(
        '', '', Gender.None,
        {
            day: { leave: true, side: true, sit: false },
            noon: { leave: true, side: true, sit: false },
            night: { leave: true, side: true, sit: false },
        },
        {
            range: { allDay: true, from: 480, to: 1200 },
            lie: { checked: false, threshold: 60 },
            over: { checked: false, threshold: 50 },
            lower: { checked: false, threshold: 20, duration: 120 },
            rollOver: { checked: false, threshold: 20, duration: 120 },
        }
    ));

    useEffect(() => {
        if (props.open) {
            console.log(`AddPadDialog init`);
            _disposables = new Rx.Subscription();
            _bloc.init(_managementBloc);
            _setPageStateObserver();
            setInit(true);
        } else if (init) {
            console.log(`AddPadDialog deinit`);
            _bloc.dispose();
            _disposables.unsubscribe();
            setInit(false);
        }
        return () => {
        };
    }, [props.open]);

    /* --- Functions --- */

    const _btnClicked = (id) => () => {
        switch (id) {
            case AddPadDialogId.btnSave:
                _config.current.deviceId = "WhizPad_RC-" + _bloc.deviceId.getValue().toUpperCase();
                _bloc.addPad(_config.current);
                break;
            default:
                break;
        }
    };

    const isDataUpdated = (isUpdated, config) => {
        // _bloc.updateData(props.index, config);
        _config.current = config;
    };

    function _setPageStateObserver() {
        let it = _bloc.pageState
            .subscribe({
                next: state => {
                    if (state !== 2) return;
                    if (props.onPadAdded !== undefined)
                        props.onPadAdded(_config.current);
                    props.onClose();
                }
            });
        _disposables.add(it);
    }

    return (() => {
        if (!init) return null;
        const height = Dimens.addPadDialogHeight;
        const width = isWeb ? Dimens.addPadDialogWidthL : Dimens.addPadDialogWidth;
        const inputWidth = width - 2 * Dimens.marginM;
        return (
            <Dialog onClose={props.onClose} open={props.open}>
                <Stack width={`${width}px`} height={`${height}px`} className={classes.root}>
                    <BlocBuilder
                        initialValue={_bloc.pageState.getValue()}
                        subject={_bloc.pageState}
                        builder={(snapshot) => {
                            const state = snapshot.data;
                            return (
                                <Fragment>
                                    <Column width={`${width}px`} height='auto' className={classes.contentLayout}
                                        style={{ visibility: state === 0 ? 'visible' : 'hidden' }}>
                                        <Row width='100%' height='auto' className={classes.titleLayout}>
                                            <span className={`${classes.titleText}`}>{intl.get('dialog_add_pad_title')}</span>
                                            <BlocBuilder
                                                initialValue={_bloc.errText.getValue()}
                                                subject={_bloc.errText}
                                                builder={(snapshot) => {
                                                    return <span className={classes.errorText} >{snapshot.data}</span>;
                                                }} />
                                        </Row>
                                        <BlocBuilder
                                            initialValue={_bloc.isDeviceIdValid.getValue()}
                                            subject={_bloc.isDeviceIdValid}
                                            builder={(snapshot) => {
                                                const { code } = snapshot.data;
                                                let errText = '';
                                                switch (code) {
                                                    case -1:
                                                        errText = intl.get('dialog_add_pad_message_id_invalid');
                                                        break;
                                                    case -2:
                                                        errText = intl.get('dialog_add_pad_message_id_exist');
                                                        break;
                                                    default:
                                                        break;
                                                }
                                                return (
                                                    <Row width='100%' height='auto' className={classes.deviceIdTitleLayout} >
                                                        <span className={`${classes.deviceIdTitleText}`}>{intl.get('dialog_add_pad_id_title')}</span>
                                                        <span className={classes.errorText} >{errText}</span>
                                                    </Row>
                                                );
                                            }} />
                                        <BlocBuilder
                                            initialValue={_bloc.deviceId.getValue()}
                                            subject={_bloc.deviceId}
                                            builder={(snapshot) => {
                                                return (
                                                    <RoomTextField
                                                        className={classes.deviceIdInput}
                                                        width={`${inputWidth}px`}
                                                        value={snapshot.data}
                                                        onChange={(event) => {
                                                            _bloc.deviceId.next(event.target.value);
                                                        }} />
                                                );
                                            }} />
                                        <PadConfigWidget
                                            width={width} height={Dimens.padConfigHeight - Dimens.padConfigHeader}
                                            data={_config.current}
                                            onDataUpdated={isDataUpdated}
                                            isHeader={false}
                                            isOtherSetting={false} />
                                        <div className={classes.divider} />
                                        <BlocBuilder
                                            initialValue={_bloc.isDeviceIdValid.getValue()}
                                            subject={_bloc.isDeviceIdValid}
                                            builder={(snapshot) => {
                                                const enable = snapshot.data.code === 1;
                                                return (
                                                    <ButtonBase
                                                        disabled={!enable}
                                                        className={classes.btnSave}
                                                        onClick={_btnClicked(AddPadDialogId.btnSave)} >
                                                        <span
                                                            className={classes.btnSaveText}
                                                            style={{ color: enable ? Colors.accent : Colors.textBLight }}>
                                                            {intl.get('save')}
                                                        </span>
                                                    </ButtonBase>
                                                );
                                            }} />
                                    </Column>
                                    <Column width='100%' height='100%' className={classes.loadingLayout}
                                        style={{ visibility: state === 1 ? 'visible' : 'hidden' }}>
                                        <Lottie
                                            isClickToPauseDisabled
                                            options={{
                                                loop: true,
                                                autoplay: true,
                                                animationData: AnimLoading,
                                                rendererSettings: {
                                                    preserveAspectRatio: 'xMidYMid slice'
                                                }
                                            }}
                                            width='50%'
                                            height='auto' />
                                        <span className={classes.loadingText}>{intl.get('loading_add')}</span>
                                    </Column>
                                </Fragment>
                            )
                        }} />
                </Stack>
            </Dialog>
        );
    })();
}

const useStyles = makeStyles({
    root: {},
    contentLayout: {
        position: 'absolute',
        top: '0px',
        left: '0px'
    },
    titleLayout: {
        justifyContent: 'space-between',
        marginTop: `${Dimens.marginM}px`,
        paddingLeft: `${Dimens.marginM}px`,
        paddingRight: `${Dimens.marginM}px`,
    },
    titleText: {
        fontSize: Dimens.textM,
        color: Colors.textBDark,
        fontWeight: 'bold',
    },
    deviceIdTitleLayout: {
        justifyContent: 'space-between',
        marginTop: `${Dimens.marginM}px`,
        paddingLeft: `${Dimens.marginM}px`,
        paddingRight: `${Dimens.marginM}px`,
    },
    deviceIdTitleText: {
        fontSize: Dimens.textS,
        color: Colors.textBLight,
        fontWeight: 'bold',
    },
    deviceIdInput: {
        marginLeft: `${Dimens.marginM}px`
    },
    errorText: {
        marginLeft: `${Dimens.marginM}px`,
        fontSize: Dimens.textS,
        color: Colors.textError,
    },
    divider: {
        width: '100%',
        height: `${Dimens.divider}px`,
        backgroundColor: Colors.dividerNormal,
    },
    btnSave: {
        width: '100%',
        height: `${Dimens.btnSize}px`,
    },
    btnSaveText: {
        width: '100%',
        color: Colors.accent,
        fontSize: Dimens.textM,
        fontWeight: 'bold',
    },
    loadingLayout: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loadingText: {
        color: Colors.textBLight,
        fontSize: Dimens.textM,
        fontWeight: 'bold',
        marginTop: `${Dimens.marginM}px`,
    }
});

const RoomTextField = styled.input`
    /* Remove First */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);

    box-sizing: border-box;
    height: 35px;
    width: ${props => props.width};
    font-size: ${Dimens.textS};
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    border-color: ${Colors.dividerNormal};
    outline: none;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: ${Dimens.marginS}px;
`;