import * as Rx from 'rxjs'
import { skip, debounceTime, tap, map, switchMap } from 'rxjs/operators'
import intl from 'react-intl-universal'

import * as LoginApi from '../../model/api/login_api'

class ChangePwdBloc {

    get btnClicked() { return this._btnClicked }
    get companyName() { return this._companyName }
    get companyNameAnim() { return this._companyNameAnim }
    get pwd() { return this._pwd }
    get pwdAnim() { return this._pwdAnim }
    get pwdAgain() { return this._pwdAgain }
    get pwdAgainAnim() { return this._pwdAgainAnim }
    get confirmAvailable() { return this._confirmAvaliable }
    get errorText() { return this._errorText }

    get backPage() { return this._backPage }
    get result() { return this._result }
    get loadingDialog() { return this._loadingDialog }

    init() {
        this._disposables = new Rx.Subscription()

        this._btnClicked = new Rx.Subject()
        this._companyName = new Rx.BehaviorSubject('')
        this._companyNameAnim = new Rx.BehaviorSubject('none')
        this._pwd = new Rx.BehaviorSubject('')
        this._pwdAnim = new Rx.BehaviorSubject('none')
        this._pwdAgain = new Rx.BehaviorSubject('')
        this._pwdAgainAnim = new Rx.BehaviorSubject('none')
        this._confirmAvaliable = new Rx.BehaviorSubject(false)
        this._errorText = new Rx.BehaviorSubject('')

        this._backPage = new Rx.Subject()
        this._result = new Rx.Subject()
        this._loadingDialog = new Rx.BehaviorSubject({ show: false, text: intl.get('loading_save') })

        this._setBtnClickedObserver()
        this._setConfirmAvailableObserver()
    }

    dispose() {
        this._disposables.unsubscribe()
    }

    _setBtnClickedObserver() {
        let it = this._btnClicked.subscribe({
            next: id => {
                switch (id) {
                    case ChangePwdPageId.BtnConfirm:
                        this._changePwd(this._companyName.getValue(), this._pwd.getValue())
                        break
                    case ChangePwdPageId.BtnCancel:
                        this._backPage.next(true)
                        break
                    default:
                        break
                }
            }
        })
        this._disposables.add(it)
    }

    _setConfirmAvailableObserver() {
        let it = Rx.combineLatest(this._getCompanyNameObservable(), this._getPwdObservable(), this._getPwdAgainObservable())
            .subscribe({
                next: ([accountAvailable, pwdAvailable, pwdAgainAvailable]) => {
                    console.log(`is available account: ${accountAvailable}, pwd: ${pwdAvailable}`);
                    this._confirmAvaliable.next(accountAvailable && pwdAvailable && pwdAgainAvailable);
                },
                error: (err) => {
                    this._confirmAvaliable.next(false);
                }
            });
        this._disposables.add(it);
    }

    _getCompanyNameObservable() {
        return this._companyName.pipe(
            skip(1),
            debounceTime(1000),
            tap(name => this._companyNameAnim.next('loading')),
            map(name => name.trim().length > 0),
            tap(available => this._companyNameAnim.next(available ? 'success' : 'failure'))
        )
    }

    _getPwdObservable() {
        return this._pwd.pipe(
            skip(1),
            debounceTime(1000),
            tap(pwd => this._pwdAnim.next('loading')),
            switchMap(pwd => LoginApi.checkPwd(pwd)),
            tap(available => this._pwdAnim.next(available ? 'success' : 'failure'))
        )
    }

    _getPwdAgainObservable() {
        return this._pwdAgain.pipe(
            skip(1),
            debounceTime(1000),
            tap(pwd => this._pwdAgainAnim.next('loading')),
            map(pwd => pwd === this._pwd.getValue()),
            tap(available => this._pwdAgainAnim.next(available ? 'success' : 'failure'))
        )
    }

    _changePwd(name, pwd) {
        this._loadingDialog.next({ show: true, text: intl.get('loading_save') })
        let it = LoginApi.changeNewPwd(name, pwd)
            .subscribe({
                next: result => {
                    this._result.next(result)
                },
                error: err => {
                    this._loadingDialog.next({ show: false, text: intl.get('loading_save') })
                    this.errorText.next(intl.get('page_change_pwd_message_error'))
                    this._result.next(false)
                },
                complete: () => {
                    this._loadingDialog.next({ show: false, text: intl.get('loading_save') })
                }
            })
        this._disposables.add(it)
    }
}

class ChangePwdPageId {
    static get BtnCancel() { return 'btn_cancel' }
    static get BtnConfirm() { return 'btn_confirm' }
}

export { ChangePwdBloc as default, ChangePwdPageId }