import * as Rx from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import * as Strings from '../../../styles/strings';
import * as SettingsApi from '../../../model/api/settings_api';

var startsWith = require('starts-with');

export default class AddPadDialogBloc {

    get pageState() { return this._pageState; }

    get deviceId() { return this._deviceId; }
    get isDeviceIdValid() { return this._isDeviceIdValid; }

    get errText() { return this._errText; }

    init(managementBloc) {
        this._managementBloc = managementBloc;
        this._disposables = new Rx.Subscription();

        /* 0: 資料填寫, 1: 新增中, 2: 新增完成 */
        this._pageState = new Rx.BehaviorSubject(0);

        this._deviceId = new Rx.BehaviorSubject('');
        /* -2: 已有裝置, -1: 格式錯誤, 0: 空白, 1: 有效 */
        this._isDeviceIdValid = new Rx.BehaviorSubject({ code: 0 });

        this._errText = new Rx.BehaviorSubject('');

        this._setDeviceIdObserver();
    }

    dispose() {
        this._disposables.unsubscribe();
    }

    _setDeviceIdObserver() {
        let it = this._deviceId.pipe(
            debounceTime(1000),
        ).subscribe({
            next: id => {
                const idStr = String(id).trim();
                if (idStr === "")
                    this._isDeviceIdValid.next({ code: 0 });
                // else if (!startsWith(idStr, "WhizPad_RC-"))
                //     this._isDeviceIdValid.next({ code: -1 });
                // else if (idStr.split('-')[1].length !== 12)
                //     this._isDeviceIdValid.next({ code: -1 });
                else if (idStr.length !== 12)
                    this._isDeviceIdValid.next({ code: -1 });
                else {
                    let isExist = false;
                    this._managementBloc.configs.getValue().forEach(config => {
                        if (config.macAddress.toUpperCase() === idStr.toUpperCase()) {
                            isExist = true;
                        }
                    });
                    if (isExist) {
                        this._isDeviceIdValid.next({ code: -2 });
                    } else {
                        this._isDeviceIdValid.next({ code: 1 });
                    }
                }
            },
            error: err => {

            }
        });
        this._disposables.add(it);
    }

    addPad(config) {
        this._pageState.next(1);
        let it = SettingsApi.addConfig(config)
            .subscribe({
                next: result => {

                },
                error: err => {
                    this._pageState.next(0);
                    this._errText.next(Strings.error_unknown);
                },
                complete: () => {
                    this._pageState.next(2);
                }
            });
        this._disposables.add(it);
    }
}