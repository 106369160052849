import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

import PadEvent from '../../model/data/pad_event';
import Gender from '../../model/data/gender'

import * as Dimens from '../../styles/dimens';
import * as Colors from '../../styles/colors';
import Stack from '../layout/stack';
import Column from '../layout/column';
import Row from '../layout/row';

import IcPadMSitSideLeft from '../../assets/pad/ic_pad_m_sit_side_left.svg';
import IcPadMSitSideRight from '../../assets/pad/ic_pad_m_sit_side_right.svg';
import IcPadMSit from '../../assets/pad/ic_pad_m_sit.svg';
import IcPadMLay from '../../assets/pad/ic_pad_m_lay.svg';
import IcPadFSitSideLeft from '../../assets/pad/ic_pad_f_sit_side_left.svg'
import IcPadFSitSideRight from '../../assets/pad/ic_pad_f_sit_side_right.svg'
import IcPadFSit from '../../assets/pad/ic_pad_f_sit.svg'
import IcPadFLay from '../../assets/pad/ic_pad_f_lay.svg'

const Color = require('color');

PadSensorWidget.propsType = {
    // -1: 100%, -2: auto, 其餘為px
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    firmwareVer: PropTypes.number,
    online: PropTypes.bool,
    event: PropTypes.instanceOf(PadEvent),
    sensors: PropTypes.array,
    gender: PropTypes.any,
}

PadSensorWidget.defaultProps = {
    width: -1,
    height: -1,
    firmwareVer: 0,
    online: false,
    event: PadEvent.Sit,
    sensors: '',
    gender: Gender.Male,
}

function getSizeStr(size) {
    switch (size) {
        case -1: return '100%';
        case -2: return 'auto';
        default: return `${size}px`;
    }
}

function getSensors(firmwareVer, online, event, width, height, sensors, clz) {
    let items = [];
    for (let r = 0; r < 6; r++) {
        let colItems = [];
        for (let c = 0; c < 5; c++) {
            const index = r * 5 + c;
            let sec = sensors[index];
            if (firmwareVer >= 3) {
                switch (sensors[index]) {
                    case 2:
                        sec = 60 * 60;
                        break;
                    case 3:
                        sec = 120 * 60
                        break;
                    default:
                        break;
                }
            }
            const color = sec > 0 && event.code !== PadEvent.Empty.code ? colorGradient(sec / (2 * 60 * 60)) : '#DCF3F9';
            colItems.push(<SensorItem key={index} online={online} color={color} />)
        }
        items.push(<Row key={`row_${r}`} width='100%' height={`${height}px`} className={clz.sensorRow} >{colItems}</Row>);
    }
    return items;
}

function colorGradient(percentage) {
    const rgbColor1 = Color('#8FB9D0');
    const rgbColor2 = Color('#FBC68F');
    const rgbColor3 = Color('#EF6567');
    let fadeFraction = percentage;
    if (fadeFraction > 1) fadeFraction = 1;
    if (fadeFraction < 0) fadeFraction = 0;
    let color1 = rgbColor1;
    let color2 = rgbColor2;
    let fade = fadeFraction;

    // Do we have 3 colors for the gradient? Need to adjust the params.
    if (rgbColor3) {
        fade = fade * 2;

        // Find which interval to use and adjust the fade percentage
        if (fade >= 1) {
            fade -= 1;
            color1 = rgbColor2;
            color2 = rgbColor3;
        }
    }

    var diffRed = color2.red() - color1.red();
    var diffGreen = color2.green() - color1.green();
    var diffBlue = color2.blue() - color1.blue();

    var gradient = {
        red: parseInt(Math.floor(color1.red() + (diffRed * fade)), 10),
        green: parseInt(Math.floor(color1.green() + (diffGreen * fade)), 10),
        blue: parseInt(Math.floor(color1.blue() + (diffBlue * fade)), 10),
    };

    return Color('rgb(' + gradient.red + ',' + gradient.green + ',' + gradient.blue + ')').hex();
}

export default function PadSensorWidget(props) {
    const classes = useStyles(props);

    useEffect(() => {
    }, []);

    const getEventImg = (online, event, gender) => {
        // const code = event.code;
        // return (
        //     <Fragment>
        //         <img src={IcPadMSitSideLeft} alt='icon_pad_f_sit_side_left' className={classes.eventImgLeft}
        //             style={{ visibility: online && code === PadEvent.SitSideLeft.code ? 'visible' : 'hidden' }} />
        //         <img src={IcPadMSitSideRight} alt='icon_pad_f_sit_side_right' className={classes.eventImgRight}
        //             style={{ visibility: online && code === PadEvent.SitSideRight.code ? 'visible' : 'hidden' }} />
        //         <img src={IcPadMSit} alt='icon_pad_f_sit' className={classes.eventImgSit}
        //             style={{ visibility: online && code === PadEvent.Sit.code ? 'visible' : 'hidden' }} />
        //         <img src={IcPadMLay} alt='icon_pad_f_lay' className={classes.eventImgLay}
        //             style={{ visibility: online && code === PadEvent.Lay.code ? 'visible' : 'hidden' }} />
        //     </Fragment>
        // )
        if (!online) return null;
        switch (event.code) {
            case PadEvent.SitSideLeft.code:
                return <img
                    src={gender.code === Gender.Female.code ? IcPadFSitSideLeft : IcPadMSitSideLeft}
                    alt='icon_pad_sit_side_left'
                    className={classes.eventImgLeft} />
            case PadEvent.SitSideRight.code:
                return <img
                    src={gender.code === Gender.Female.code ? IcPadFSitSideRight : IcPadMSitSideRight}
                    alt='icon_pad_sit_side_right'
                    className={classes.eventImgRight} />
            case PadEvent.Sit.code:
                return <img
                    src={gender.code === Gender.Female.code ? IcPadFSit : IcPadMSit}
                    alt='icon_pad_sit'
                    className={classes.eventImgSit} />;
            case PadEvent.Lay.code:
                return <img
                    src={gender.code === Gender.Female.code ? IcPadFLay : IcPadMLay}
                    alt='icon_pad_lay'
                    className={classes.eventImgLay} />;
            default: return null;
        }
    }

    return (() => {
        const width = getSizeStr(props.width);
        const height = getSizeStr(props.height);
        const sensorWidth = props.width * 0.7;
        const itemWidth = sensorWidth / 5;
        const itemHeight = itemWidth * 1.3;
        return (
            <Stack width={width} height={height} >
                <Column width={`${sensorWidth}px`} height='auto' className={classes.sensorsLayout} >
                    {getSensors(props.firmwareVer, props.online, props.event, itemWidth, itemHeight, props.sensors, classes)}
                </Column>
                {getEventImg(props.online, props.event, props.gender)}
            </Stack>
        );
    })();
}

const useStyles = makeStyles({
    sensorsLayout: {
        position: 'absolute',
        top: '0%',
        left: props => `${props.width * 0.15}px`,
    },
    sensorRow: {
        paddingTop: '2px',
        paddingBottom: '2px',
    },
    sensorItem: {
        flex: '1',
        width: 'auto',
        height: 'auto',
        backgroundColor: '#DCF3F9',
        margin: '2px',
        borderRadius: '3px',
    },
    eventImgLeft: {
        position: 'absolute',
        top: '25%',
        left: '5%',
        width: '50%',
        height: '50%',
        zIndex: 1,
        opacity: 0.8,
    },
    eventImgRight: {
        position: 'absolute',
        top: '25%',
        right: '5%',
        width: '50%',
        height: '50%',
        zIndex: 1,
        opacity: 0.8,
    },
    eventImgSit: {
        position: 'absolute',
        top: '30%',
        left: '20%',
        width: '60%',
        height: '60%',
        zIndex: 1,
        opacity: 0.8,
    },
    eventImgLay: {
        position: 'absolute',
        top: '7.5%',
        left: '7.5%',
        width: '85%',
        height: '85%',
        zIndex: 1,
        opacity: 0.8,
    },
});

const SensorItem = styled.div`
    flex: 1;
    width: auto;
    height: 100%;
    background-color: ${props => props.online ? props.color : '#c0c0c0'};
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 3px;
    box-sizing: border-box;
`;