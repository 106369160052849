import React, { useContext, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import intl from 'react-intl-universal'

import * as Rx from 'rxjs'
import * as PadApi from '../model/api/pad_api'
import { HomeContext } from '../utils/context_utils';

import * as Dimens from '../styles/dimens'
import * as Colors from '../styles/colors'
import Column from '../components/layout/column'
import Row from '../components/layout/row'

import BlocBuilder from 'bloc-builder-react'
import { Dialog, IconButton } from '@material-ui/core';
import { FixedSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer';
import moment from 'moment'

import IcEvent from '@material-ui/icons/EventRounded';

NotificationsHistoryDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    config: PropTypes.any,
}

export default function NotificationsHistoryDialog(props) {

    const css = useStyles(props)
    const isWeb = useMediaQuery('(min-width: 768px)')
    const _bloc = useContext(HomeContext).bloc
    const _disposables = useRef(new Rx.Subscription())
    const _notifications = useRef(new Rx.BehaviorSubject([]))

    useEffect(() => {
        _disposables.current = new Rx.Subscription()
        const disposable = _disposables.current
        if (props.open) {
            console.log(`notifications history dialog init`)
            _setDateObserver()
            _getNotificationsHistory(_bloc.notiHistoryDate.getValue())
        }
        return () => {
            disposable.unsubscribe()
        }
    }, [props.open])

    const _getNotificationsHistory = (date) => {
        _notifications.current.next([])
        let it = PadApi.notificationsHistory(props.config.deviceId, moment(date).format('YYYY/MM/DD')).subscribe({
            next: (data) => {
                _notifications.current.next(data)
            },
            error: err => { },
            complete: () => { }
        })
        _disposables.current.add(it)
    }

    const _setDateObserver = () => {
        let it = _bloc.notiHistoryDate.subscribe({
            next: date => {
                _getNotificationsHistory(date)
            },
            error: err => { },
            complete: () => { }
        })
        _disposables.current.add(it)
    }

    return (() => {
        if (props.config === undefined) return null
        return (
            <Dialog onClose={props.onClose} open={props.open} scroll='paper' >
                <Column
                    width={`${isWeb ? Dimens.notiHistoryDialogWidthL : Dimens.notiHistoryDialogWidth}px`}
                    height='100%'
                    className={css.root} >
                    <Row width='100%' height={`${Dimens.notiHistoryDialogTitle}px`} className={css.titleLayout} >
                        <span className={css.titleText} >{intl.get('dialog_notifications_history_title')} - {props.config.name}</span>
                        <SvgIconButton onClick={() => { _bloc.datePickerDialog.next({ show: true, date: _bloc.notiHistoryDate.getValue() }) }}><IcEvent className={css.titleDateBtn} /></SvgIconButton>
                    </Row>
                    <div className={css.divider} />
                </Column>
                <Column width='100%' height='55vh'>
                    <AutoSizer>
                        {({ height, width }) => {
                            return (
                                <BlocBuilder
                                    initialValue={_notifications.current.getValue()}
                                    subject={_notifications.current}
                                    builder={(snapshot) => {
                                        const notifications = snapshot.data
                                        return (
                                            <List width={width} height={height - 1}
                                                itemSize={Dimens.notiHistoryDialogItem}
                                                itemCount={notifications.length}
                                                itemData={notifications} >
                                                {({ index, style, data }) => (
                                                    <div style={style} >
                                                        <Row width='100%' height='100%' className={css.itemLayout}>
                                                            <span className={css.itemEventText} style={{ color: data[index].notification.color }} >{`${data[index].notification.name} ${intl.get('alert')}`}</span>
                                                            <span className={css.itemDateTimeText} >{moment(data[index].epoch).format('YYYY/MM/DD HH:mm:ss')}</span>
                                                        </Row>
                                                    </div>
                                                )}
                                            </List>
                                        )
                                    }} />
                            )
                        }}
                    </AutoSizer>
                </Column>
            </Dialog>
        )
    })()
}

const useStyles = makeStyles({
    root: {
        padding: `0px ${Dimens.marginM}px`,
    },
    titleLayout: {
        alignItems: 'center',
    },
    titleText: {
        flex: 1,
        fontSize: Dimens.textL,
        fontWeight: 'bold',
        color: Colors.textBNormal,
    },
    titleDateBtn: {
        width: `${Dimens.historyChartHeaderBarIconBtn}px`,
        height: `${Dimens.historyChartHeaderBarIconBtn}px`,
        color: Colors.iconDefault,
    },
    divider: {
        width: '100%',
        height: `${Dimens.divider}px`,
        backgroundColor: Colors.dividerNormal,
    },
    itemLayout: {
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `0px ${Dimens.marginM}px`,
    },
    itemEventText: {
        fontSize: Dimens.textM,
        fontWeight: 'bold',
    },
    itemDateTimeText: {
        fontSize: Dimens.textXS,
        color: Colors.textBXLight,
    }
})

const SvgIconButton = styled(IconButton)`
    && {
        align-self: center;
        padding: ${Dimens.historyChartHeaderBarIconBtnPadding}px;
    }
    &:focus {
        outline: none;
    }
`;