import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import * as Dimens from '../../../styles/dimens';
import * as Colors from '../../../styles/colors';
import Row from '../../layout/row';

ChartLegend.propTypes = {
    color: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
}

export default function ChartLegend(props) {

    const css = useStyles(props);

    return (
        <Row width='auto' height='auto' className={`${css.root} ${props.className}`}>
            <div className={css.indicator} />
            <span className={css.text} >{props.text}</span>
        </Row>
    );
}

const useStyles = makeStyles({
    root: {
        alignItems: 'center',
    },
    indicator: {
        width: `${Dimens.chartLegendIndicator}px`,
        height: `${Dimens.chartLegendIndicator}px`,
        backgroundColor: props => props.color,
        borderRadius: `${Dimens.chartLegendIndicator / 2}px`,
    },
    text: {
        marginLeft: `${Dimens.marginM}px`,
        fontWeight: 'bold',
        fontSize: Dimens.textS,
        color: Colors.textBNormal,
    }
});

