import * as LocalServer from "./local/login_api"
import * as AWSServer from './aws/login_api'

export function isLoggedIn() {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.isLoggedIn() : AWSServer.isLoggedIn();
}

export function getUserId() {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.getUserId() : undefined;
}

export function checkAccountExist(email) {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.checkAccountExist(email) : AWSServer.checkAccountExist(email);
}

export function checkPwd(pwd) {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.checkPwd(pwd) : AWSServer.checkPwd(pwd);
}

export function signIn(email, pwd) {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.signIn(email, pwd) : AWSServer.signIn(email, pwd);
}

export function signOut() {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.signOut() : AWSServer.signOut();
}

export function changeNewPwd(name, pwd) {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.changeNewPwd(name, pwd) : AWSServer.changeNewPwd(name, pwd);
}

export function refreshSession() {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.refreshSession() : AWSServer.refreshSession();
}

export function currentCredentials() {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.currentCredentials() : AWSServer.currentCredentials();
}

export function attachAwsIotPolicy(identityId) {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.attachAwsIotPolicy(identityId) : AWSServer.attachAwsIotPolicy(identityId);
}