import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import intl from 'react-intl-universal'

import * as Colors from '../../styles/colors';
import * as Dimens from '../../styles/dimens';
import Row from '../layout/row';

import { Button } from '@material-ui/core';

const PadAlertSelectorMode = {
    leave: { code: 0, nameId: 'pad_config_widget_stage_alert_off_bed', color: Colors.padEventBlue },
    side: { code: 1, nameId: 'pad_config_widget_stage_alert_side', color: Colors.padEventRed },
    sit: { code: 2, nameId: 'pad_config_widget_stage_alert_sit', color: Colors.padEventGreen },
}

PadAlertSelector.propTypes = {
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    selected: PropTypes.array.isRequired,
    onChange: PropTypes.func,
}

PadAlertSelector.defaultProps = {
    width: `${Dimens.padAlertSelectorWidth}px`,
    height: `${Dimens.padAlertSelectorHeight}px`,
    selected: [false, false, false],
}

export default function PadAlertSelector(props) {

    const onClicked = (code) => () => {
        const leaveSelected = props.selected[0] === undefined ? false : props.selected[0];
        const sideSelected = props.selected[1] === undefined ? false : props.selected[1];
        const sitSelected = props.selected[2] === undefined ? false : props.selected[2];
        let arr = [leaveSelected, sideSelected, sitSelected];
        arr[code] = !arr[code];
        if (props.onChange !== undefined)
            props.onChange(arr);
    }

    return (() => {
        const leaveSelected = props.selected[0] === undefined ? false : props.selected[0];
        const sideSelected = props.selected[1] === undefined ? false : props.selected[1];
        const sitSelected = props.selected[2] === undefined ? false : props.selected[2];
        return (
            <Row width={props.width} height={props.height}
                className={props.className} style={props.style}>
                <SelectorButton height={props.height} mode={PadAlertSelectorMode.leave} selected={leaveSelected}
                    onClick={onClicked(PadAlertSelectorMode.leave.code)}>
                    {intl.get(PadAlertSelectorMode.leave.nameId)}
                </SelectorButton>
                <Divider height={props.height} />
                <SelectorButton height={props.height} mode={PadAlertSelectorMode.side} selected={sideSelected}
                    onClick={onClicked(PadAlertSelectorMode.side.code)}>
                    {intl.get(PadAlertSelectorMode.side.nameId)}
                </SelectorButton>
                <Divider height={props.height} />
                <SelectorButton height={props.height} mode={PadAlertSelectorMode.sit} selected={sitSelected}
                    onClick={onClicked(PadAlertSelectorMode.sit.code)}>
                    {intl.get(PadAlertSelectorMode.sit.nameId)}
                </SelectorButton>
            </Row>
        );
    })();
}

const Divider = styled.div`
    width: ${Dimens.divider}px;
    height: ${props => props.height}px;
    background-color: ${Colors.dividerNormal};
`;

const SelectorButton = styled(Button).attrs(props => ({
    variant: 'contained',
    size: 'small'
}))`
    &.MuiButton-contained {
        && {
            color: #fff;
            background-color: ${props => {
        return props.selected === true ? props.mode.color : '#cccccc';
    }};
            box-shadow: none;
        }
    }
    outline: none;
    flex: 1;
    height: ${props => props.height};
    && {
        border-top-left-radius: ${props => props.mode.code === PadAlertSelectorMode.leave.code ? '5px' : '0px'};
        border-bottom-left-radius: ${props => props.mode.code === PadAlertSelectorMode.leave.code ? '5px' : '0px'};
        border-top-right-radius: ${props => props.mode.code === PadAlertSelectorMode.sit.code ? '5px' : '0px'};
        border-bottom-right-radius: ${props => props.mode.code === PadAlertSelectorMode.sit.code ? '5px' : '0px'};
        min-width: 10px;
        font-size: ${Dimens.textXS};
    }
`;