const zh_tw = {
    // General
    'login': '登入',
    'password': '密碼',
    'cancel': '取消',
    'confirm': '確認',
    'save': '儲存',
    'add': '新增',
    'alert': '提醒',
    'none': '無',
    'male': '男',
    'female': '女',
    'room': '房號',
    'on': '開',
    'off': '關',
    'setting': '設定',
    // Date Format
    'year': '年',
    'month': '月',
    'day': '日',
    'hour': '時',
    'minute': '分',
    'second': '秒',
    'format_year': '年',
    'format_month': '月',
    'format_day': '日',
    'format_hour': '時',
    'format_min': '分',
    'format_sec': '秒',
    'before_second': '秒前',
    'before_minute': '分前',
    'date_until': '至',
    // Arrange Shift
    'arrange_shift_day': '早班',
    'arrange_shift_noon': '中班',
    'arrange_shift_night': '晚班',
    // Pad Event
    'pad_event_empty': '未知',
    'pad_event_unknwon': '空床',
    'pad_event_left_side': '左床緣',
    'pad_event_right_side': '右床緣',
    'pad_event_sit': '坐床',
    'pad_event_on_bed': '躺床',
    // Pad Notification
    'pad_notification_unknown': '未知',
    'pad_notification_off_bed': '離床',
    'pad_notification_side': '床緣',
    'pad_notification_sit': '坐床',
    'pad_notification_on_bed': '躺床',
    'pad_notification_high_activity': '高活動',
    'pad_notification_low_activity': '低活動',
    'pad_notification_roll_over': '翻身拍背',
    'pad_notification_on_bed_over_time': '久臥',
    'pad_notification_short_off_bed': '離',
    'pad_notification_short_side' : '緣',
    'pad_notification_short_sit': '坐',
    'pad_notification_sound_unknown': '未知',
    'pad_notification_sound_off_bed': '離床',
    'pad_notification_sound_side': '床緣',
    'pad_notification_sound_sit': '坐床',
    'pad_notification_sound_on_bed': '躺床',
    'pad_notification_sound_high_activity': '高活動',
    'pad_notification_sound_low_activity': '低活動',
    'pad_notification_sound_roll_over': '翻身拍背',
    'pad_notification_sound_on_bed_over_time': '久臥',
    // Loading
    'loading_login': '登入中',
    'loading_save': '儲存中',
    'loading_data': '資料讀取中',
    'loading_add': '新增中',
    'loading_read': '讀取中',

    // --- Pages ---
    // SignInPage
    'page_signin_title': '請先登入您的帳戶',
    'page_signin_message_new_pwd_required': '此帳號需要更新密碼',
    'page_signin_message_user_not_confirmed': '此帳號尚未完成電子郵件驗證',
    'page_signin_message_not_authorized': '密碼驗證錯誤',
    'page_signin_message_user_not_found': '此帳號尚未註冊',
    'page_signin_message_unknown_error': '系統錯誤： 錯誤碼 {code}',
    // ChangePwdPage
    'page_change_pwd_title': '第一次登入，請輸入公司名稱與新密碼',
    'page_change_pwd_company_name': '公司名稱',
    'page_change_pwd_company_name_hint': '請輸入公司名稱',
    'page_change_pwd_pwd_hint': '請輸入密碼 (須含英文大小寫與數字)',
    'page_change_pwd_pwd_again_hint': '再次輸入密碼',
    'page_change_pwd_message_error': '無法更新您的帳號，請尋求客服幫忙',
    // HomePage
    'page_home_tooltip_battery': '電量剩餘: {level}',
    'page_home_tooltip_alert_records' : '提醒紀錄',
    'page_home_tooltip_activity': '十分鐘活動量',
    'page_home_tooltip_activity_1min': '一分鐘活動量',
    'page_home_tooltip_route_to_history': '查看歷史狀態',
    'page_home_tooltip_route_to_management': '查看床墊管理',
    'page_home_message_pad_offline': '床墊離線或離開區域',
    'page_home_message_gateway_offline': '藍牙Gateway離線',
    // History Page
    'page_history_chart_posture_title': '臥離床紀錄',
    'page_history_chart_posture_legend_on_bed': '臥床',
    'page_history_chart_posture_legend_off_bed': '離床',
    'page_history_chart_posture_legend_offline': '離線',
    'page_history_chart_posture_message_none': '無',
    'page_history_chart_posture_message_on_bed': '用戶持續臥床',
    'page_history_chart_posture_message_off_bed': '用戶離開床墊',
    'page_history_chart_posture_message_offline': '智慧床墊離線',
    'page_history_chart_activity_title': '活動量紀錄',
    'page_history_chart_activity_legend': '活動量',
    'page_history_chart_sleep_title': '睡眠紀錄',
    'page_history_chart_sleep_status': '睡眠狀態',
    'page_history_chart_sleep_legend_none': '無資料',
    'page_history_chart_sleep_legend_awake': '清醒',
    'page_history_chart_sleep_legend_light': '睡眠',
    'page_history_chart_sleep_legend_deep': '深眠',
    'page_history_chart_hint_format_hour': '時',
    'page_history_chart_hint_format_min': '分',
    'page_history_chart_bed_allday': '全天臥床',
    'page_history_chart_bed_day': '白天臥床',
    'page_history_chart_bed_night': '晚上臥床',
    'page_history_chart_sleep_allday': '全天睡眠',
    'page_history_chart_sleep_day': '白天睡眠',
    'page_history_chart_sleep_night': '晚上睡眠',
    'page_history_notify_add_bed': '請先新增智慧床墊',
    // Management Page
    'page_management_time_setting': '設定時間',

    // --- Widget ---
    // AppBar Menu
    'menu_home': '首頁',
    'menu_statistics': '歷史狀態',
    'menu_management': '床墊管理',
    'menu_setting': '設定',
    'menu_language': '語言',
    'menu_logout': '登出',
    // Notification Bar
    'notification_bar_title': '事件提醒',
    'notification_bar_select_voice': '選擇聲音',
    'notification_bar_tab_realtime': '即時',
    'notification_bar_tab_record': '紀錄',
    'notification_bar_tooltip_volume': '提醒聲音: 開啟',
    'notification_bar_tooltip_volume_mute': '提醒聲音: 關閉',
    'notification_bar_format_alert': '提醒',
    // Pad Config Widget
    'pad_config_widget_id_title': '序號',
    'pad_config_widget_basic_title': '基本設定',
    'pad_config_widget_basic_resident_title': '住房狀況',
    'pad_config_widget_stage_alert_title': '三階段離床提醒設定',
    'pad_config_widget_stage_alert_off_bed': '離床',
    'pad_config_widget_stage_alert_side': '床緣',
    'pad_config_widget_stage_alert_sit': '坐床', 
    'pad_config_widget_activity_alert_title': '活動提醒設定',
    'pad_config_widget_activity_alert_over_time_title': '久臥',
    'pad_config_widget_activity_alert_over_time_setting': '時間設定',

    'pad_config_widget_activity_alert_activity_lower_title': '低活動量',
    'pad_config_widget_activity_alert_activity_low_threshold_setting': '活動低於',
    'pad_config_widget_activity_alert_activity_low_time_setting': '持續時間',

    'pad_config_widget_activity_alert_activity_higher_title': '躁動提醒',
    'pad_config_widget_activity_alert_activity_high_setting': '活動高於',

    'pad_config_widget_activity_alert_activity_roll_over_title': '翻身拍背提醒',
    'pad_config_widget_activity_alert_activity_roll_over_threshold_setting': '活動低於',
    'pad_config_widget_activity_alert_activity_roll_over_time_setting': '翻身週期',
    
    'pad_config_widget_activity_alert_range_title': '提醒時段',
    'pad_config_widget_activity_alert_range_selector_all': '全天',
    'pad_config_widget_activity_alert_range_selector_range': '特定',
    'pad_config_widget_activity_alert_range_message_all': '通知全天開啟中',
    'pad_config_widget_other_title': '其他設定',
    'pad_config_widget_other_mi_temp_title': '溫濕度計',

    // --- Dialog ---
    // Arrange Shift Dialog
    'dialog_arrange_shift_title': '設定時間',
    // Languages Dialog
    'dialog_lang_title': '選擇語言',
    // Notifications History Dialog
    'dialog_notifications_history_title': '提醒紀錄',
    // Add Pad Dialog
    'dialog_add_pad_title': '新增床墊',
    'dialog_add_pad_id_title': '序號',
    'dialog_add_pad_message_id_invalid': '格式有誤',
    'dialog_add_pad_message_id_exist': '裝置已存在',
    // Reorder Pad Dialog
    'dialog_reorder_title':'更換順序',
    // voice language dialog
    'dialog_voice_lang_title': '選擇聲音',
}

export default zh_tw