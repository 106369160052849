import PadEvent from './pad_event';

const Base64 = require('base64-js');

export default class Pad {

    get deviceId() {
        return this._deviceId;
    }

    get macAddress() {
        let mac = this._deviceId.split('-')[1];
        return mac === undefined ? '' : mac;
    }

    get firmwareVer() {
        return this._firmwareVer;
    }

    get online() {
        return this._online;
    }

    get sensors() {
        return this._sensors;
    }

    set sensors(array) {
        this._sensors = array;
    }

    get eventCode() {
        return this._eventCode;
    }

    set eventCode(code) {
        this._eventCode = code
    };

    get event() {
        switch (this._eventCode) {
            case 0:
                return PadEvent.Empty;
            case 1:
                return PadEvent.SitSideLeft;
            case 2:
                return PadEvent.SitSideRight;
            case 3:
                return PadEvent.Sit;
            case 4:
                return PadEvent.Lay;
            default:
                return PadEvent.None;
        }
    }

    get activity() {
        return this._activity;
    }

    set activity(val) {
        this._activity = val;
    }

    get activity1Min() {
        return this._activity1Min;
    }

    set activity1Min(val) {
        this._activity1Min = val;
    }

    get battery() {
        return this._battery;
    }

    get miTemp() {
        return this._miTemp
    }

    constructor(deviceId, firmwareVer, online, sensors, eventCode, activity, activity1Min, battery, miTemp = undefined) {
        this._deviceId = deviceId;
        this._firmwareVer = firmwareVer;
        this._online = online;
        this._sensors = sensors;
        this._eventCode = eventCode;
        this._activity = activity;
        this._activity1Min = activity1Min;
        this._battery = battery;
        this._miTemp = miTemp
    }

    setSensorsBase64(str) {
        const array = Base64.toByteArray(str);
        let sensors = [];
        for (let i = 0; i < parseInt(array.length / 4); i++) {
            sensors.push((array[i * 4] << 24) | (array[i * 4 + 1] << 16) | (array[i * 4 + 2] << 8) | (array[i * 4 + 3]));
        }
        this._sensors = sensors;
    }

    setSensorsRaw(str) {
        let sensors = [];
        for (let i = 0; i < 30; i++) {
            sensors.push(parseInt(str.substring(i, i + 1)));
        }
        this._sensors = sensors;
    }
}