import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import intl from 'react-intl-universal'
import AutoSizer from 'react-virtualized-auto-sizer';
import * as Rx from 'rxjs';

import { PadNotification, NotificationData } from '../../model/data/pad_notification';
import { MainContext } from '../../utils/context_utils';

import * as Dimens from '../../styles/dimens';
import * as Colors from '../../styles/colors';
import Column from '../layout/column';
import Row from '../layout/row';
import Stack from '../layout/stack'
import NotificationBarItem from '../widgets/notification_bar_item';
import ButtonText from '../widgets/btn_text';
import VoiceLanguagesDialog from '../../dialog/voice_languages_dialog';
import CustomScrollbarsVirtualList from './scrollbar/CustomScrollbarsVirtualList'

import BlocBuilder from 'bloc-builder-react';
import { Tabs, Tab, createMuiTheme, IconButton, Tooltip } from '@material-ui/core';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { FixedSizeList as List } from 'react-window'

import IcNotification from '@material-ui/icons/NotificationsRounded';
import IcVolume from '@material-ui/icons/VolumeUpRounded'
import IcVolumeMute from '@material-ui/icons/VolumeOffRounded'

const ItemRow = ({ index, style, data }) => (
    <NotificationBarItem index={index} data={data[index]} style={style} datasize={data.length} />
);

const headerTheme = createMuiTheme({ palette: { primary: { main: Colors.accent } } });

NotificationBar.propTypes = {
    width: PropTypes.string.isRequired,
}

export default function NotificationBar(props) {

    const css = useStyles(props)
    const _mainBloc = useContext(MainContext).bloc;
    const [tabIndex, setTabIndex] = useState(0);

    const listView = useRef(null)

    /* lifecycles */

    useEffect(() => {
        return () => {
        }
    }, [])

    /* functions */

    const handleTabChanged = (event, index) => {
        setTabIndex(index);
    };

    function _isOSWin() {
        return navigator.platform.indexOf('Win') > -1;
    }

    function _list(width, height, notifications) {
        return (
            <List
                ref={listView}
                width={width} height={height - Dimens.appbarSizeL} style={{ backgroundColor: Colors.notiBarBg }}
                outerElementType={CustomScrollbarsVirtualList}
                itemSize={width > 300 ? Dimens.notiBarItemSize : Dimens.notiBarItemSizeL}
                itemCount={notifications.length}
                itemData={notifications} >
                {ItemRow}
            </List>
        )
    }

    return (
        <RootLayout width={props.width}>
            <Row width='100%' height={`${Dimens.notiBarHeader}px`}
                style={{ justifyContent: 'space-between', alignItems: 'center' }} >
                <Row width='auto' height='auto' className={css.headerTitleLayout} >
                    <IcNotification style={{ width: '20px', height: 'auto', color: Colors.iconDefault, marginRight: `${Dimens.marginS}px` }} />
                    <HeaderTitle>{intl.get('notification_bar_title')}</HeaderTitle>
                </Row>
                <Row width='auto' height='auto' className={css.headerBtnLayout} >
                    <ButtonText
                        onClick={() => { _mainBloc.voiceLangDialog.next({ show: true }) }}
                        width='auto'
                        textcolor={Colors.primary}
                        text={intl.get('notification_bar_select_voice')}
                        fontsize={Dimens.textS}
                        style={{ fontWeight: 'bold', }} />
                    <BlocBuilder
                        initialValue={_mainBloc.notificationVolume.getValue()}
                        subject={_mainBloc.notificationVolume}
                        builder={(snapshot) => {
                            const isVolume = snapshot.data
                            let icon = <IcVolume className={css.iconBtn} />
                            let message = intl.get('notification_bar_tooltip_volume')
                            if (!isVolume) {
                                icon = <IcVolumeMute className={css.iconBtn} />
                                message = intl.get('notification_bar_tooltip_volume_mute')
                            }
                            return (
                                <Tooltip title={message}>
                                    <SvgIconButton onClick={() => {
                                        const isVolume = _mainBloc.notificationVolume.getValue()
                                        _mainBloc.notificationVolume.next(!isVolume)
                                    }} >
                                        {icon}
                                    </SvgIconButton>
                                </Tooltip>
                            )
                        }} />
                </Row>
            </Row>
            <BlocBuilder
                initialValue={_mainBloc.voiceLangDialog.getValue()}
                subject={_mainBloc.voiceLangDialog}
                builder={snapshot => {
                    const { show } = snapshot.data;
                    return (
                        <VoiceLanguagesDialog open={show} onClose={() => _mainBloc.voiceLangDialog.next({ show: false })} />
                    );
                }} />
            <ThemeProvider theme={headerTheme}>
                <HeaderTabLayout
                    value={tabIndex} onChange={handleTabChanged}
                    indicatorColor="primary"
                    variant="fullWidth">
                    <HeaderTab label={intl.get('notification_bar_tab_realtime')} />
                    <HeaderTab label={intl.get('notification_bar_tab_record')} />
                </HeaderTabLayout>
            </ThemeProvider>
            <Divider />
            <Column width='100%' height='auto' style={{ flex: '1' }} >
                <AutoSizer>
                    {({ height, width }) => {
                        console.log(`re-render tab index: ${tabIndex}`)
                        return (
                            <BlocBuilder
                                initialValue={_mainBloc.notifications.getValue()}
                                subject={_mainBloc.notifications}
                                builder={(snapshot) => {
                                    const notifications = tabIndex === 0 ? _mainBloc.notifications.getValue() : _mainBloc.notificationRecords.getValue()
                                    notifications.sort(function (a, b) {
                                        return b.localEpoch - a.localEpoch
                                    })
                                    // if (_isOSWin()) {
                                    //     return (
                                    //         <Scrollbars
                                    //             style={{ width: `${width}px`, height: `${height}px` }}
                                    //             autoHide
                                    //             onScroll={({ target }) => {
                                    //                 const { scrollTop } = target;
                                    //                 listView.current.scrollTo(scrollTop);
                                    //             }}>
                                    //             {_list(width, height, notifications)}
                                    //         </Scrollbars>
                                    //     )
                                    // }
                                    return _list(width, height, notifications)
                                }} />
                        )
                    }}
                </AutoSizer>
            </Column>
        </RootLayout>
    );
}

const useStyles = makeStyles({
    headerTitleLayout: {
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: `${Dimens.marginM}px`,
    },
    headerBtnLayout: {
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: `${Dimens.marginM}px`,
    },
    iconBtn: {
        width: `${Dimens.notiBarHeaderBtn}px`,
        height: `${Dimens.notiBarHeaderBtn}px`,
        color: Colors.iconDefault,
    }
})

const RootLayout = styled(Column).attrs(props => ({
    width: props.width,
    height: '100%',
    backgroundColor: '#fff',
}))`
    position: fixed;
    top: ${Dimens.appbarSize}px;
    right: 0px;
    max-width: ${Dimens.notiBarMaxWidth}px;
    @media (min-width: 768px) {
        top: ${Dimens.appbarSizeL}px;
    }
`;

const HeaderTitle = styled.span`
    color: ${Colors.textBLight};
    font-size: ${Dimens.textS};
    align-self: center;
    text-align: center;
    font-weight: bold;
`;

const HeaderTabLayout = styled(Tabs).attrs(props => ({

}))`
    width: 100%;
    && {
        min-height: ${Dimens.notiBarTab}px;
    }
`;

const HeaderTab = styled(Tab)`
    & .MuiTab-wrapper {
        font-size: ${Dimens.textS};
    }
    && {
        min-height: ${Dimens.notiBarTab}px;
    }
    outline: none;
`;

const Divider = styled.div`
    width: 100%;
    height: ${Dimens.divider}px;
    background-color: ${Colors.dividerNormal};
`;

const SvgIconButton = styled(IconButton)`
    && {
        align-self: center;
        padding: 3px;
    }
    &:focus {
        outline: none;
    }
`;