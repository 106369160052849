import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import intl from 'react-intl-universal'

import * as Rx from 'rxjs'
import ChangePwdBloc, { ChangePwdPageId } from '../../bloc/login/change_pwd_bloc'

import * as Dimens from '../../styles/dimens'
import * as Colors from '../../styles/colors'
import Row from '../../components/layout/row'
import Column from '../../components/layout/column'
import Stack from '../../components/layout/stack'
import TextField from '../../components/widgets/text_field'
import ButtonRound from '../../components/widgets/btn_round'
import BlocBuilder from 'bloc-builder-react'
import LoadingDialog from '../../dialog/loading_dialog'

import ImgWhizPad from '../../assets/img_whizpad.svg';
import IcCompany from '../../assets/ic_company.svg'
import IcPwd from '../../assets/ic_pwd.svg';

export default function ChangePwdPage(props) {

    const _isWeb = useMediaQuery('(min-width: 768px)');
    const css = useStyles(props)
    const _disposables = useRef(new Rx.Subscription())
    const _bloc = useRef(new ChangePwdBloc())

    const [init, setInit] = useState(false)

    useEffect(() => {
        if (props.location.state === undefined || !props.location.state.hasOwnProperty('email')) {
            props.history.replace('/login/sign_in')
        }
    }, [props])

    useEffect(() => {
        _disposables.current = new Rx.Subscription()
        const bloc = _bloc.current
        bloc.init()
        setBackPageObserver()
        setResultObserver()
        setInit(true)
        return () => {
            setInit(false)
            bloc.dispose()
            _disposables.current.unsubscribe()
        }
    }, [])

    const setBackPageObserver = () => {
        let it = _bloc.current.backPage.subscribe({
            next: () => {
                props.history.replace('/login')
            }
        })
        _disposables.current.add(it)
    }

    const setResultObserver = () => {
        let it = _bloc.current.result.subscribe({
            next: result => {
                if (result) {
                    props.history.replace('/main')
                }
            }
        })
        _disposables.current.add(it)
    }

    return (() => {
        if (!init) return <Stack width='100%' height='100%' className={css.root} />
        const bloc = _bloc.current
        const w = _isWeb ? 50 : 85
        const l = (100 - w) / 2
        const tfWidthPercent = _isWeb ? 70 : 90
        const tfWidthPercentStr = `${tfWidthPercent}%`
        return (
            <Stack width='100%' height='100%' className={css.root} >
                <Column width={`${w}%`} height='auto' style={{ left: `${l}%` }} className={css.contentRootLayout} >
                    <img className={css.imgTitle} src={ImgWhizPad} alt='img_whizpad' />
                    <Column width='100%' height='auto' className={css.contentLayout} >
                        <span className={css.title} >{intl.get('page_change_pwd_title')}</span>
                        <span className={css.title02} style={{ marginLeft: `${Math.floor((100 - tfWidthPercent) / 2)}%` }} >
                            {intl.get('page_change_pwd_company_name')}
                        </span>
                        <BlocBuilder
                            initValue={bloc.companyNameAnim.getValue()}
                            subject={bloc.companyNameAnim}
                            builder={(snapshot) => {
                                return (
                                    <TextField
                                        width={tfWidthPercentStr}
                                        className={css.textField}
                                        leadingicon={IcCompany}
                                        inputtype='text'
                                        hint={intl.get('page_change_pwd_company_name_hint')}
                                        animstate={snapshot.data}
                                        onChange={(event) => { bloc.companyName.next(event.target.value) }} />
                                )
                            }} />
                        <span className={css.title02} style={{ marginLeft: `${Math.floor((100 - tfWidthPercent) / 2)}%` }} >
                            {intl.get('password')}
                        </span>
                        <BlocBuilder
                            initValue={bloc.pwdAnim.getValue()}
                            subject={bloc.pwdAnim}
                            builder={(snapshot) => {
                                return (
                                    <TextField
                                        width={tfWidthPercentStr}
                                        className={css.textField}
                                        leadingicon={IcPwd}
                                        inputtype='password'
                                        hint={intl.get('page_change_pwd_pwd_hint')}
                                        animstate={snapshot.data}
                                        onChange={(event) => { bloc.pwd.next(event.target.value) }} />
                                )
                            }} />
                        <BlocBuilder
                            initValue={bloc.pwdAgainAnim.getValue()}
                            subject={bloc.pwdAgainAnim}
                            builder={(snapshot) => {
                                return (
                                    <TextField
                                        width={tfWidthPercentStr}
                                        className={css.textField}
                                        leadingicon={IcPwd}
                                        inputtype='password'
                                        hint={intl.get('page_change_pwd_pwd_again_hint')}
                                        animstate={snapshot.data}
                                        onChange={(event) => { bloc.pwdAgain.next(event.target.value) }} />
                                )
                            }} />
                        <BlocBuilder
                            initValue={bloc.errorText.getValue()}
                            subject={bloc.errorText}
                            builder={(snapshot) => {
                                return <span className={css.errorText} >{snapshot.data}</span>
                            }} />
                        <ButtonRound
                            onClick={() => { bloc.btnClicked.next(ChangePwdPageId.BtnCancel) }}
                            enable={true}
                            width={tfWidthPercentStr}
                            className={css.btnConfirm}
                            btncolor='#7C8589' >
                            {intl.get('cancel')}
                        </ButtonRound>
                        <BlocBuilder
                            initValue={bloc.confirmAvailable.getValue()}
                            subject={bloc.confirmAvailable}
                            builder={(snapshot) => {
                                return (
                                    <ButtonRound
                                        onClick={() => { bloc.btnClicked.next(ChangePwdPageId.BtnConfirm) }}
                                        enable={snapshot.data}
                                        width={tfWidthPercentStr}
                                        className={css.btnConfirm}
                                        btncolor='#7C8589' >
                                        {intl.get('confirm')}
                                    </ButtonRound>
                                )
                            }} />
                    </Column>
                </Column>
                <BlocBuilder
                    initValue={bloc.loadingDialog.getValue()}
                    subject={bloc.loadingDialog}
                    builder={(snapshot) => {
                        console.log(`change pwd page loading dialog:`)
                        console.log(snapshot)
                        if (snapshot.data === null) return null
                        const { show, text } = snapshot.data
                        return <LoadingDialog onClose={() => { }} open={show} text={text} />
                    }} />
            </Stack >
        )
    })()
}

const useStyles = makeStyles({
    root: {
        backgroundColor: Colors.primary,
        overflowY: 'scroll',
    },
    contentRootLayout: {
        position: 'absolute',
        top: '10%',
    },
    imgTitle: {
        width: '85%',
        height: '50px',
        alignSelf: 'center',
        '@media (min-width: 768px)': {
            height: '60px',
        },
        '@media (min-width: 1200px)': {
            height: '80px',
        }
    },
    contentLayout: {
        marginTop: '3vh',
        paddingTop: `${Dimens.marginL}px`,
        paddingBottom: `${Dimens.marginL}px`,
        borderRadius: `${Dimens.loginBoxRadius}px`,
        borderWidth: `${Dimens.dividerL}px`,
        borderColor: Colors.dividerNormal,
    },
    title: {
        alignSelf: 'center',
        color: '#ffffff',
        fontSize: `${Dimens.textM}`,
        fontWeight: 'bold',
    },
    title02: {
        color: Colors.textWNormal,
        fontSize: `${Dimens.textS}`,
        fontWeight: 'bold',
        marginTop: `${Dimens.marginL}px`,
    },
    textField: {
        alignSelf: 'center',
        marginTop: `${Dimens.marginM}px`,
    },
    btnConfirm: {
        alignSelf: 'center',
        marginTop: `${Dimens.marginL}px`
    },
    errorText: {
        alignSelf: 'center',
        marginTop: `${Dimens.marginM}px`,
        fontSize: Dimens.textS,
        color: Colors.accent,
    }
})