import * as Rx from 'rxjs';
import * as SettingsApi from '../../../model/api/settings_api';

export default class ChangeSeqBloc {

    get padList() { return this._padList; }
    get padListDownloading() { return this._padListDownloading; }
    get isSaved() { return this._isSaved; }
    get padListSaving() { return this._padListSaving; }

    init() {
        this._disposables = new Rx.Subscription();
        this._padList = new Rx.BehaviorSubject([]);
        this._padListDownloading = new Rx.BehaviorSubject(false);
        this._isSaved = new Rx.BehaviorSubject(false);
        this._padListSaving = new Rx.BehaviorSubject(false);

        this.getPadList();
    }

    dispose() {
        this._disposables.unsubscribe();
    }

    getPadList() {
        this._padList.next([]);
        this._padListDownloading.next(true);
        let it = SettingsApi.getPadList()
            .subscribe({
                next: padList => {
                    console.log('padList1', padList)
                    padList = padList.sort((a, b) => a.index - b.index);
                    this._padList.next(padList);
                },
                error: err => {
                    this._padListDownloading.next(false);
                },
                complete: () => {
                    this._padListDownloading.next(false);
                }
            });
        this._disposables.add(it);
    }

    savePadList() {
        this._isSaved.next(false);
        this._padListSaving.next(true);
        console.log('padListd', this._padList.getValue())
        let it = SettingsApi.setPadListOrder(this._padList.getValue())
            .subscribe({
                next: res => {
                    console.log('res', res)
                    this._isSaved.next(true);
                },
                error: err => {
                    console.log('res', err);
                    this._padListSaving.next(false);
                },
                complete: () => {
                    this._isSaved.next(true);
                    this._padListSaving.next(false);
                }
            });
        this._disposables.add(it);
    }

}


