import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles'

import { ManagementContext } from '../../../utils/context_utils';

import * as Dimens from '../../../styles/dimens';
import Stack from '../../../components/layout/stack';
import PadConfigWidget from '../../../components/widgets/pad_config_widget';

import { Paper } from '@material-ui/core';

var Lodash = require('lodash');

ManagementItem.propTypes = {
    index: PropTypes.number.isRequired,
}

export default function ManagementItem(props) {

    const classes = useStyles(props);

    const _bloc = useContext(ManagementContext).bloc;

    // const _isUpdating = useRef(false);

    const isDataUpdated = (isUpdated, config) => {
        console.log(`device: ${config.deviceId}, isUpdated: ${isUpdated}`);
        // _bloc.putConfig(props.index, config);
        _bloc.updateData(props.index, config);
    };

    const onDelete = (deviceId) => {
        console.log(`onDelete: ${deviceId}`);
        _bloc.removeConfig(deviceId);
    };

    return (() => {
        let width = props.style.width - 2 * Dimens.marginM;
        width = width > Dimens.padConfigMinWidth / 0.9 ? width * 0.9 : width;
        let height = Dimens.padConfigHeight;
        if (props.index >= _bloc.configs.getValue().length) return null;
        const config = _bloc.configs.getValue()[props.index];
        return (
            <Stack width={`${props.style.width}px`} height={`${props.style.height}px`} style={props.style}>
                <Paper elevation={3} className={classes.root} >
                    <PadConfigWidget
                        width={width} height={height}
                        data={Lodash.cloneDeep(config)}
                        onDataUpdated={isDataUpdated}
                        onDelete={onDelete} />
                    {/* <BlocBuilder
                        initialValue={false}
                        subject={_bloc.configsUpdated}
                        builder={(snapshot) => {
                            const {updating, index} = snapshot.data;
                            if (index === props.index) _isUpdating.current = updating;
                            return (
                                <PadConfigWidget
                                    width={width} height={height}
                                    data={Lodash.cloneDeep(config)}
                                    onDataUpdated={isDataUpdated}
                                    isSaving={_isUpdating.current} />
                            );
                        }} /> */}
                </Paper>
            </Stack>
        );
    })();
}

const useStyles = makeStyles({
    root: {
        width: props => {
            let width = props.style.width - 2 * Dimens.marginM;
            return width > Dimens.padConfigMinWidth / 0.9 ? width * 0.9 : width
        },
        height: props => `${Dimens.padConfigHeight}px`,
        position: 'absolute',
        top: `50%`,
        left: `50%`,
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#ffffff',
        borderRadius: `${Dimens.padConfigLayoutCorner}px`,
        overflow: 'hidden',
    },
});