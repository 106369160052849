const zh_cn = {
    // General
    'login': '登入',
    'password': '密码',
    'cancel': '取消',
    'confirm': '确认',
    'save': '储存',
    'add': '新增',
    'alert': '提醒',
    'none': '无',
    'male': '男',
    'female': '女',
    'room': '房号',
    'on': '开',
    'off': '关',
    'setting': '设定',
    // Date Format
    'year': '年',
    'month': '月',
    'day': '日',
    'hour': '时',
    'minute': '分',
    'second': '秒',
    'format_year': '年',
    'format_month': '月',
    'format_day': '日',
    'format_hour': '时',
    'format_min': '分',
    'format_sec': '秒',
    'before_second': '秒前',
    'before_minute': '分前',
    'date_until': '至',
    // Arrange Shift
    'arrange_shift_day': '早班',
    'arrange_shift_noon': '中班',
    'arrange_shift_night': '晚班',
    // Pad Event
    'pad_event_empty': '未知',
    'pad_event_unknwon': '空床',
    'pad_event_left_side': '左床缘',
    'pad_event_right_side': '右床缘',
    'pad_event_sit': '坐床',
    'pad_event_on_bed': '躺床',
    // Pad Notification
    'pad_notification_unknown': '未知',
    'pad_notification_off_bed': '离床',
    'pad_notification_side': '床缘',
    'pad_notification_sit': '坐床',
    'pad_notification_on_bed': '躺床',
    'pad_notification_high_activity': '高活动',
    'pad_notification_low_activity': '低活动',
    'pad_notification_roll_over': '翻身拍背',
    'pad_notification_on_bed_over_time': '久卧',
    'pad_notification_short_off_bed': '离',
    'pad_notification_short_side': '缘',
    'pad_notification_short_sit': '坐',
    'pad_notification_sound_unknown': '未知',
    'pad_notification_sound_off_bed': '离床',
    'pad_notification_sound_side': '床缘',
    'pad_notification_sound_sit': '坐床',
    'pad_notification_sound_on_bed': '躺床',
    'pad_notification_sound_high_activity': '高活动',
    'pad_notification_sound_low_activity': '低活动',
    'pad_notification_sound_roll_over': '翻身拍背',
    'pad_notification_sound_on_bed_over_time': '久卧',
    // Loading
    'loading_login': '登入中',
    'loading_save': '储存中',
    'loading_data': '资料读取中',
    'loading_add': '新增中',
    'loading_read': '读取中',

    // --- Pages ---
    // SignInPage
    'page_signin_title': '请先登入您的帐户',
    'page_signin_message_new_pwd_required': '此帐号需要更新密码',
    'page_signin_message_user_not_confirmed': '此帐号尚未完成电子邮件验证',
    'page_signin_message_not_authorized': '密码验证错误',
    'page_signin_message_user_not_found': '此帐号尚未注册',
    'page_signin_message_unknown_error': '系统错误： 错误码 {code}',
    // ChangePwdPage
    'page_change_pwd_title': '第一次登入，请输入公司名称与新密码',
    'page_change_pwd_company_name': '公司名称',
    'page_change_pwd_company_name_hint': '请输入公司名称',
    'page_change_pwd_pwd_hint': '请输入密码 (须含英文大小写与数字)',
    'page_change_pwd_pwd_again_hint': '再次输入密码',
    'page_change_pwd_message_error': '无法更新您的帐号，请寻求客服帮忙',
    // HomePage
    'page_home_tooltip_battery': '电量剩余: {level}',
    'page_home_tooltip_alert_records': '提醒纪录',
    'page_home_tooltip_activity': '十分钟活动量',
    'page_home_tooltip_activity_1min': '一分钟活动量',
    'page_home_tooltip_route_to_history': '查看历史状态',
    'page_home_tooltip_route_to_management': '查看床垫管理',
    'page_home_message_pad_offline': '床垫离线或离开区域',
    'page_home_message_gateway_offline': '蓝牙Gateway离线',
    // History Page
    'page_history_chart_posture_title': '卧离床纪录',
    'page_history_chart_posture_legend_on_bed': '卧床',
    'page_history_chart_posture_legend_off_bed': '离床',
    'page_history_chart_posture_legend_offline': '离线',
    'page_history_chart_posture_message_none': '无',
    'page_history_chart_posture_message_on_bed': '用户持续卧床',
    'page_history_chart_posture_message_off_bed': '用户离开床垫',
    'page_history_chart_posture_message_offline': '智慧床垫离线',
    'page_history_chart_activity_title': '活动量纪录',
    'page_history_chart_activity_legend': '活动量',
    'page_history_chart_sleep_title': '睡眠纪录',
    'page_history_chart_sleep_status': '睡眠状态',
    'page_history_chart_sleep_legend_none': '无资料',
    'page_history_chart_sleep_legend_awake': '清醒',
    'page_history_chart_sleep_legend_light': '浅眠',
    'page_history_chart_sleep_legend_deep': '深眠',
    'page_history_chart_hint_format_hour': '时',
    'page_history_chart_hint_format_min': '分',
    'page_history_chart_bed_allday': '全天卧床',
    'page_history_chart_bed_day': '白天卧床',
    'page_history_chart_bed_night': '晚上卧床',
    'page_history_chart_sleep_allday': '全天睡眠',
    'page_history_chart_sleep_day': '白天睡眠',
    'page_history_chart_sleep_night': '晚上睡眠',
    'page_history_notify_add_bed': '请先新增智慧床垫',
    // Management Page
    'page_management_time_setting': '设定时间',

    // --- Widget ---
    // AppBar Menu
    'menu_home': '首页',
    'menu_statistics': '历史状态',
    'menu_management': '床垫管理',
    'menu_setting': '设定',
    'menu_language': '语言',
    'menu_logout': '登出',
    // Notification Bar
    'notification_bar_title': '事件提醒',
    'notification_bar_select_voice': '选择声音',
    'notification_bar_tab_realtime': '即时',
    'notification_bar_tab_record': '纪录',
    'notification_bar_tooltip_volume': '提醒声音: 开启',
    'notification_bar_tooltip_volume_mute': '提醒声音: 关闭',
    'notification_bar_format_alert': '提醒',
    // Pad Config Widget
    'pad_config_widget_id_title': '序号',
    'pad_config_widget_basic_title': '基本设定',
    'pad_config_widget_basic_resident_title': '住房状况',
    'pad_config_widget_stage_alert_title': '三阶段离床提醒设定',
    'pad_config_widget_stage_alert_off_bed': '离床',
    'pad_config_widget_stage_alert_side': '床缘',
    'pad_config_widget_stage_alert_sit': '坐床',
    'pad_config_widget_activity_alert_title': '活动提醒设定',
    'pad_config_widget_activity_alert_over_time_title': '久卧',
    'pad_config_widget_activity_alert_over_time_setting': '时间设定',

    'pad_config_widget_activity_alert_activity_lower_title': '低活动量',
    'pad_config_widget_activity_alert_activity_low_threshold_setting': '活动低于',
    'pad_config_widget_activity_alert_activity_low_time_setting': '持续时间',

    'pad_config_widget_activity_alert_activity_higher_title': '躁动提醒',
    'pad_config_widget_activity_alert_activity_high_setting': '活动高于',

    'pad_config_widget_activity_alert_activity_roll_over_title': '翻身拍背提醒',
    'pad_config_widget_activity_alert_activity_roll_over_threshold_setting': '活动低于',
    'pad_config_widget_activity_alert_activity_roll_over_time_setting': '翻身周期',

    'pad_config_widget_activity_alert_range_title': '提醒时段',
    'pad_config_widget_activity_alert_range_selector_all': '全天',
    'pad_config_widget_activity_alert_range_selector_range': '特定',
    'pad_config_widget_activity_alert_range_message_all': '通知全天开启中',
    'pad_config_widget_other_title': '其他设定',
    'pad_config_widget_other_mi_temp_title': '温湿度计',

    // --- Dialog ---
    // Arrange Shift Dialog
    'dialog_arrange_shift_title': '设定时间',
    // Languages Dialog
    'dialog_lang_title': '选择语言',
    // Notifications History Dialog
    'dialog_notifications_history_title': '提醒纪录',
    // Add Pad Dialog
    'dialog_add_pad_title': '新增床垫',
    'dialog_add_pad_id_title': '序号',
    'dialog_add_pad_message_id_invalid': '格式有误',
    'dialog_add_pad_message_id_exist': '装置已存在',
    // Reorder Pad Dialog
    'dialog_reorder_title':'更换顺序',
    // voice language dialog
    'dialog_voice_lang_title': '选择声音',
}

export default zh_cn