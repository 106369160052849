export const BtnHistory = '歷史狀態';
export const BtnManagement = '床墊管理';
export const BtnLogout = '登出';
export const error_unknown = '伺服器錯誤';
export const save = '儲存';
export const saving = '儲存中';
export const dataLoading = '資料讀取中';
export const add = '新增';
export const adding = '新增中';
export const del = '移除';
export const deleting = '移除中';
export const confirm = '確認';
export const cancel = '取消'

export const none = '無';
export const female = '女';
export const male = '男';
export const serialNumber = '序號';
export const companyName = '公司名稱'
export const password = '密碼'
export const alarm = '提醒'
export const notifiEvent='事件提醒';
export const notifiOrderTime='時間排序';
export const notifiOrderEvent='事件排序';
export const Alert = '提醒';

export const second = '秒'
export const minute = '分'
export const beforeSecond = '秒前'
export const beforeMinute = '分前'

export const padAlertSelectorLeave = '離床'
export const padAlertSelectorSide = '床緣';
export const padAlertSelectorSit = '坐床';
export const room='房號';
export const padAlertUnderknow = '未知';
export const padAlertSelectorOnBed = '躺床';
export const padAlertSelectorHighAct = '高活動';
export const padAlertSelectorLowAct = '低活動';
export const padAlertSelectorLongInBed = '久臥';
export const padEventEmpty='未知';
export const padEventUnknown='空床';
export const padEventLiftSide='左床緣';
export const padEventRightSide='右床緣';
export const padEventSit='坐床';
export const padEventOnBed='躺床';
export const padTooltipBattery = '電量剩餘'
export const padTooltipNotificationHistory = '提醒紀錄'
export const padTooltipActivity = '十分鐘活動量'
export const padOfflineReason = '床墊離線或離開區域';
export const padGatewayOfflineReason = '藍芽Gateway離線';

export const changePwdTitle = '第一次登入，請輸入公司名稱與新密碼'
export const changePwdCompanyNameHint = '請輸入公司名稱'
export const changePwdHint = '請輸入密碼 (須含英文大小寫與數字)'
export const changePwdAgainHint = '再次輸入密碼'
export const changePwdError = '無法更新您的帳號，請尋求客服幫忙'

export const historyChartPosture = '臥離床紀錄';
export const historyChartPostureLie = '臥床';
export const historyChartPostureLeave = '離床';
export const historyChartPostureOffline = '離線';
export const historyChartActivity = '活動量紀錄';

export const historyChartSleep = '睡眠紀錄';
export const historyChartSleepStatus = '睡眠狀態';
export const historyChartSleepLegendNone = '無資料';
export const historyChartSleepLegendAwake = '清醒';
export const historyChartSleepLegendLight = '淺眠';
export const historyChartSleepLegendDeep = '深眠';
export const historyChartActivityShot = '活動量';
/* --- Widget --- */
export const alertTimeDialogTitle = '選擇提醒時段';

export const addPadDialogTitle = '新增床墊';
export const addPadDialogDeviceIdTitle = '序號';
export const addPadDialogMsgErrDeviceId = '格式有誤';
export const addPadDialogMsgErrDeviceExist = '裝置已存在';

export const notiBarTitle = '事件提醒'
export const notiBarTitleRealtime = '即時'
export const notiBarTitleRecord = '紀錄'
export const notiBarVolumeTooltip = '提醒聲音: 開啟'
export const notiBarVolumeMuteTooltip = '提醒聲音: 關閉'

/* --- Dialog --- */



export const notiHistoryDialogTitle = '提醒紀錄'

/* --- Date Format --- */
export const dateYear = "年";
export const dateMon = "月";
export const dateDay = "日";
export const dateHour = "時";
export const dateMin = "分";

export const dateTo = "至";

export const timeShiftDay = "早班";
export const timeShiftNoon = "中班";
export const timeShiftNight = "晚班";
export const timeSitting = '設定時間';

/* ---- Sitting Widget --- */
export const sittingBasicSitting='基本設定';
export const sittingBasicRoom='房號';
export const sittingBasicSex='住房狀況';
export const sittingBasicBedLeavingAlert='三階段離床提醒設定';
export const sittingBasicOtherAlertSetting='活動提醒設定';
export const sittingBasicOverTime='久臥';
export const sittingBasicOn='開';
export const sittingBasicOff='關';
export const sittingBasicTime='時間設定';
export const sittingBasicActivityError='活動量異常';
export const sittingBasicActivityHigh='異常高於';
export const sittingBasicActivityLow='異常低於';
export const sittingBasicAlertTime='提醒時段';
export const sittingBasicAlertAll='全天';
export const sittingBasicAlertAllFull='通知全天開啟中';
export const sittingBasicAlertSpec='特定';

export const shortLive = '住';
export const shortSide = '緣';
export const shortSit = '坐';
export const shortLevel = '離';