import React, { useEffect, useRef, Fragment, useState, useCallback } from 'react';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Route, Switch, Redirect } from "react-router-dom";
import { useCookies } from 'react-cookie';

import * as Rx from 'rxjs';
import { MainContext } from '../../utils/context_utils';
import MainBloc from '../../bloc/main/main_bloc';

import HomePage from '../main/home/home_page';
import ManagementPage from '../main/management/management_page';
import HistoryPage from '../main/history/history_page';

import * as Dimens from '../../styles/dimens';
import * as Colors from '../../styles/colors';
import Stack from '../../components/layout/stack';
import Column from '../../components/layout/column';
import { AppBar, AppBarIds } from '../../components/widgets/app_bar';
import NotificationBar from '../../components/widgets/notification_bar';
import LanguagesDialog from '../../dialog/languages_dialog'
import BlocBuilder from 'bloc-builder-react'

export default function MainPage(props) {
    const [cookie, setCookie] = useCookies('voice_lang')

    const _disposables = useRef(new Rx.Subscription());
    const _bloc = useRef(new MainBloc());

    const matches = useMediaQuery('(min-width: 768px)');

    const [init, setInit] = useState(false);
    const [loginLoading, setLoginLoading] = useState(true);

    useEffect(() => {
        console.log(`MainPage init...`);
        const disposables = _disposables.current;
        const bloc = _bloc.current;
        setLoginLoading(true);
        bloc.init(cookie);
        setRoutePageObserver();
        setLoginLoadingObserver();
        console.log('check login state');
        bloc.isLoggedIn();
        setInit(true);
        return () => {
            console.log(`MainPage deinit...`);
            bloc.dispose();
            disposables.unsubscribe();
            setInit(false);
        };
    }, [])

    const onAppBarClicked = (id) => {
        switch (id) {
            case AppBarIds.BtnLogout:
                _bloc.current.logout();
                break;
            case AppBarIds.BtnHome:
                _bloc.current.routePage.next('/main/home');
                break;
            case AppBarIds.BtnManagement:
                _bloc.current.routePage.next('/main/management');
                break;
            case AppBarIds.BtnHistory:
                _bloc.current.routePage.next('/main/history');
                break;
            case AppBarIds.BtnLanguage:
                _bloc.current.languagesDialog.next({ show: true })
                break;
            default:
                break;
        }
    }

    /* --- Functions --- */

    const setLoginLoadingObserver = useCallback(() => {
        let it = _bloc.current.loginLoading.subscribe({
            next: loading => {
                setLoginLoading(loading)
            }
        });
        _disposables.current.add(it);
    }, [_disposables]);

    const setRoutePageObserver = useCallback(() => {
        let it = _bloc.current.routePage.subscribe({
            next: path => {
                console.log(`route page path: ${path}`);
                props.history.replace(path);
            },
            error: err => {

                console.log(`route page error: ${err}`);
            },
            complete: () => {
                console.log(`route page complete`);
            }
        });
        _disposables.current.add(it);
    }, [_disposables, props.history]);

    return (() => {
        if (loginLoading || !init) return null;
        const bloc = _bloc.current
        return (
            <MainContext.Provider value={{ bloc: _bloc.current }}>
                <Stack width='100%' height='100%' backgroundColor={Colors.pageBg} >
                    <AppBar onclick={onAppBarClicked} />
                    {matches ?
                        <Fragment>
                            <NotificationBar width='25%' />
                            <ContentLayout width='75%' >
                                <Switch>
                                    <Route exact path='/main/home' component={HomePage} />
                                    <Route exact path='/main/management' component={ManagementPage} />
                                    <Route exact path='/main/history' component={HistoryPage} />
                                    <Route>
                                        <Redirect to='/main/home' />
                                    </Route>
                                </Switch>
                            </ContentLayout>
                        </Fragment>
                        :
                        <ContentLayout width='100%' >
                            <Switch>
                                <Route exact path='/main/home' component={HomePage} />
                                <Route exact path='/main/management' component={ManagementPage} />
                                <Route exact path='/main/history' component={HistoryPage} />
                                <Route>
                                    <Redirect to='/main/home' />
                                </Route>
                            </Switch>
                        </ContentLayout>
                    }
                    <BlocBuilder
                        initialValue={bloc.languagesDialog.getValue()}
                        subject={bloc.languagesDialog}
                        builder={(snapshot) => {
                            const show = snapshot.data.show
                            return <LanguagesDialog onClose={() => { bloc.languagesDialog.next({ show: false }) }} open={show} />
                        }} />
                </Stack>
            </MainContext.Provider>
        )
    })();
}

const ContentLayout = styled(Column).attrs(props => ({
    width: props.width,
    height: '100%',
    backgroundColor: Colors.pageBg,
}))`
    overflow-y: 'scroll';
    position: fixed;
    left: 0px;
    top: ${Dimens.appbarSize}px;
    @media (min-width: 768px) {
        top: ${Dimens.appbarSizeL}px;
    }
`;