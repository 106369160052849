import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as Dimens from '../../styles/dimens';
import { Button } from '@material-ui/core';
import Column from '../layout/column';

export default function ButtonMenu(props) {
    return (
        <RootLayout style={props.style}>
            <Btn width={props.width} height={props.height} onClick={props.onclick}>
                <props.icon style={{ color: '#fff', marginRight: `${Dimens.marginS}px`}} />
                {props.text}
            </Btn>
            <Indicator />
        </RootLayout>
    );
}

ButtonMenu.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    icon: PropTypes.any.isRequired,
    text: PropTypes.string.isRequired,
    onclick: PropTypes.func,
}

const RootLayout = styled(Column).attrs(props => ({
    width: 'auto',
    height: 'auto',
}))`
`;

const Btn = styled(Button)`
    &.MuiButton-root {
        color: #fff;
        font-size: ${Dimens.textL};
    }
    width: ${props => props.width === undefined ? 'auto' : props.width};
    height: ${props => props.height === undefined ? 'auto' : props.height};
    outline: none;
    border: none;
`;

const Indicator = styled.div`
    ${RootLayout}:hover & {
        opacity: 1;
    }
    opacity: 0;
    background-color: #fff;
    height: 3px;
    width: 100%;
    border-radius: 1.5px;
`;