import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery';

import * as Rx from 'rxjs';

import * as Strings from '../styles/strings';
import * as Dimens from '../styles/dimens';
import * as Colors from '../styles/colors';
import Stack from '../components/layout/stack';
import Column from '../components/layout/column';
import Row from '../components/layout/row';
import TimeCircularSlider from '../components/widgets/slider/time_circular_slider';
import BlocBuilder from 'bloc-builder-react';

import { Dialog, ButtonBase } from '@material-ui/core';

var printf = require('printf');

AlertTimeDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
    initTime: PropTypes.object,
    onTimeSelected: PropTypes.func,
}

AlertTimeDialog.defaultProps = {
    open: false,
    initTime: { start: { h: 8, m: 0 }, end: { h: 20, m: 0 } },
}

let _disposables = new Rx.Subscription();

export default function AlertTimeDialog(props) {
    const classes = useStyles(props);
    const isWeb = useMediaQuery('(min-width: 768px)');

    const [init, setInit] = useState(false);

    let _time = useRef(new Rx.BehaviorSubject(props.initTime));

    /* --- Functions --- */

    const onClicked = () => {
        if (props.onTimeSelected !== undefined) {
            props.onTimeSelected(_time.current.getValue());
        }
        props.onClose();
    };

    useEffect(() => {
        if (props.open) {
            _disposables = new Rx.Subscription();
            _time.current.next(props.initTime);
            setInit(true);
        } else if (init) {
            _disposables.unsubscribe();
            setInit(false);
        }
    }, [props.open]);

    return (() => {
        if (!init) return null;
        return (
            <Dialog onClose={props.onClose} open={props.open} >
                <Column width={`${isWeb ? Dimens.alertTimeDialogWidthL : Dimens.alertTimeDialogWidth}px`} height='auto'
                    onTouchMove={(event) => { event.preventDefault(); }}>
                    <span className={classes.title}>{Strings.alertTimeDialogTitle}</span>
                    <Column width='100%' height='auto' className={classes.contentLayout}
                        onTouchStart={(event) => { event.preventDefault(); }}
                        onTouchEnd={(event) => { event.preventDefault(); }}>
                        <TimeCircularSlider
                            onTouchStart={(event) => { event.preventDefault(); }}
                            onTouchEnd={(event) => { event.preventDefault(); }}
                            onTouchMove={(event) => { event.preventDefault(); }}
                            className={classes.slider}
                            initTime={_time.current.getValue()}
                            onUpdate={({ start, end }) => {
                                _time.current.next({ start: start, end: end });
                            }}
                            segments={10}
                            strokeWidth={isWeb ? 15 : 20}
                            radius={isWeb ? 50 : 60}
                            gradientColorFrom="#ff9800"
                            gradientColorTo="#ffcf00"
                            trackColor="#171717" />
                        <BlocBuilder
                            initialValue={_time.current.getValue()}
                            subject={_time.current}
                            builder={(snapshot) => {
                                const start = snapshot.data.start;
                                const end = snapshot.data.end;
                                return (
                                    <span className={classes.numSubText}>
                                        <span className={classes.numText}>{printf("%02d:%02d", start.h, start.m)}</span> {Strings.dateTo} <span className={classes.numText}>{printf("%02d:%02d", end.h, end.m)}</span>
                                    </span>
                                );
                            }} />
                    </Column>
                    <div className={classes.btnDivider} />
                    <ButtonBase
                        className={classes.btnSave}
                        onClick={onClicked}>
                        <span className={classes.btnSaveText}>{Strings.save}</span>
                    </ButtonBase>
                </Column>
            </Dialog>
        );
    })();

}

const useStyles = makeStyles({
    title: {
        color: Colors.textBDark,
        fontSize: Dimens.textL,
        fontWeight: 'bold',
        marginTop: `${Dimens.marginM}px`,
        marginLeft: `${Dimens.marginM}px`,
    },
    contentLayout: {
        marginTop: `${Dimens.marginM}px`,
        marginBottom: `${Dimens.marginM}px`,
        alignItems: 'center',
    },
    slider: {
        marginTop: `${Dimens.marginM}px`,
        alignSelf: 'center',
    },
    numText: {
        color: Colors.textBDark,
        fontSize: Dimens.textXL,
        fontWeight: 'bold',
    },
    numSubText: {
        color: Colors.textBLight,
        fontSize: Dimens.textS,
        marginTop: `${Dimens.marginM}px`,
    },
    btnDivider: {
        width: '100%',
        height: `${Dimens.divider}px`,
        backgroundColor: Colors.dividerLight,
    },
    btnSave: {
        width: '100%',
        height: `${Dimens.arrangeShiftFooter}px`,
    },
    btnSaveText: {
        width: '100%',
        color: Colors.accent,
        fontSize: Dimens.textM,
        fontWeight: 'bold',
    },
});