import * as Rx from 'rxjs';
import {Auth, API} from 'aws-amplify';

import * as ApiGateway from '../api_gateway';

import Gender from '../../data/gender';
import PadConfig from '../../data/pad_config'
import {ArrangeShift} from '../../data/arrange_shift';

var Lodash = require('lodash');

export function getArrangeShift() {
    return Rx.Observable.create(async subscriber => {
        try {
            const params = {
                body: {'userId': 'user'},
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            const config = await API.get(ApiGateway.HOST, ApiGateway.CONFIG_ARRANGE_SHIFT, params);
            console.log(config);
            subscriber.next(new ArrangeShift(config.day, config.noon, config.night));
        } catch (e) {
            subscriber.next(new ArrangeShift(420, 900, 1380));
        }
        subscriber.complete();
    });
}

export function putArrangeShift(config) {
    const _config = Lodash.cloneDeep(config);
    return Rx.Observable.create(async subscriber => {
        try {
            // const session = await Auth.currentSession();
            const userId = "user";//session.getIdToken().decodePayload().sub;

            const params = {
                body: {'userId': userId, 'config': _config.toJson()},
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            const response = await API.put(ApiGateway.HOST, ApiGateway.CONFIG_ARRANGE_SHIFT, params);
            console.log(response);
            subscriber.next(_config);
            subscriber.complete();
        } catch (e) {
            subscriber.error(e);
        }
    });
}

export function getConfigs() {
    return Rx.Observable.create(async subscriber => {
        let configs = [];
        try {
            const params = {
                body: {'userId': 'user'},
                headers: {
                    'Content-Type': 'application/json',
                }
            };
            const items = await API.get(ApiGateway.HOST, ApiGateway.CONFIG_PADS, params);
            console.log(items);
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                configs.push(new PadConfig(item.deviceId, item.name, Gender.parseGender(item.gender), item.stageAlert, item.activityAlert, item.miTempDeviceId, item.created));
            }
        } catch (e) {

        }
        subscriber.next(configs);
        subscriber.complete();
    });
}

export function updateConfigs(configs) {
    const _configs = Lodash.cloneDeep(configs);
    return Rx.Observable.create(async subscriber => {
        try {
            //const session = await Auth.currentSession();
            const userId = "user";

            let items = {};
            for (let i = 0; i < configs.length; i++) {
                const config = configs[i];
                items[config.deviceId] = config.toJson();
            }

            const params = {
                body: {'userId': userId, 'action': 'update', 'configs': items},
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            const response = await API.put(ApiGateway.HOST, ApiGateway.CONFIG_PADS, params);
            console.log(response);
        } catch (e) {

        }
        subscriber.next(_configs);
        subscriber.complete();
    });
}

export function addConfig(config) {
    const _config = Lodash.cloneDeep(config);
    return Rx.Observable.create(async subscriber => {
        try {
            // const session = await Auth.currentSession();
            const userId = "user";

            const params = {
                body: {'userId': userId, 'action': 'add', 'config': _config.toJson()},
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            const response = await API.put(ApiGateway.HOST, ApiGateway.CONFIG_PADS, params);
            console.log(response);
        } catch (e) {

        }
        subscriber.next(config);
        subscriber.complete();
    });
}

export function removeConfig(deviceId) {
    return removeConfigs([deviceId]);
}

export function removeConfigs(deviceIds) {
    return Rx.Observable.create(async subscriber => {
        try {
            //const session = await Auth.currentSession();
            const userId = "user";

            const params = {
                body: {'userId': "user", 'deviceIds': deviceIds},
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            const response = await API.del(ApiGateway.HOST, ApiGateway.CONFIG_PADS, params);
            console.log(response);
        } catch (e) {

        }
        subscriber.next(deviceIds);
        subscriber.complete();
    });
}

export function getConfig(deviceId) {
    return Rx.Observable.create(async subscriber => {
        try {
            const userId = "user";
            const params = {
                body: {'userId': userId, 'deviceId': deviceId},
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            const item = await API.post(ApiGateway.HOST, ApiGateway.CONFIG_PADS, params);
            const miTempDeviceId = item.hasOwnProperty('miTempDeviceId') ? item.miTempDeviceId : ''
            const config = new PadConfig(item.deviceId, item.name, Gender.parseGender(item.gender), item.stageAlert, item.activityAlert, miTempDeviceId, item.created);
            subscriber.next(config);
            subscriber.complete();
        } catch (e) {
            subscriber.error(e);
        }
    });
}

export function getPadList() {
    return Rx.Observable.create(async subscriber => {
        try {
            const userId = "user"
            const params = {
                headers: {'Content-Type': 'application/json',},
                response: false,
                queryStringParameters: {
                    userId: userId,
                }
            }
            const items = await API.get(ApiGateway.HOST, ApiGateway.CONFIG_PAD_LIST, params);
            console.log("getPadList", items);
            subscriber.next(items);
            subscriber.complete();
        } catch (e) {
            subscriber.error(e);
        }
    })
}

export function setPadListOrder(list) {
    return Rx.Observable.create(async subscriber => {
        try {
            console.log("ListData:", list);
            const userId = "user";
            const params = {
                body: {userId, list},
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            await API.put(ApiGateway.HOST, ApiGateway.CONFIG_PAD_LIST, params);
            subscriber.next();
        } catch (e) {
            subscriber.error(e);
        }
    });
}
