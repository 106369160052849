import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as Dimens from '../../styles/dimens';
import Row from '../layout/row';
import Lottie from 'react-lottie';

import AnimLoading from '../../assets/lottie/textfield_loading.json';
import AnimValid from '../../assets/lottie/textfield_valid.json';
import AnimInvalid from '../../assets/lottie/textfield_invalid.json';

function getAnim(state) {
    switch (state) {
        case 'success':
            return AnimValid;
        case 'failure':
            return AnimInvalid;
        case 'loading':
            return AnimLoading;
        case 'none':
        default:
            return undefined;
    }
}

export default function TextField(props) {

    const defaultOptions = {
        loop: props.animstate === 'loading' ? true : false,
        autoplay: props.animstate === 'none' ? false : true,
        animationData: getAnim(props.animstate),
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <RootLayout
            height={`${Dimens.textFieldSize}px`}
            borderRadius={`${Dimens.textFieldSize / 2}px`}
            backgroundColor='#fff'
            {...props}>
            <LeadingIcon src={props.leadingicon} />
            <Input type={!props.inputtype ? 'text' : props.inputtype} placeholder={!props.hint ? '' : props.hint} />
            <Lottie
                style={{
                    alignSelf: 'center', marginRight: `${Dimens.textFieldSize * 0.3 / 2}px`,
                    visibility: `${props.animstate === 'none' ? 'hidden' : 'visible'}`
                }}
                options={defaultOptions}
                width={Dimens.textFieldSize * 0.7}
                height={Dimens.textFieldSize * 0.7} />
        </RootLayout>
    );
}

TextField.propTypes = {
    width: PropTypes.string.isRequired,
    leadingicon: PropTypes.string.isRequired,
    hint: PropTypes.string,
    inputtype: PropTypes.string,
    animstate: PropTypes.oneOf(['none', 'loading', 'success', 'failure'])
};

TextField.defaultProps = {
    animstate: 'none'
}

const RootLayout = styled(Row)`
    overflow: hidden;
`;

const LeadingIcon = styled.img`
    align-self: center;
    margin-left: ${Dimens.textFieldSize * 0.3 / 2}px;
    width: ${Dimens.textFieldSize * 0.7}px;
    height: ${Dimens.textFieldSize * 0.7}px;
`;

const Input = styled.input`
    /* position: absolute; */
    align-self: center;
    margin-left: ${Dimens.textFieldSize * 0.3 / 2 + Dimens.marginM}px;
    margin-right: ${Dimens.textFieldSize * 0.3 / 2 + Dimens.marginM}px;
    flex: 1;
    height: auto;
    font-size: ${Dimens.textM};
    background-color: transparent;
    border: none;
    outline: none;
`;
