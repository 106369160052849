import React, { useState, useEffect, useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import intl from 'react-intl-universal'

import * as Rx from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ManagementContext } from '../../../utils/context_utils';
import ManagementBloc from '../../../bloc/main/management/management_bloc';
import ManagementItem from './management_item';
import PadConfigWidget from '../../../components/widgets/pad_config_widget';

import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeGrid as Grid } from 'react-window';
import { Paper, ButtonBase } from '@material-ui/core';
import BlocBuilder from 'bloc-builder-react';
import Lottie from 'react-lottie';
import { Scrollbars } from 'react-custom-scrollbars';

import * as Dimens from '../../../styles/dimens';
import * as Colors from '../../../styles/colors';
import Stack from '../../../components/layout/stack';
import Column from '../../../components/layout/column';
import Row from '../../../components/layout/row';
import BtnRound from '../../../components/widgets/btn_round';
import ArrangeShiftDialog from './arrange_shift_dialog';
import AddPadDialog from './add_pad_dialog';
import ChangePadSeqDialog from './change_pad_seq_dialog'
import LoadingDialog from '../../../dialog/loading_dialog';

import IcDay from '../../../assets/ic_day.svg';
import IcNoon from '../../../assets/ic_noon.svg';
import IcNight from '../../../assets/ic_night.svg';
import IcAdd from '@material-ui/icons/AddCircleRounded';
import IcSave from '@material-ui/icons/SaveRounded';
import IcChange from '@material-ui/icons/CachedRounded';
import AnimLoading from '../../../assets/lottie/textfield_loading.json';

const printf = require('printf');
const Lodash = require('lodash');

const ManagementPageIds = {
    BtnShiftSetting: 0, BtnSave: 1, BtnAdd: 2, BtnSequenceSetting: 3,
};

let _bloc = new ManagementBloc();

function formatTime({ h, m }) {
    return printf("%02d:%02d", h, m);
}

export default function ManagementPage(props) {

    const classes = useStyles(props);
    const isWeb = useMediaQuery('(min-width: 768px)');

    const [init, setInit] = useState(false);

    const gridView = useRef(null);

    let _disposables = useRef(new Rx.Subscription());

    useEffect(() => {
        console.log(`ManagementPage init...`);
        const disposable = _disposables.current;
        _bloc.init();
        isPushFromHomePage();
        // setArrangeShiftDialogObserver();
        _setGridViewSizeObserver();
        setInit(true);
        return () => {
            console.log(`ManagementPage deinit...`);
            _bloc.dispose();
            disposable.unsubscribe();
            setInit(false);
        };
    }, [])

    /* --- Functions --- */
    const isPushFromHomePage = () => {
        if (props.location.state) {
            // console.log('findindex',props.location.state)
            _bloc.findIndex(props.location.state.deviceId);
        }
    }

    const onClicked = useCallback((id) => () => {
        switch (id) {
            case ManagementPageIds.BtnShiftSetting:
                _bloc._arrangeShiftDialog.next({ show: true });
                break;
            case ManagementPageIds.BtnSave:
                _bloc.saveConfig();
                break;
            case ManagementPageIds.BtnAdd:
                _bloc.addPadDialog.next({ show: true });
                break;
            case ManagementPageIds.BtnSequenceSetting:
                _bloc.changePadSeqDialog.next({ show: true });
                break;
            default:
                break;
        }
    }, []);

    const _onArrangeShiftSaved = (arrangeShift) => {
        _bloc.arrangeShift.next(arrangeShift);
        console.log(_bloc.arrangeShift.getValue());
    };

    const _onPadAdded = (config) => {
        _bloc._getPadList();
    };

    function _setGridViewSizeObserver() {
        let it = _bloc.gridViewSize
            .pipe(
                debounceTime(300),
            )
            .subscribe({
                next: ({ width, height }) => {
                    if (gridView.current !== null) gridView.current.resetAfterColumnIndex(0, true);
                }
            });
        _disposables.current.add(it);
    }

    // pad item is Updated
    const isDataUpdated = (isUpdated, config) => {
        console.log(`device: ${config.deviceId}, isUpdated: ${isUpdated}`);
        console.log('update', config)
        _bloc.updateData(config);
    };
    // pad item onDelete
    const onDelete = (deviceId) => {
        console.log(`onDelete: ${deviceId}`);
        _bloc.removeConfig(deviceId);
    };

    return (() => {
        if (!init) return null;
        console.log(`re-render`);
        const headerSize = isWeb ? Dimens.managementHeaderSizeL : Dimens.managementHeaderSize;
        const appbarSize = isWeb ? Dimens.appbarSizeL : Dimens.appbarSize;
        return (
            <ManagementContext.Provider value={{ bloc: _bloc }} >
                <Column width='100%' height='100%' >
                    <BlocBuilder
                        initialValue={_bloc.arrangeShift.getValue()}
                        subject={_bloc.arrangeShift}
                        builder={(snapshot) => {
                            const { day, noon, night } = snapshot.data.range;
                            return (
                                <Scrollbars style={{ width: '100%' }} autoHide autoHeight>
                                    <Row width='100%' height={`${headerSize}px`} className={classes.headerLayout} >
                                        <Row width='auto' height='100%' className={classes.headerItemLayout}>
                                            <img src={IcDay} alt='ic_day' className={classes.shiftIcon} />
                                            <span className={classes.shiftText}>
                                                {intl.get('arrange_shift_day')} <span className={classes.shiftNumText}>{formatTime(day.start)}</span> {intl.get('date_until')} <span className={classes.shiftNumText}>{formatTime(day.end)}</span>
                                            </span>
                                        </Row>
                                        <Row width='auto' height='100%' className={classes.headerItemLayout}>
                                            <img src={IcNoon} alt='ic_noon' className={classes.shiftIcon} />
                                            <span className={classes.shiftText}>
                                                {intl.get('arrange_shift_noon')} <span className={classes.shiftNumText}>{formatTime(noon.start)}</span> {intl.get('date_until')} <span className={classes.shiftNumText}>{formatTime(noon.end)}</span>
                                            </span>
                                        </Row>
                                        <Row width='auto' height='100%' className={classes.headerItemLayout}>
                                            <img src={IcNight} alt='ic_night' className={classes.shiftIcon} />
                                            <span className={classes.shiftText}>
                                                {intl.get('arrange_shift_night')} <span className={classes.shiftNumText}>{formatTime(night.start)}</span> {intl.get('date_until')} <span className={classes.shiftNumText}>{formatTime(night.end)}</span>
                                            </span>
                                        </Row>
                                        <BtnRound
                                            onClick={onClicked(ManagementPageIds.BtnShiftSetting)}
                                            width={`${Dimens.managementShiftSettingBtnSize}px`}
                                            btncolor={Colors.accent}
                                            className={classes.shiftSettingBtn}>
                                            <span style={{ color: '#ffffff' }}>{intl.get('page_management_time_setting')}</span>
                                        </BtnRound>
                                    </Row>
                                </Scrollbars>
                            );
                        }} />
                    <div className={classes.divider} />
                    {/* <Stack width='100%' height='auto' style={{ flex: 1 }}> */}
                    <Row width='100%' height='auto' style={{ flex: 1 }}  >
                        {/* sider */}
                        <Scrollbars
                            autoHide
                            style={{
                                width: `${isWeb ? Dimens.managementDeviceItemWidthL : Dimens.managementDeviceItemWidth}px`,
                                height: `calc(100vh - ${appbarSize}px - ${headerSize}px)`
                            }}>
                            <Column width={`${isWeb ? Dimens.managementDeviceItemWidthL : Dimens.managementDeviceItemWidth}px`}
                                height='auto'>
                                <BlocBuilder
                                    initialValue={_bloc.padList.getValue()}
                                    subject={_bloc.padList}
                                    builder={snapshot => {
                                        const padList = snapshot.data
                                        return (
                                            <Column width='200px' height='auto'
                                                backgroundColor='#ffffff'>
                                                {padList.map((item) => {
                                                    return (
                                                        <ButtonBase className={classes.DeviceListItem} key={item.deviceId}
                                                            onClick={() => {
                                                                _bloc.btnDevicesClicked(item.index)
                                                                console.log('click', item.deviceId)
                                                            }}>
                                                            <BlocBuilder
                                                                initialValue={_bloc.selectedConfig.getValue()}
                                                                subject={_bloc.selectedConfig}
                                                                builder={snapshot => {
                                                                    const selectedIndex = snapshot.data.index;
                                                                    const isSelected = selectedIndex === item.index;
                                                                    const macAddress = item.deviceId.replace('WhizPad_RC-', '');
                                                                    return (
                                                                        <Stack width='100%' height={`${Dimens.historyDeviceItem}px`} >
                                                                            <Column width='100%' height='90%'
                                                                                className={classes.DeviceListItemContent}
                                                                                style={{ backgroundColor: isSelected ? '#f0f0f0' : 'transparent' }}>
                                                                                <span className={classes.DeviceListName}>{item.name}</span>
                                                                                <span className={classes.DeviceListId}>{macAddress}</span>
                                                                            </Column>
                                                                            <div className={classes.DeviceListIndicator}
                                                                                style={{ backgroundColor: isSelected ? Colors.accent : 'transparent' }} />
                                                                        </Stack>
                                                                    )
                                                                }}
                                                            />

                                                        </ButtonBase>
                                                    )
                                                })}
                                            </Column>

                                        )
                                    }} />

                            </Column>
                        </Scrollbars>
                        <div className={classes.RootDivider} />
                        {/* main body */}

                        <Column width='auto' height='100%' style={{ flex: 1 }}>

                            <Row width='100%' height={`55px`}
                                className={classes.listHeaderBarLayout} >
                                <BlocBuilder
                                    initialValue={_bloc.isDataDiff.getValue()}
                                    subject={_bloc.isDataDiff}
                                    builder={(snapshot) => {
                                        const diff = snapshot.data;
                                        return (
                                            <BtnRound width='auto' btncolor={Colors.primary} enable={diff}
                                                onClick={onClicked(ManagementPageIds.BtnSave)}>
                                                <IcSave className={classes.listHeaderBarIcon} />
                                                <span className={classes.listHeaderBarIconText} >{intl.get('save')}</span>
                                            </BtnRound>
                                        );
                                    }} />
                                {(!isWeb || process.env.REACT_APP_LOCAL === 'true') ? null :
                                    <BtnRound width='auto' btncolor={Colors.primary} enable
                                        style={{ marginLeft: `${Dimens.marginM}px` }}
                                        onClick={onClicked(ManagementPageIds.BtnAdd)} >
                                        <IcAdd className={classes.listHeaderBarIcon} />
                                        <span className={classes.listHeaderBarIconText} >{intl.get('add')}</span>
                                    </BtnRound>
                                }
                                <Row style={{ flex: 1, justifyContent: 'flex-end' }}>
                                    <BtnRound width='auto' btncolor={Colors.primary} enable
                                        style={{ marginRight: `${Dimens.marginM}px` }}
                                        onClick={onClicked(ManagementPageIds.BtnSequenceSetting)} >
                                        <IcChange className={classes.listHeaderBarIcon} />
                                        {/* need to add strings! */}
                                        <span className={classes.listHeaderBarIconText} >{intl.get('dialog_reorder_title')}</span>
                                    </BtnRound>
                                </Row>

                            </Row>
                            <Scrollbars autoHide>
                                <Row width='100%' height='100%' style={{ justifyContent: 'center', alignItems: 'stretch' }}>
                                    <Column style={{ flex: 1 }}>
                                        <AutoSizer>
                                            {({ height, width }) => {
                                                console.log('grid size YX', height, width);
                                                return (
                                                    <BlocBuilder
                                                        initialValue={_bloc.config.getValue()}
                                                        subject={_bloc.config}
                                                        builder={(snapshot) => {
                                                            const config = snapshot.data;
                                                            const padding = isWeb ? 100 : 50;
                                                            console.log('config page', config)
                                                            if (!config || config.deviceId === undefined) {
                                                                return <div></div>
                                                            } else {
                                                                return (
                                                                    <Stack width={`${width}px`} height={`${Dimens.padConfigHeight + 115}px`}>
                                                                        <Paper elevation={3} style={{ position: 'absolute', top: '15px', left: `${padding / 2}px`, width: `calc(${width}px - ${padding}px)`, height: `${Dimens.padConfigHeight}px`, borderRadius: `${Dimens.padConfigLayoutCorner}px`, }}>
                                                                            <PadConfigWidget
                                                                                width={width - padding}
                                                                                height={Dimens.padConfigHeight}
                                                                                data={Lodash.cloneDeep(config)}
                                                                                onDataUpdated={isDataUpdated}
                                                                                onDelete={onDelete} />
                                                                        </Paper>
                                                                    </Stack>
                                                                )
                                                                if (process.env.REACT_APP_LOCAL === "true") {
                                                                    return (
                                                                        <Stack width={`${width}px`} height={`${Dimens.padConfigLanHeight + 115}px`}>
                                                                            <Paper elevation={3} style={{ position: 'absolute', top: '15px', left: `${padding / 2}px`, width: `calc(${width}px - ${padding}px)`, height: `${Dimens.padConfigLanHeight}px`, borderRadius: `${Dimens.padConfigLayoutCorner}px`, }}>
                                                                                <PadConfigWidget
                                                                                    width={width - padding}
                                                                                    height={Dimens.padConfigLanHeight}
                                                                                    data={Lodash.cloneDeep(config)}
                                                                                    onDataUpdated={isDataUpdated}
                                                                                    onDelete={onDelete} />
                                                                            </Paper>
                                                                        </Stack>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <Stack width={`${width}px`} height={`${Dimens.padConfigHeight + 115}px`}>
                                                                            <Paper elevation={3} style={{ position: 'absolute', top: '15px', left: `${padding / 2}px`, width: `calc(${width}px - ${padding}px)`, height: `${Dimens.padConfigHeight}px`, borderRadius: `${Dimens.padConfigLayoutCorner}px`, }}>
                                                                                <PadConfigWidget
                                                                                    width={width - padding}
                                                                                    height={Dimens.padConfigHeight}
                                                                                    data={Lodash.cloneDeep(config)}
                                                                                    onDataUpdated={isDataUpdated}
                                                                                    onDelete={onDelete} />
                                                                            </Paper>
                                                                        </Stack>
                                                                    )
                                                                }
                                                            }

                                                        }} />
                                                )
                                            }}
                                        </AutoSizer>

                                    </Column>

                                </Row>
                            </Scrollbars>
                        </Column>

                        {/* 頁面讀取中 */}
                        <BlocBuilder
                            initialValue={_bloc.configDownloading.getValue()}
                            subject={_bloc.configDownloading}
                            builder={(snapshot) => {
                                if (!snapshot.data) return null;
                                return (
                                    <Column width='100%' height='100%' className={classes.loadingLayout}>
                                        <Lottie
                                            isClickToPauseDisabled
                                            options={{
                                                loop: true,
                                                autoplay: true,
                                                animationData: AnimLoading,
                                                rendererSettings: {
                                                    preserveAspectRatio: 'xMidYMid slice'
                                                }
                                            }}
                                            width={`${Dimens.padConfigLoading}px`}
                                            height='auto' />
                                        <span className={classes.loadingText}>{intl.get('loading_data')}</span>
                                    </Column>
                                );
                            }} />
                    </Row>
                    <BlocBuilder
                        initialValue={_bloc.arrangeShiftDialog.getValue()}
                        subject={_bloc.arrangeShiftDialog}
                        builder={(snapshot) => {
                            const { show } = snapshot.data;
                            if (isWeb)
                                return (
                                    <ArrangeShiftDialog
                                        onClose={() => { _bloc.arrangeShiftDialog.next({ show: false }); }} open={show}
                                        onTimeSelected={_onArrangeShiftSaved} />
                                );
                            else return null;
                        }} />
                    <BlocBuilder
                        initialValue={_bloc.addPadDialog.getValue()}
                        subject={_bloc.addPadDialog}
                        builder={(snapshot) => {
                            const { show } = snapshot.data;
                            return (
                                <AddPadDialog onClose={() => { _bloc.addPadDialog.next({ show: false }) }} open={show}
                                    onPadAdded={_onPadAdded} />
                            );
                        }} />
                    <BlocBuilder
                        initialValue={_bloc.changePadSeqDialog.getValue()}
                        subject={_bloc.changePadSeqDialog}
                        builder={(snapshot) => {
                            const { show } = snapshot.data;
                            return (
                                <ChangePadSeqDialog onClose={() => { _bloc.changePadSeqDialog.next({ show: false }) }} open={show}
                                    onSave={() => { _bloc._getPadList(); }} />
                                // <AddPadDialog onClose={() => { _bloc.addPadDialog.next({ show: false }) }} open={show}
                                //     onPadAdded={_onPadAdded} />
                            );
                        }} />
                    <BlocBuilder
                        initialValue={_bloc.loadingDialog.getValue()}
                        subject={_bloc.loadingDialog}
                        builder={(snapshot) => {
                            const { show, message } = snapshot.data;
                            return (
                                <LoadingDialog onClose={() => { }} open={show} text={message} />
                            );
                        }} />
                </Column>
            </ManagementContext.Provider>
        );
    })();
}

const useStyles = makeStyles({
    root: {
        position: 'fixed',
        top: '0px',
        left: '0px',
    },
    RootDivider: {
        width: `${Dimens.divider}px`,
        height: '100%',
        backgroundColor: Colors.dividerNormal,
    },
    headerLayout: {
    },
    headerItemLayout: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: `${Dimens.marginM}px`,
        paddingRight: `${Dimens.marginM}px`,
    },
    DeviceListItem: {
        width: '100%',
        height: `${Dimens.historyDeviceItem}px`,
    },
    DeviceListItemContent: {
        position: 'absolute',
        top: '5%',
        left: '0px',
        justifyContent: 'center',
        paddingLeft: `${Dimens.marginM}px`,
        paddingRight: `${Dimens.marginM}px`,
    },
    DeviceListIndicator: {
        position: 'absolute',
        top: '5%',
        right: '0px',
        width: `${Dimens.historyDeviceItemIndicator}px`,
        height: '90%',
    },
    DeviceListName: {
        fontWeight: 'bold',
        fontSize: Dimens.textM,
        color: Colors.textBNormal,
    },
    DeviceListId: {
        fontSize: Dimens.textXS,
        color: Colors.textBXLight,
    },



    shiftIcon: {
        width: `${Dimens.managementHeaderIconSize}px`,
        height: `${Dimens.managementHeaderIconSize}px`,
    },
    shiftText: {
        marginLeft: `${Dimens.marginM}px`,
        color: Colors.textBLight,
        fontSize: Dimens.textS,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    shiftNumText: {
        color: Colors.textBDark,
        fontSize: Dimens.textXL,
        fontWeight: 'bold',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    shiftSettingBtn: {
        alignSelf: 'center',
        marginLeft: `${Dimens.marginL}px`,
        marginRight: `${Dimens.marginL}px`,
        display: 'none',
        ['@media (min-width:768px)']: { // eslint-disable-line no-useless-computed-key
            display: 'inline-flex',
        }
    },
    divider: {
        width: '100%',
        height: `${Dimens.divider}px`,
        backgroundColor: Colors.dividerNormal,
    },
    listHeaderBarLayout: {
        alignItems: 'center',
        paddingLeft: `${Dimens.marginM}px`,
        paddingRight: `${Dimens.marginM}px`,
    },
    listHeaderBarIcon: {
        width: `${Dimens.padConfigHeaderBarAddIcon}px`,
        height: `${Dimens.padConfigHeaderBarAddIcon}px`,
        color: Colors.iconDefaultW,
    },
    listHeaderBarIconText: {
        fontSize: Dimens.textM,
        color: Colors.textWDark,
        marginLeft: `${Dimens.marginS}px`,
    },
    listLayout: {
        backgroundColor: '#000000',
    },
    loadingLayout: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loadingText: {
        color: Colors.textBLight,
        fontSize: Dimens.textM,
        fontWeight: 'bold',
        marginTop: `${Dimens.marginM}px`,
    }
});