import Gender from '../data/gender';
import { ArrangeShiftType } from '../data/arrange_shift';
import { PadNotification } from '../data/pad_notification';

export default class PadConfig {

    static residentToGender(resident) {
        switch (resident) {
            case 0: return Gender.Male;
            case 1: return Gender.Female;
            default: return Gender.None;
        }
    }

    get deviceId() { return this._deviceId; }

    set deviceId(id) { this._deviceId = id; }

    get macAddress() { let mac = this._deviceId.split('-')[1]; return mac === undefined ? '' : mac; }

    get name() { return this._name; }

    get gender() { return this._gender; }

    get resident() {
        let code = this._gender.code;
        switch (code) {
            case Gender.Female.code:
                return 1;
            case Gender.Male.code:
                return 0;
            default:
                return 2;
        }
    }

    get stageAlert() { return this._stageAlert; }

    get activityAlert() { return this._activityAlert; }

    get miTempDeviceId() { return this._miTempDeviceId }

    get created() { return this._created; }

    get index() { return this._index; }

    constructor(deviceId, name, gender, stageAlert, activityAlert, miTempDeviceId = '', created = 0, index = undefined) {
        this._deviceId = deviceId
        this._name = name
        this._gender = gender
        this._stageAlert = stageAlert
        this._activityAlert = activityAlert
        this._miTempDeviceId = miTempDeviceId
        this._created = created
        this._index = index
    }

    isDifferent(config) {
        try {
            if (config.deviceId !== this._deviceId) return true;
            if (config.name !== this._name) return true;
            if (config.resident !== this.resident) return true;
            if (JSON.stringify(config.stageAlert) !== JSON.stringify(this._stageAlert)) return true;
            if (JSON.stringify(config.activityAlert) !== JSON.stringify(this._activityAlert)) return true;
            if (config.miTempDeviceId !== this._miTempDeviceId) return true
        } catch (e) { }
        return false
    }

    stageAlertNow(type, noti) {
        if (type.code === ArrangeShiftType.Day.code) {
            if (noti.code === PadNotification.Leave.code) return this._stageAlert.day.leave;
            else if (noti.code === PadNotification.SitSide.code) return this._stageAlert.day.side;
            else return this._stageAlert.day.sit;
        } else if (type.code === ArrangeShiftType.Noon.code) {
            if (noti.code === PadNotification.Leave.code) return this._stageAlert.noon.leave;
            else if (noti.code === PadNotification.SitSide.code) return this._stageAlert.noon.side;
            else return this._stageAlert.noon.sit;
        } else {
            if (noti.code === PadNotification.Leave.code) return this._stageAlert.night.leave;
            else if (noti.code === PadNotification.SitSide.code) return this._stageAlert.night.side;
            else return this._stageAlert.night.sit;
        }
    }

    toJson() {
        const json = {
            'deviceId': this._deviceId,
            'name': this._name,
            'gender': this._gender.code,
            'stageAlert': {
                'day': { 'leave': this._stageAlert.day.leave, 'side': this._stageAlert.day.side, 'sit': this._stageAlert.day.sit },
                'noon': { 'leave': this._stageAlert.noon.leave, 'side': this._stageAlert.noon.side, 'sit': this._stageAlert.noon.sit },
                'night': { 'leave': this._stageAlert.night.leave, 'side': this._stageAlert.night.side, 'sit': this._stageAlert.night.sit }
            },
            'activityAlert': {
                'range': {
                    'allDay': this._activityAlert.range.allDay,
                    'from': this._activityAlert.range.from,
                    'to': this._activityAlert.range.to
                },
                'lie': { 'checked': this._activityAlert.lie.checked, 'threshold': this._activityAlert.lie.threshold },
                'lower': {
                    'checked': this._activityAlert.lower.checked,
                    'threshold': this._activityAlert.lower.threshold,
                    'duration': this._activityAlert.lower.duration,
                },
                'over': { 'checked': this._activityAlert.over.checked, 'threshold': this._activityAlert.over.threshold },
                'rollOver': {
                    'checked': this._activityAlert.rollOver.checked,
                    'threshold': this._activityAlert.rollOver.threshold,
                    'duration': this._activityAlert.rollOver.duration,
                },
            },
            'created': this._created,
            'index': this._index
        };
        if (this._miTempDeviceId.length !== 0) {
            json['miTempDeviceId'] = this._miTempDeviceId
        }
        return json
    }
}

/**
 * stage alert => {
 *      day: {
 *          leave: false, side: false, sit: false,
 *      },
 *      noon: {
 *          ...
 *      },
 *      night: {
 *          ...
 *      }
 * }
 *
 *
 * activity alert => {
 *      range: { allDay: false, from: {h: 0, m: 0}, to: {h: 23, m: 55} },
 *      lie: { checked: true, threshold: {h:0, m:0} },
 *      activityOver: { checked: true, threshold: 60 },
 *      activityLower: { checked: true, threshold: 20 }
 * }
 *
 */