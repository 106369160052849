import * as Rx from 'rxjs';
import { tap, filter, debounceTime } from 'rxjs/operators';

import * as SettingsApi from '../../../model/api/settings_api';
import { ArrangeShift } from '../../../model/data/arrange_shift';

export const ArrangeShiftDialogId = {
    btnSave: 0,
}

export const ArrangeShiftDialogState = {
    loading: 0, normal: 1, saving: 2,
}

export default class ArrangeShiftBloc {
    constructor() {
        this._isInit = false;
    }

    get isInit() { return this._isInit; }

    get pageState() { return this._pageState; }

    get day() { return this._day; }
    get noon() { return this._noon; }
    get night() { return this._night; }

    get updateSliders() { return this._updateSliders; }
    get dismiss() { return this._dismiss; }

    init() {
        this._disposables = new Rx.Subscription();

        this._pageState = new Rx.BehaviorSubject(ArrangeShiftDialogState.loading);
        /* action: 1 => trigger observer, action: 0 => nothing */
        this._day = new Rx.BehaviorSubject({ action: 1, start: { h: 7, m: 0 }, end: { h: 15, m: 0 } });
        this._noon = new Rx.BehaviorSubject({ action: 1, start: { h: 15, m: 0 }, end: { h: 23, m: 0 } });
        this._night = new Rx.BehaviorSubject({ action: 1, start: { h: 23, m: 0 }, end: { h: 7, m: 0 } });
        this._updateSliders = new Rx.Subject();
        this._dismiss = new Rx.Subject();

        this._setDayObserver();
        this._setNoonObserver();
        this._setNightObserver();

        this._isInit = true;

        this.getArrangeShift();
    }

    dispose() {
        this._disposables.unsubscribe();
        this._isInit = false;
    }

    getArrangeShift() {
        let it = SettingsApi.getArrangeShift()
            .subscribe({
                next: (data) => {
                    const { day, noon, night } = data.range;
                    day.action = 1;
                    this._day.next(day);
                    noon.action = 1;
                    this._noon.next(noon);
                    night.action = 1;
                    this._night.next(night);
                    this._pageState.next(ArrangeShiftDialogState.normal);
                },
                error: err => {

                },
            });
        this._disposables.add(it);
    }

    putArrangeShift() {
        const data = {
            day: this._day.getValue(),
            noon: this._noon.getValue(),
            night: this._night.getValue(),
        };
        const daySec = data.day.start.h * 60 + data.day.start.m;
        const noonSec = data.noon.start.h * 60 + data.noon.start.m;
        const nightSec = data.night.start.h * 60 + data.night.start.m;
        let it = SettingsApi.putArrangeShift(new ArrangeShift(daySec, noonSec, nightSec))
            .subscribe({
                next: ({ day, noon, night }) => {

                },
                error: err => {
                    this._pageState.next(ArrangeShiftDialogState.normal);
                },
                complete: () => {
                    this._dismiss.next(true);
                }
            });
        this._disposables.add(it);
        this._pageState.next(ArrangeShiftDialogState.saving);
    }

    _setDayObserver() {
        let it = this._day.pipe(
            debounceTime(500),
            filter(obj => obj.action === 1),
        ).subscribe({
            next: ({ start, end }) => {
                let noon = this._noon.getValue();
                let night = this._night.getValue();
                noon.action = 0;
                noon.start = end;
                night.action = 0;
                night.end = start;
                this._noon.next(noon);
                this._night.next(night);
                this._updateSliders.next(1);
            },
            error: err => {
            },
        });
        this._disposables.add(it);
    }

    _setNoonObserver() {
        let it = this._noon.pipe(
            debounceTime(500),
            filter(obj => obj.action === 1),
        ).subscribe({
            next: ({ start, end }) => {
                let night = this._night.getValue();
                let day = this._day.getValue();
                night.action = 0;
                night.start = end;
                day.action = 0;
                day.end = start;
                this._night.next(night);
                this._day.next(day);
                this._updateSliders.next(1);
            },
            error: err => {
            },
        });
        this._disposables.add(it);
    }

    _setNightObserver() {
        let it = this._night.pipe(
            debounceTime(500),
            filter(obj => obj.action === 1),
        ).subscribe({
            next: ({ start, end }) => {
                let day = this._day.getValue();
                let noon = this._noon.getValue();
                day.action = 0;
                day.start = end;
                noon.action = 0;
                noon.end = start;
                this._day.next(day);
                this._noon.next(noon);
                this._updateSliders.next(1);
            },
            error: err => {
            },
        });
        this._disposables.add(it);
    }
}