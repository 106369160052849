import * as Rx from 'rxjs';

import * as SettingsApi from '../../../model/api/settings_api';

import Gender from '../../../model/data/gender';
import PadConfig from '../../../model/data/pad_config';
import { ArrangeShift } from '../../../model/data/arrange_shift';

import * as Strings from '../../../styles/strings';

var Lodash = require('lodash');

export default class ManagementBloc {

    constructor() {
        console.log(`ManagementBloc constructor: ${this._isInit}`);
    }

    get arrangeShift() { return this._arrangeShift; }
    get arrangeShiftDialog() { return this._arrangeShiftDialog; }

    get gridViewSize() { return this._gridViewSize; }

    get configDownloading() { return this._configDownloading; }
    get configs() { return this._configs; }// deprecate
    get padList() { return this._padList; }
    get selectedConfig() { return this._selectedConfig; }
    get config() { return this._config; }
    get updatedConfigs() { return this._updatedConfigs; }
    get isDataDiff() { return this._isDataDiff; }

    get loadingDialog() { return this._loadingDialog; }
    get addPadDialog() { return this._addPadDialog; }
    get changePadSeqDialog() { return this._changePadSeqDialog; }

    init() {
        this._disposables = new Rx.Subscription();

        this._arrangeShift = new Rx.BehaviorSubject(new ArrangeShift(420, 900, 1380));
        this._arrangeShiftDialog = new Rx.BehaviorSubject({ show: false });

        this._gridViewSize = new Rx.Subject();

        this._configDownloading = new Rx.BehaviorSubject(false);
        this._configs = new Rx.BehaviorSubject([]);// deprecate
        this._padList = new Rx.BehaviorSubject([]);
        this._selectedConfig = new Rx.BehaviorSubject({ index: 0 });
        this._config = new Rx.BehaviorSubject({});
        this._updatedConfig = new Rx.BehaviorSubject({});
        this._updatedConfigs = new Rx.BehaviorSubject([]);
        this._isDataDiff = new Rx.BehaviorSubject(false);

        this._loadingDialog = new Rx.BehaviorSubject({ show: false, message: Strings.saving });
        this._addPadDialog = new Rx.BehaviorSubject({ show: false });
        this._changePadSeqDialog = new Rx.BehaviorSubject({ show: false });

        console.log(`ManagementBloc init`);
        // console.log(this._arrangeShift);
        // console.log(this._configs);
        this.getArrangeShift();
        // this.getConfigs(); // deprecate
        this._getPadList();
    }

    dispose() {
        this._disposables.unsubscribe();
    }

    // updateData(index, config) {
    //     let updatedConfigs = this._updatedConfigs.getValue();
    //     updatedConfigs[index] = config;
    //     this._calculateDiff();
    // }

    updateData(config) {
        this._updatedConfig.next(Lodash.cloneDeep(config));
        this._calculateDiff();
    }

    // _calculateDiff() {
    //     let updatedConfigs = this._updatedConfigs.getValue();
    //     let configs = this._configs.getValue();
    //     let diff = false;
    //     for (let i = 0; i < configs.length; i++) {
    //         if (configs[i].isDifferent(updatedConfigs[i])) {
    //             diff = true;
    //             break;
    //         }
    //     }
    //     this._isDataDiff.next(diff);
    // }

    _calculateDiff() {
        let updatedConfig = this._updatedConfig.getValue();
        let config = this._config.getValue();
        let diff = false;
        if (config.isDifferent(updatedConfig)) {
            diff = true;
        }
        this._isDataDiff.next(diff);
    }

    getArrangeShift() {
        let it = SettingsApi.getArrangeShift()
            .subscribe({
                next: config => {
                    console.log(`get arrange shift: ${config}`);
                    this._arrangeShift.next(config);
                },
                error: err => {

                },
                complete: () => {

                }
            });
        this._disposables.add(it);
    }

    btnDevicesClicked(index) {
        if (this._selectedConfig.getValue().index !== index) {
            this._selectedConfig.next({ index: index })
            this._getConfig();
        }
    }

    _getPadList() {
        this._padList.next([]);
        this.configDownloading.next(true);
        let it = SettingsApi.getPadList()
            .subscribe({
                next: padList => {
                    console.log("padListDebug", padList)
                    padList = padList.sort((a, b) => a.index - b.index);
                    this._padList.next(padList);
                },
                error: err => {
                    this.configDownloading.next(false);
                },
                complete: () => {
                    //if pad list is empty
                    if (this._padList.getValue().length === 0) {
                        this.configDownloading.next(false);
                        this._config.next(null);
                    } else {
                        this._getConfig();
                    }
                    // this.configDownloading.next(false);
                }
            });
        this._disposables.add(it);
    }

    _getConfig() {
        this._config.next({});
        this.configDownloading.next(true);
        const index = this._selectedConfig.getValue().index;
        let deviceId = this._padList.getValue()[index].deviceId;

        let it = SettingsApi.getConfig(deviceId)
            .subscribe({
                next: config => {
                    console.log('config', config);
                    this._isDataDiff.next(false);
                    this._config.next(config);
                    this._updatedConfig.next(Lodash.cloneDeep(config));
                },
                error: err => {
                    this.configDownloading.next(false);
                },
                complete: () => {
                    this.configDownloading.next(false);
                }
            });
        this._disposables.add(it);
    }

    // deprecate
    getConfigs() {
        this._configs.next([]);
        this.configDownloading.next(true);
        let it = SettingsApi.getConfigs()
            .subscribe({
                next: configs => {
                    console.log(`get configs: ${configs}`);
                    this._isDataDiff.next(false);
                    this._configs.next(configs);
                    this._updatedConfigs.next(Lodash.cloneDeep(configs));
                },
                error: err => {
                    this.configDownloading.next(false);
                },
                complete: () => {
                    this.configDownloading.next(false);
                }
            });
        this._disposables.add(it);
    }

    saveConfig() {
        this._loadingDialog.next({ show: true, message: Strings.saving });
        let it = SettingsApi.updateConfigs([this._updatedConfig.getValue()])
            .pipe()
            .subscribe({
                next: (config) => {
                    // save single config -> index 0
                    this._config.next(config[0]);
                    this._calculateDiff();
                },
                error: err => {
                    this._loadingDialog.next({ show: false, message: Strings.saving });
                },
                complete: () => {
                    this._loadingDialog.next({ show: false, message: Strings.saving });
                    this._getPadList();
                }
            });
        this._disposables.add(it);
    }

    saveConfigs() {
        this._loadingDialog.next({ show: true, message: Strings.saving });
        let it = SettingsApi.updateConfigs(this._updatedConfigs.getValue())
            .pipe()
            .subscribe({
                next: (configs) => {
                    this._configs.next(configs);
                    this._calculateDiff();
                },
                error: err => {
                    this._loadingDialog.next({ show: false, message: Strings.saving });
                },
                complete: () => {
                    this._loadingDialog.next({ show: false, message: Strings.saving });
                }
            });
        this._disposables.add(it);
    }

    removeConfig(deviceId) {
        this._loadingDialog.next({ show: true, message: Strings.deleting });
        let it = SettingsApi.removeConfig(deviceId)
            .pipe()
            .subscribe({
                next: (deviceIds) => {
                    // this.getConfigs();
                },
                error: err => {
                    this._loadingDialog.next({ show: false, message: Strings.deleting });
                },
                complete: () => {
                    this._loadingDialog.next({ show: false, message: Strings.deleting });
                    this._selectedConfig.next({ index: 0 })
                    this._getPadList();
                }
            });
        this._disposables.add(it);
    }

    findIndex(deviceId) {
        let it = this._padList.subscribe({
            next: (padlist) => {
                if (padlist.length) {
                    padlist.map(item => {
                        if (item.deviceId === deviceId) {
                            return this._selectedConfig.next({index: item.index});
                        }
                    })
                }
            },
        });
        this._disposables.add(it);
    }
}