import React, { useContext, useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles'
import intl from 'react-intl-universal'
import { Redirect, Link } from 'react-router-dom'

import { MainContext, HomeContext } from '../../../utils/context_utils';
import { PadNotification } from '../../../model/data/pad_notification';

import * as Dimens from '../../../styles/dimens';
import * as Colors from '../../../styles/colors';
import Stack from '../../../components/layout/stack';
import Column from '../../../components/layout/column';
import Row from '../../../components/layout/row';
import BatteryIcon from '../../../components/widgets/battery_icon';
import PadSensorWidget from '../../../components/widgets/pad_sensor_widget';

import { Paper, IconButton, Tooltip } from '@material-ui/core';
import ProgressBar from 'react-bootstrap/ProgressBar'
import IcSetting from '@material-ui/icons/SettingsRounded';
import IcHistory from '@material-ui/icons/InsertChartRounded';
import IcOffline from '@material-ui/icons/ErrorRounded';
import IcNotification from '@material-ui/icons/NotificationsRounded'
import { ReactComponent as IcTemperature } from '../../../assets/ic_temperature.svg'
import { ReactComponent as IcHumidity } from '../../../assets/ic_humidity.svg'

const Color = require('color');

PadItem.propTypes = {
    index: PropTypes.number.isRequired,
    cardWidth: PropTypes.number.isRequired,
    data: PropTypes.any,
    onHistoryClick: PropTypes.func,
    onSettingClick: PropTypes.func,
}

export default function PadItem(props) {
    const classes = useStyles(props);
    const offlineStyles = useOfflineStyle(props);

    const _mainBloc = useContext(MainContext).bloc;
    const _bloc = useContext(HomeContext).bloc;
    const [refreshCount, setRefreshCount] = useState(0)

    const isLocalLan = process.env.REACT_APP_LOCAL === "true";

    /* --- functions --- */

    useEffect(() => {
        const index = props.index
        if (index >= props.data.length) return () => { }
        const timer = setInterval(() => {
            if (props.data[props.index].pad.miTemp !== undefined) {
                setRefreshCount(prev => prev + 1)
            }
        }, 10000)
        return () => {
            clearInterval(timer)
        }
    }, [])

    /* --- render --- */

    return (() => {
        const index = props.index
        const data = props.data
        const { arrangeShift } = _mainBloc.padsData
        if (index >= data.length) return null
        const contentWidth = props.cardWidth - 2 * Dimens.marginS
        const sensorWidth = contentWidth * 0.7
        const sensorHeight = sensorWidth / 5 * 6 * 1.3
        const config = data[index].config
        const pad = data[index].pad
        const arrangeShiftType = arrangeShift.nowType()
        let tempColor = Colors.padEventGreen, humColor = Colors.padEventGreen;
        if (pad.miTemp !== undefined) {
            if (pad.miTemp.temperature < 18) {
                tempColor = Colors.padEventBlue
            } else if (pad.miTemp.temperature > 25) {
                tempColor = Colors.padEventRed
            }
            if (pad.miTemp.humidity < 40 || pad.miTemp.humidity > 70) {
                humColor = Colors.padEventRed
            }
        }
        // 0: activity, 1: mi temperature
        // let type = refreshCount % 2
        let type = 1;
        if (pad.miTemp === undefined) {
            type = 0
        }
        if (data.deviceId === 'WhizPad_RC-FFFFFFFFFFFF') {
            console.log(`pad item render type: ${type}`)
        }
        return (
            <Stack width={`${props.style.width}px`} height={`${props.style.height}px`} style={props.style} >
                <Paper elevation={3} className={classes.root} >
                    <Stack width='100%' height='100%' >
                        <Column width='100%' height='100%' className={classes.contentLayout} >
                            {/* 床墊Header列 */}
                            <Row width='100%' height={`${Dimens.padHeaderBar}px`} className={classes.headerBar} >
                                <Tooltip title={intl.get('page_home_tooltip_battery', { level: `${Math.floor(pad.battery * 100)}%` })}>
                                    <div className={classes.headerBarBatteryIconLayout}>
                                        <BatteryIcon level={Math.floor(pad.online === 1 ? pad.battery * 100 : 100)} className={classes.headerBarBatteryIcon} />
                                    </div>
                                </Tooltip>
                                <Column width='auto' height='auto' className={classes.headerBarTextLayout}>
                                    <span className={`${classes.headerBarTitleText} ${classes.headerBarText}`} >{config.name}</span>
                                    <span className={`${classes.headerBarIdText} ${classes.headerBarText}`} >{pad.macAddress}</span>
                                </Column>
                                <Tooltip title={intl.get('page_home_tooltip_route_to_management')}>
                                    <SvgIconButton style={{ visibility: 'visible' }} onClick={() => {
                                        if (props.onSettingClick) {
                                            props.onSettingClick();
                                        }
                                    }} ><IcSetting className={classes.iconBtn} /></SvgIconButton>
                                </Tooltip>
                            </Row>
                            {/* 床墊感測器及事件 */}
                            <Row width='100%' height='auto' className={classes.sensorsLayout} >
                                <PadSensorWidget className={classes.sensors} width={contentWidth} height={sensorHeight}
                                    firmwareVer={pad.firmwareVer} online={pad.online === 1} event={pad.event} sensors={pad.sensors} gender={config.gender} />
                            </Row>
                            {/* Footer */}
                            <Row width='100%' height={`${Dimens.padFooterBar}px`} className={classes.footerLayout} >
                                {type === 0 ?
                                    <Fragment>
                                        <Tooltip title={intl.get('page_home_tooltip_route_to_history')}>
                                            <SvgIconButton onClick={() => {
                                                if (props.onHistoryClick) {
                                                    props.onHistoryClick();
                                                }
                                            }}><IcHistory className={classes.iconBtn} /></SvgIconButton>
                                        </Tooltip>
                                        {isLocalLan ?
                                            (
                                                <>
                                                    <Tooltip title={intl.get('page_home_tooltip_activity_1min')}>
                                                        <ActivityBar now={pad.online ? pad.activity1Min : 0} animated />
                                                    </Tooltip>
                                                    <span className={classes.activityText} ><span className={classes.activityNumText} >{pad.online ? pad.activity1Min : 0}</span> %</span>
                                                </>
                                            ) : (
                                                <>
                                                    <Tooltip title={intl.get('page_home_tooltip_activity')}>
                                                        <ActivityBar now={pad.online ? pad.activity : 0} animated />
                                                    </Tooltip>
                                                    <span className={classes.activityText} ><span className={classes.activityNumText} >{pad.online ? pad.activity : 0}</span> %</span>
                                                </>
                                            )}

                                    </Fragment>
                                    : (
                                        <>
                                            <Tooltip title={intl.get('page_home_tooltip_route_to_history')}>
                                                <SvgIconButton onClick={() => {
                                                    if (props.onHistoryClick) {
                                                        props.onHistoryClick();
                                                    }
                                                }}><IcHistory className={classes.iconBtn} /></SvgIconButton>
                                            </Tooltip>
                                            <Tooltip title={intl.get('page_home_tooltip_activity')}>
                                                <span style={{ width: '15%' }} className={classes.activityText} >
                                                    {isLocalLan ?
                                                        <span className={classes.activityNumText} >{pad.online ? pad.activity1Min : 0}</span>
                                                        :
                                                        <span className={classes.activityNumText} >{pad.online ? pad.activity : 0}</span>
                                                    }
                                                    %
                                                </span>
                                            </Tooltip>

                                            <Row width='100%' height='100%' style={{ justifyContent: 'flex-end', alignItems: 'center' }} >
                                                <Row width='auto' height='auto'>
                                                    <SvgWrapper color={tempColor}>
                                                        <IcTemperature style={{ width: `18px`, height: `18px` }} alt='ic_temperature' />
                                                    </SvgWrapper>
                                                    <span className={classes.miTempText}>
                                                        <span className={classes.miTempNumText}>{pad.miTemp.temperature.toFixed(1)}</span>
                                                        {` °C`}
                                                    </span>
                                                </Row>
                                                <Row width='auto' height='auto' style={{ marginLeft: `${Dimens.marginS}px` }}>
                                                    <SvgWrapper color={humColor}>
                                                        <IcHumidity style={{ width: `18px`, height: `18px` }} alt='ic_humidity' />
                                                    </SvgWrapper>
                                                    <span className={classes.miTempText}>
                                                        <span className={classes.miTempNumText}>{pad.miTemp.humidity}</span>
                                                        {` %`}
                                                    </span>
                                                </Row>
                                            </Row>
                                        </>
                                    )
                                }
                                {/* {type === 1 ?
                                    <Fragment>
                                        <Row width='100%' height='100%' style={{ justifyContent: 'space-around', alignItems: 'center' }} >
                                            <Row width='auto' height='auto'>
                                                <SvgWrapper color={tempColor}>
                                                    <IcTemperature style={{ width: `${Dimens.padMiTempIcon}px`, height: `${Dimens.padMiTempIcon}px` }} alt='ic_temperature' />
                                                </SvgWrapper>
                                                <span style={{ marginLeft: `${Dimens.marginS}px` }} className={classes.miTempText}>
                                                    <span className={classes.miTempNumText}>{pad.miTemp.temperature.toFixed(1)}</span>
                                                    {` °C`}
                                                </span>
                                            </Row>
                                            <Row width='auto' height='auto'>
                                                <SvgWrapper color={humColor}>
                                                    <IcHumidity style={{ width: `${Dimens.padMiTempIcon}px`, height: `${Dimens.padMiTempIcon}px` }} alt='ic_humidity' />
                                                </SvgWrapper>
                                                <span style={{ marginLeft: `${Dimens.marginS}px` }} className={classes.miTempText}>
                                                    <span className={classes.miTempNumText}>{pad.miTemp.humidity}</span>
                                                    {` %`}
                                                </span>
                                            </Row>
                                        </Row>
                                    </Fragment>
                                    : null
                                } */}
                            </Row>
                            {/* 床墊設定簡易顯示列 */}
                            <Row width='100%' height={`${Dimens.padAlertConfigBar}px`} reverse
                                className={classes.alertConfigBar}>
                                {!config.stageAlertNow(arrangeShiftType, PadNotification.Leave) ? null :
                                    <PadAlertConfigIcon color={PadNotification.Leave.color} isFirst >
                                        <span className={classes.alertConfigBarIconText} >{intl.get('pad_notification_short_off_bed')}</span>
                                    </PadAlertConfigIcon>
                                }
                                {!config.stageAlertNow(arrangeShiftType, PadNotification.SitSide) ? null :
                                    <PadAlertConfigIcon color={PadNotification.SitSide.color} >
                                        <span className={classes.alertConfigBarIconText} >{intl.get('pad_notification_short_side')}</span>
                                    </PadAlertConfigIcon>
                                }
                                {!config.stageAlertNow(arrangeShiftType, PadNotification.Sit) ? null :
                                    <PadAlertConfigIcon color={PadNotification.Sit.color} >
                                        <span className={classes.alertConfigBarIconText} >{intl.get('pad_notification_short_sit')}</span>
                                    </PadAlertConfigIcon>
                                }
                                <Row width='auto' height='100%' className={classes.alertConfigLayout} >
                                    <Tooltip title={intl.get('page_home_tooltip_alert_records')}>
                                        <SvgIconButton onClick={() => { _bloc.notiHistoryClicked.next(config) }} >
                                            <IcNotification className={classes.iconBtn} />
                                        </SvgIconButton>
                                    </Tooltip>
                                </Row>
                            </Row>
                        </Column>
                        {/* 離線原因 */}
                        {pad.online === 1 ? null :
                            <Column width='100%' height='100%' className={offlineStyles.root}>
                                <Tooltip title={
                                    <Column width='auto' height='auto' style={{ alignItems: 'center', justifyContent: 'center' }} >
                                        <span style={{ fontSize: Dimens.textS, fontWeight: 'bold' }} >{config.name}</span>
                                        <span style={{ fontSize: Dimens.textXS, marginTop: `${Dimens.marginS}px` }} >{config.macAddress}</span>
                                    </Column>
                                } >
                                    <IcOffline className={offlineStyles.iconOffline} />
                                </Tooltip>
                                <span className={offlineStyles.offlineText}>
                                    {pad.online === 0 ? intl.get('page_home_message_pad_offline') : intl.get('page_home_message_gateway_offline')}
                                </span>
                            </Column>
                        }
                    </Stack>
                </Paper>
            </Stack>
        );
    })();
}

const useStyles = makeStyles({
    root: {
        width: props => `${props.cardWidth}px`,
        height: props => `${props.style.height - 2 * Dimens.marginM}px`,
        position: 'absolute',
        top: `50%`,
        left: `50%`,
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#ffffff',
        borderRadius: `${Dimens.padCorner}px`,
        overflow: 'hidden',
    },
    contentLayout: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        paddingLeft: `${Dimens.marginS}px`,
        paddingRight: `${Dimens.marginS}px`,
    },
    iconBtn: {
        width: `${Dimens.padIconBtn}px`,
        height: `${Dimens.padIconBtn}px`,
        color: Colors.iconDefault,
    },
    alertConfigBar: {
        alignItems: 'center',
    },
    alertConfigBarIconText: {
        fontSize: Dimens.textXS,
        color: Colors.textWDark,
    },
    alertConfigLayout: {
        flex: '1',
        alignItems: 'center',
    },
    headerBar: {
        alignItems: 'center',
    },
    headerBarBatteryIconLayout: {
        width: `${Dimens.padIcon}px`,
        height: `${Dimens.padIcon}px`,
    },
    headerBarBatteryIcon: {
        width: `${Dimens.padIcon}px`,
        height: `${Dimens.padIcon}px`,
    },
    headerBarTextLayout: {
        flex: 1,
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: `${Dimens.marginS}px`,
        marginRight: `${Dimens.marginS}px`,
    },
    headerBarText: {
        width: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        textAlign: 'center',
    },
    headerBarTitleText: {
        fontSize: Dimens.textM,
        color: Colors.textBNormal,
        fontWeight: 'bold',
    },
    headerBarIdText: {
        fontSize: Dimens.textXS,
        color: Colors.textBXLight,
    },
    sensorsLayout: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    footerLayout: {
        alignItems: 'center',
    },
    activityNumText: {
        fontSize: Dimens.textM,
        fontWeight: 'bold',
        color: Colors.textBNormal,
    },
    activityText: {
        fontSize: Dimens.textXS,
        color: Colors.textBLight,
    },
    miTempNumText: {
        fontSize: Dimens.textM,
        fontWeight: 'bold',
        color: Colors.textBNormal,
    },
    miTempText: {
        fontSize: Dimens.textXS,
        color: Colors.textBLight,
    }
});

const useOfflineStyle = makeStyles({
    root: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        backgroundColor: Color(Colors.padOfflineDim).alpha(0.7).hsl().string(),
        // opacity: 0.45,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: `${Dimens.padCorner}px`,
    },
    iconOffline: {
        width: `${Dimens.padOfflineIcon}px`,
        height: `${Dimens.padOfflineIcon}px`,
        color: Colors.padOfflineIcon,
    },
    offlineText: {
        marginTop: `${Dimens.marginM}px`,
        fontSize: Dimens.textM,
        color: Colors.textWDark,
        fontWeight: 'bold',
    }
});

const PadAlertConfigIcon = styled(Column).attrs(props => ({
    width: `${Dimens.padAlertConfigBarIcon}px`,
    height: `${Dimens.padAlertConfigBarIcon}px`,
}))`
    background-color: ${props => props.color};
    border-radius: ${Dimens.padAlertConfigBarIcon / 2}px;
    align-items: center;
    justify-content: center;
    margin-right: ${props => props.isFirst ? 0 : Dimens.marginS}px;
`;

const SvgIconButton = styled(IconButton)`
    && {
        align-self: center;
        padding: ${Dimens.padIconBtnPadding}px;
    }
    &:focus {
        outline: none;
    }
`;

const ActivityBar = styled(ProgressBar)`
    flex: 1;
    align-self: center;
    margin-left: ${Dimens.marginS}px;
    margin-right: ${Dimens.marginS}px;
    height: ${Dimens.padActivityProgress}px;
    .progress-bar {
        background-color: #4db6ac;
    }
`;

const SvgWrapper = styled.div`
    display: flex;
  & path {
    fill: ${props => props.color ? props.color : 'red'};
  }
`;