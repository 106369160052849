import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles'
import intl from 'react-intl-universal'

import * as Rx from 'rxjs';

import ArrangeShiftBloc, { ArrangeShiftDialogId, ArrangeShiftDialogState } from '../../../bloc/main/management/arrange_shift_bloc';
import { ArrangeShift } from '../../../model/data/arrange_shift';

import * as Dimens from '../../../styles/dimens';
import * as Colors from '../../../styles/colors';
import Column from '../../../components/layout/column';
import Row from '../../../components/layout/row';
import Stack from '../../../components/layout/stack';
import BlocBuilder from 'bloc-builder-react';
import TimeCircularSlider from '../../../components/widgets/slider/time_circular_slider'
import { Dialog, ButtonBase } from '@material-ui/core';
import Lottie from 'react-lottie';

import IcDay from '../../../assets/ic_day.svg';
import IcNoon from '../../../assets/ic_noon.svg';
import IcNight from '../../../assets/ic_night.svg';

import AnimLoading from '../../../assets/lottie/textfield_loading.json';

const printf = require('printf');

ArrangeShiftDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
    onTimeSelected: PropTypes.func,
}

const _bloc = new ArrangeShiftBloc();
let _disposables = new Rx.Subscription();

export default function ArrangeShiftDialog(props) {

    const classes = useStyles(props);

    const [init, setInit] = useState(false);
    const [pageState, setPageState] = useState(ArrangeShiftDialogState.normal);

    useEffect(() => {
        if (props.open) {
            console.log(`ArrangeShiftDialog init`);
            _disposables = new Rx.Subscription();
            _bloc.init();
            setPageStateObserver();
            setDismissObserver();
            setInit(true);
        } else if (_bloc.isInit) {
            console.log(`ArrangeShiftDialog deinit`);
            _bloc.dispose();
            _disposables.unsubscribe();
            setInit(false);
        }
        return () => {
        };
    }, [props.open]);

    const onDayTimeUpdate = useRef(({ start, end }) => {
        _bloc.day.next({
            action: 1, start: start, end: end,
        });
    });

    const onNoonTimeUpdate = useRef(({ start, end }) => {
        _bloc.noon.next({
            action: 1, start: start, end: end,
        });
    });

    const onNightTimeUpdate = useRef(({ start, end }) => {
        _bloc.night.next({
            action: 1, start: start, end: end,
        });
    });

    const onClicked = (id) => () => {
        switch (id) {
            case ArrangeShiftDialogId.btnSave:
                _bloc.putArrangeShift();
                break;
            default:
                break;
        }
    };

    return (() => {
        if (!_bloc.isInit) return null;
        return (
            <Dialog onClose={props.onClose} open={props.open}>
                <Stack width='auto' height='auto'>
                    <Column width='auto' height='auto' className={classes.root}>
                        <Title>{intl.get('dialog_arrange_shift_title')}</Title>
                        <Row width='550px' height='auto'
                            className={classes.sliderLayout}
                            style={{ visibility: pageState === ArrangeShiftDialogState.normal ? 'visible' : 'hidden' }}>
                            <Column width='auto' height='auto' className={classes.sliderItemLayout}>
                                <Row width='auto' height='auto' className={classes.titleLayout}>
                                    <img src={IcDay} alt='ic_day' className={classes.shiftIcon}></img>
                                    <ItemTitle>{intl.get('arrange_shift_day')}</ItemTitle>
                                </Row>
                                <BlocBuilder
                                    initialValue={0}
                                    subject={_bloc.updateSliders}
                                    builder={(snapshot) => {
                                        const data = _bloc.day.getValue();
                                        const time = { start: data.start, end: data.end };
                                        return (
                                            <TimeCircularSlider
                                                key='day'
                                                className={classes.slider}
                                                initTime={time}
                                                onUpdate={onDayTimeUpdate.current}
                                                segments={10}
                                                strokeWidth={15}
                                                radius={50}
                                                gradientColorFrom="#4baea0"
                                                gradientColorTo="#b6e6bd"
                                                trackColor="#171717" />
                                        );
                                    }} />
                                <BlocBuilder
                                    initialValue={_bloc.day.getValue()}
                                    subject={_bloc.day}
                                    builder={(snapshot) => {
                                        const start = snapshot.data.start;
                                        const end = snapshot.data.end;
                                        return (
                                            <ShiftTitle>
                                                <ShiftNumTitle>{printf("%02d:%02d", start.h, start.m)}</ShiftNumTitle> {intl.get('date_until')} <ShiftNumTitle>{printf("%02d:%02d", end.h, end.m)}</ShiftNumTitle>
                                            </ShiftTitle>
                                        );
                                    }} />
                            </Column>
                            <Column width='auto' height='auto' className={classes.sliderItemLayout}>
                                <Row width='auto' height='auto' className={classes.titleLayout}>
                                    <img src={IcNoon} alt='ic_noon' className={classes.shiftIcon}></img>
                                    <ItemTitle>{intl.get('arrange_shift_noon')}</ItemTitle>
                                </Row>
                                <BlocBuilder
                                    initialValue={0}
                                    subject={_bloc.updateSliders}
                                    builder={(snapshot) => {
                                        const data = _bloc.noon.getValue();
                                        const time = { start: data.start, end: data.end };
                                        return (
                                            <TimeCircularSlider
                                                key='noon'
                                                className={classes.slider}
                                                initTime={time}
                                                onUpdate={onNoonTimeUpdate.current}
                                                segments={10}
                                                strokeWidth={15}
                                                radius={50}
                                                gradientColorFrom="#ff9800"
                                                gradientColorTo="#ffcf00"
                                                trackColor="#171717" />
                                        );
                                    }} />
                                <BlocBuilder
                                    initialValue={_bloc.noon.getValue()}
                                    subject={_bloc.noon}
                                    builder={(snapshot) => {
                                        const start = snapshot.data.start;
                                        const end = snapshot.data.end;
                                        return (
                                            <ShiftTitle>
                                                <ShiftNumTitle>{printf("%02d:%02d", start.h, start.m)}</ShiftNumTitle> {intl.get('date_until')} <ShiftNumTitle>{printf("%02d:%02d", end.h, end.m)}</ShiftNumTitle>
                                            </ShiftTitle>
                                        );
                                    }} />
                            </Column>
                            <Column width='auto' height='auto' className={classes.sliderItemLayout}>
                                <Row width='auto' height='auto' className={classes.titleLayout}>
                                    <img src={IcNight} alt='ic_night' className={classes.shiftIcon}></img>
                                    <ItemTitle>{intl.get('arrange_shift_night')}</ItemTitle>
                                </Row>
                                <BlocBuilder
                                    initialValue={0}
                                    subject={_bloc.updateSliders}
                                    builder={(snapshot) => {
                                        const data = _bloc.night.getValue();
                                        const time = { start: data.start, end: data.end };
                                        return (
                                            <TimeCircularSlider
                                                className={classes.slider}
                                                initTime={time}
                                                onUpdate={onNightTimeUpdate.current}
                                                segments={10}
                                                strokeWidth={15}
                                                radius={50}
                                                gradientColorFrom="#587983"
                                                gradientColorTo="#9db3b4"
                                                trackColor="#171717" />
                                        );
                                    }} />
                                <BlocBuilder
                                    initialValue={_bloc.night.getValue()}
                                    subject={_bloc.night}
                                    builder={(snapshot) => {
                                        const start = snapshot.data.start;
                                        const end = snapshot.data.end;
                                        return (
                                            <ShiftTitle>
                                                <ShiftNumTitle>{printf("%02d:%02d", start.h, start.m)}</ShiftNumTitle> {intl.get('date_until')} <ShiftNumTitle>{printf("%02d:%02d", end.h, end.m)}</ShiftNumTitle>
                                            </ShiftTitle>
                                        );
                                    }} />
                            </Column>
                        </Row>
                        <div className={classes.btnDivider}
                            style={{ visibility: pageState === ArrangeShiftDialogState.normal ? 'visible' : 'hidden' }} />
                        <ButtonBase className={classes.btnSave}
                            style={{ visibility: pageState === ArrangeShiftDialogState.normal ? 'visible' : 'hidden' }}
                            onClick={onClicked(ArrangeShiftDialogId.btnSave)}>
                            <span className={classes.btnSaveText}>{intl.get('save')}</span>
                        </ButtonBase>
                    </Column>
                    <Column width='100%' height='100%' className={classes.animLayout}
                        style={{
                            visibility: pageState === ArrangeShiftDialogState.normal ? 'hidden' : 'visible',
                        }}>
                        <Lottie
                            isClickToPauseDisabled
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: AnimLoading,
                                rendererSettings: {
                                    preserveAspectRatio: 'xMidYMid slice'
                                }
                            }}
                            width='25%'
                            height='auto' />
                        <span className={classes.animText}>{getAnimText()}</span>
                    </Column>
                </Stack>
            </Dialog>
        );
    })();

    function getAnimText() {
        switch (pageState) {
            case ArrangeShiftDialogState.loading:
                return intl.get('loading_read')
            case ArrangeShiftDialogState.saving:
                return intl.get('loading_save')
            default:
                return '';
        }
    }

    function setPageStateObserver() {
        let it = _bloc.pageState.subscribe({
            next: state => {
                setPageState(state);
            },
            error: err => {
            },
        });
        _disposables.add(it);
    }

    function setDismissObserver() {
        let it = _bloc.dismiss.subscribe({
            next: enable => {
                if (props.onTimeSelected !== undefined)
                    props.onTimeSelected(
                        new ArrangeShift(
                            getSec(_bloc.day.getValue().start),
                            getSec(_bloc.noon.getValue().start), 
                            getSec(_bloc.night.getValue().start)));
                props.onClose();
            },
            error: err => {
            },
        });
        _disposables.add(it);
    }

    function getSec({ h, m }) {
        return h * 60 + m;
    }
}

const useStyles = makeStyles({
    root: {
        paddingTop: `${Dimens.marginM}px`,
    },
    titleLayout: {
        alignSelf: 'center',
        alignItems: 'flex-end',
    },
    sliderLayout: {
        marginTop: `${Dimens.marginL}px`,
        marginBottom: `${Dimens.marginM}px`,
    },
    sliderItemLayout: {
        flex: 1,
        alignItems: 'center',
    },
    shiftIcon: {
        width: `${Dimens.arrangeShiftIconSize}px`,
        height: `${Dimens.arrangeShiftIconSize}px`,
    },
    slider: {
        marginTop: `${Dimens.marginM}px`,
        alignSelf: 'center',
    },
    btnDivider: {
        width: '100%',
        height: `${Dimens.divider}px`,
        backgroundColor: Colors.dividerLight,
    },
    btnSave: {
        width: '100%',
        height: `${Dimens.arrangeShiftFooter}px`,
    },
    btnSaveText: {
        color: Colors.accent,
        fontSize: Dimens.textM,
        fontWeight: 'bold',
    },
    animLayout: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    animText: {
        color: Colors.textBLight,
        fontSize: Dimens.textM,
        fontWeight: 'bold',
        marginTop: `${Dimens.marginM}px`,
    }
});

const Title = styled.span`
    color: ${Colors.textBDark};
    font-size: ${Dimens.textL};
    font-weight: bold;
    margin-left: ${Dimens.marginM}px;
`;

const ShiftTitle = styled.span`
    color: ${Colors.textBLight};
    font-size: ${Dimens.textS};
    margin-top: ${Dimens.marginM}px;
`;

const ShiftNumTitle = styled.span`
    color: ${Colors.textBDark};
    font-size: ${Dimens.textXL};
    font-weight: bold;
`;

const ItemTitle = styled.span`
    color: ${Colors.textBNormal};
    font-size: ${Dimens.textM};
    margin-left: ${Dimens.marginS}px;
    margin-right: ${Dimens.marginS}px;
`;