const en = {
    // General
    'login': 'login',
    'password': 'Password',
    'cancel': 'Cancel',
    'confirm': 'Confirm',
    'save': 'Save',
    'add': 'Add',
    'alert': 'Alert',
    'none': 'N/A',
    'male': 'M',
    'female': 'F',
    'room': 'Room',
    'on': 'ON',
    'off': 'OFF',
    'setting': 'Setting',
    // Date Format
    'year': 'year',
    'month': 'month',
    'day': 'day',
    'hour': 'hr',
    'minute': 'min',
    'second': 'sec',
    'format_year': '/',
    'format_month': '/',
    'format_day': ' ',
    'format_hour': ':',
    'format_min': ':',
    'format_sec': '',
    'before_second': 'sec',
    'before_minute': 'min',
    'date_until': ' - ',
    // Arrange Shift
    'arrange_shift_day': 'Day',
    'arrange_shift_noon': 'Noon',
    'arrange_shift_night': 'Night',
    // Pad Event
    'pad_event_empty': 'Unknwon',
    'pad_event_unknwon': 'Empty',
    'pad_event_left_side': 'Left Side',
    'pad_event_right_side': 'Right Side',
    'pad_event_sit': 'Sit',
    'pad_event_on_bed': 'On-Bed',
    // Pad Notification
    'pad_notification_unknown': 'Unknwon',
    'pad_notification_off_bed': 'OFF BED',
    'pad_notification_side': 'BED EDGE',
    'pad_notification_sit': 'SIT',
    'pad_notification_on_bed': 'ON-BED',
    'pad_notification_high_activity': 'High Activity',
    'pad_notification_low_activity': 'Low Activity',
    'pad_notification_roll_over': 'Roll Over',
    'pad_notification_on_bed_over_time': 'Over Time',
    'pad_notification_short_off_bed': 'OFF',
    'pad_notification_short_side' : 'EG',
    'pad_notification_short_sit': 'SIT',
    'pad_notification_sound_unknown': 'unknwon',
    'pad_notification_sound_off_bed': 'off bed',
    'pad_notification_sound_side': 'bed edge',
    'pad_notification_sound_sit': 'sit',
    'pad_notification_sound_on_bed': 'on bed',
    'pad_notification_sound_high_activity': 'high activity',
    'pad_notification_sound_low_activity': 'low activity',
    'pad_notification_sound_roll_over': 'Roll Over',
    'pad_notification_sound_on_bed_over_time': 'over time',
    // Loading
    'loading_login': 'loading...',
    'loading_save': 'saving...',
    'loading_data': 'loading...',
    'loading_add': 'saving...',
    'loading_read': 'loading...',

    // --- Pages ---
    // SignInPage
    'page_signin_title': 'Please login your account',
    'page_signin_message_new_pwd_required': 'Need to update password',
    'page_signin_message_user_not_confirmed': 'Email not authorized',
    'page_signin_message_not_authorized': 'Wrong password',
    'page_signin_message_user_not_found': 'Account not exist',
    'page_signin_message_unknown_error': 'System Error： Error Code {code}',
    // ChangePwdPage
    'page_change_pwd_title': 'First time login, please enter company name and new password',
    'page_change_pwd_company_name': 'Company Name',
    'page_change_pwd_company_name_hint': 'Please enter company name',
    'page_change_pwd_pwd_hint': 'please enter password',
    'page_change_pwd_pwd_again_hint': 'plaese enter password again',
    'page_change_pwd_message_error': 'Not able to update your password, please contact with custom service',
    // HomePage
    'page_home_tooltip_battery': 'Battery Level: {level}',
    'page_home_tooltip_alert_records' : 'Alert Records',
    'page_home_tooltip_activity': '10min Activities',
    'page_home_tooltip_activity_1min': '1 min Activities',
    'page_home_tooltip_route_to_history': 'Look up statistics',
    'page_home_tooltip_route_to_management': 'Look up management',
    'page_home_message_pad_offline': 'Pad Offline / Out of range',
    'page_home_message_gateway_offline': 'Gateway Offline',
    // History Page
    'page_history_chart_posture_title': 'Bed Status Record',
    'page_history_chart_posture_legend_on_bed': 'On-Bed',
    'page_history_chart_posture_legend_off_bed': 'Off-Bed',
    'page_history_chart_posture_legend_offline': 'Offline',
    'page_history_chart_posture_message_none': 'None',
    'page_history_chart_posture_message_on_bed': 'On-Bed',
    'page_history_chart_posture_message_off_bed': 'Off-Bed',
    'page_history_chart_posture_message_offline': 'Offline',
    'page_history_chart_activity_title': 'Activity Record',
    'page_history_chart_activity_legend': 'Activity',
    'page_history_chart_sleep_title': 'Sleep Record',
    'page_history_chart_sleep_status': 'Sleep status',
    'page_history_chart_sleep_legend_none': 'No record',
    'page_history_chart_sleep_legend_awake': 'Awake',
    'page_history_chart_sleep_legend_light': 'Light sleep',
    'page_history_chart_sleep_legend_deep': 'Deep sleep',
    'page_history_chart_hint_format_hour': 'hr',
    'page_history_chart_hint_format_min': 'min',
    'page_history_chart_bed_allday': 'Bed all day',
    'page_history_chart_bed_day': 'Bed at daytime',
    'page_history_chart_bed_night': 'Bed at night',
    'page_history_chart_sleep_allday': 'Sleeping time',
    'page_history_chart_sleep_day': 'Sleep during the day',
    'page_history_chart_sleep_night': 'Sleep at night',
    'page_history_notify_add_bed': 'Please add a WhizPad first',
    // Management Page
    'page_management_time_setting': 'Setting',

    // --- Widget ---
    // AppBar Menu
    'menu_home': 'Home',
    'menu_statistics': 'Statistics',
    'menu_management': 'Management',
    'menu_setting': 'Setting',
    'menu_language': 'Language',
    'menu_logout': 'Logout',
    // Notification Bar
    'notification_bar_title': 'Event Alert',
    'notification_bar_select_voice': 'Choose Voice',
    'notification_bar_tab_realtime': 'Realtime',
    'notification_bar_tab_record': 'Records',
    'notification_bar_tooltip_volume': 'Alert Sound: ON',
    'notification_bar_tooltip_volume_mute': 'Alert Sound: OFF',
    'notification_bar_format_alert': ' Alert',
    // Pad Config Widget
    'pad_config_widget_id_title': 'ID',
    'pad_config_widget_basic_title': 'Basic Setting',
    'pad_config_widget_basic_resident_title': 'Resident',
    'pad_config_widget_stage_alert_title': 'Bed Alert Setting',
    'pad_config_widget_stage_alert_off_bed': 'OFF',
    'pad_config_widget_stage_alert_side': 'EDGE',
    'pad_config_widget_stage_alert_sit': 'SIT',
    'pad_config_widget_activity_alert_title': 'Activity Alert Setting',
    'pad_config_widget_activity_alert_over_time_title': 'Over Time',
    'pad_config_widget_activity_alert_over_time_setting': 'Time Setting',
    'pad_config_widget_activity_alert_activity_title': 'Activity Alert',

    'pad_config_widget_activity_alert_activity_lower_title': 'Low Activity',
    'pad_config_widget_activity_alert_activity_low_threshold_setting': 'Threshold',
    'pad_config_widget_activity_alert_activity_low_time_setting': 'Duration',

    'pad_config_widget_activity_alert_activity_higher_title': 'Restlessness',
    'pad_config_widget_activity_alert_activity_high_setting': 'Activity Over',

    'pad_config_widget_activity_alert_activity_roll_over_title': 'Roll Over',
    'pad_config_widget_activity_alert_activity_roll_over_threshold_setting': 'Threshold',
    'pad_config_widget_activity_alert_activity_roll_over_time_setting': 'Duration',
    
    'pad_config_widget_activity_alert_range_title': 'Alert Time Setting',
    'pad_config_widget_activity_alert_range_selector_all': 'All',
    'pad_config_widget_activity_alert_range_selector_range': 'Range',
    'pad_config_widget_activity_alert_range_message_all': 'All Day Alert',
    'pad_config_widget_other_title': 'Other Settings',
    'pad_config_widget_other_mi_temp_title': 'Temperature & Humidity Monitor',

    // --- Dialog ---
    // Arrange Shift Dialog
    'dialog_arrange_shift_title': 'Arrange Shift',
    // Languages Dialog
    'dialog_lang_title': 'Select Language',
    // Notifications History Dialog
    'dialog_notifications_history_title': 'Alert Records',
    // Add Pad Dialog
    'dialog_add_pad_title': 'Add New Pad',
    'dialog_add_pad_id_title': 'ID',
    'dialog_add_pad_message_id_invalid': 'ID format invalid',
    'dialog_add_pad_message_id_exist': 'ID already added',
    // Reorder Pad Dialog
    'dialog_reorder_title':'Reorder',
    // voice language dialog
    'dialog_voice_lang_title': 'Choose Voice',
}

export default en