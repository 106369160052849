import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles'
import intl from 'react-intl-universal'

import * as Rx from 'rxjs';
import ChangeSeqBloc from '../../../bloc/main/management/change_pad_seq_dialog_bloc'

import * as Dimens from '../../../styles/dimens';
import * as Colors from '../../../styles/colors';
import Column from '../../../components/layout/column';
import Row from '../../../components/layout/row';
import Stack from '../../../components/layout/stack';
import BtnRound from '../../../components/widgets/btn_round';
import BlocBuilder from 'bloc-builder-react';
import { Dialog, ButtonBase } from '@material-ui/core';
import IcSave from '@material-ui/icons/SaveRounded';
import Lottie from 'react-lottie';
import AnimLoading from '../../../assets/lottie/textfield_loading.json';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

ChangePadSeqDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
    onSave: PropTypes.func,
}

const _bloc = new ChangeSeqBloc();
let _disposables = new Rx.Subscription();

const UserDragItem = ({ item }) => {
    const { deviceId, name, index } = item;
    return (
        <Draggable draggableId={deviceId} index={index}>
            {(provided, snapshot) => {
                const style = {
                    backgroundColor: snapshot.isDragging ? 'grey' : 'white',
                    color: snapshot.isDragging ? 'white' : 'black',
                    // fontSize: 18,
                    ...provided.draggableProps.style,
                };
                return (
                    <DragItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={style}
                    >
                        <span>{name}</span>
                        {/* <span>{index}</span> */}
                    </DragItem>
                )
            }}
        </Draggable>
    )
}


function ChangePadSeqDialog(props) {
    const classes = useStyles(props);

    const [init, setInit] = useState(false);

    useEffect(() => {
        if (props.open) {
            console.log(`ChangeSeqDialog init`);
            _disposables = new Rx.Subscription();
            _bloc.init();
            _isDataUpdated();
            setInit(true);
        } else if (init) {
            console.log(`ChangeSeqDialog deinit`);
            _bloc.dispose();
            _disposables.unsubscribe();
            setInit(false);
        }
        return () => {
        }
    }, [props.open])

    const onDragEnd = (result) => {
        console.log('onDragEnd', result)
        if (!result.destination) {
            return;
        }
        if (result.destination.index === result.source.index) {
            return;
        }
        //reorder
        const items = _bloc.padList.getValue();
        const startIndex = result.source.index;
        const endIndex = result.destination.index;
        const [removed] = items.splice(startIndex, 1);
        items.splice(endIndex, 0, removed);
        items.map((item, index) => {
            items[index] = {
                ...item,
                'index': index
            }
        })
        console.log('result', items)
        // _bloc.padList.next(items);
    }

    const onSaveClick = () => {
        _bloc.savePadList();
    }

    const _isDataUpdated = () => {
        let it = _bloc.isSaved
            .subscribe({
                next: state => {
                    if (state) {
                        if (props.onSave !== undefined) {
                            props.onSave();
                        }
                        props.onClose();
                    }
                }
            });
        _disposables.add(it);
    }

    return (() => {
        if (!init) return null;
        return (
            <Dialog onClose={props.onClose} open={props.open} >
                <Stack width='auto' height='auto' className={classes.root}>

                    <Row width='250px' height='auto' className={classes.titleLayout}>
                        <Title>{intl.get('dialog_reorder_title')}</Title>
                        <BlocBuilder
                            initialValue={_bloc._padListSaving.getValue()}
                            subject={_bloc._padListSaving}
                            builder={(snapshot) => {
                                const isLoading = snapshot.data;
                                return (
                                    isLoading ?
                                        (
                                            <Lottie
                                                isClickToPauseDisabled
                                                options={{
                                                    loop: true,
                                                    autoplay: true,
                                                    animationData: AnimLoading,
                                                    rendererSettings: {
                                                        preserveAspectRatio: 'xMidYMid slice'
                                                    }
                                                }}
                                                width={`${Dimens.padConfigHeaderBarAddIcon}px`}
                                                height={`${Dimens.padConfigHeaderBarAddIcon}px`}
                                                style={{ marginRight: 0, padding: '10px' }} />
                                        )
                                        :
                                        (<ButtonBase width='auto' btncolor={Colors.primary} onClick={onSaveClick}>
                                            <IcSave className={classes.headerBarIcon} />
                                        </ButtonBase>)
                                )
                            }}
                        />
                    </Row>
                    <Column width='100%' height='auto' className={classes.dragDropContainer}>

                        <DragDropContext onDragEnd={onDragEnd} style={{ height: '200px' }}>
                            <Droppable droppableId="padList">
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef} {...provided.droppableProps}
                                        style={{ width: '100%', backgroundColor: '#f0f0f0' }}>
                                        <BlocBuilder
                                            initialValue={_bloc.padList.getValue()}
                                            subject={_bloc.padList}
                                            builder={(snapshot) => {
                                                // console.log('dialog', snapshot.data);
                                                const padList = snapshot.data;
                                                return (
                                                    padList.map((item) => {
                                                        return (
                                                            // <div>{item.name}</div>
                                                            <UserDragItem item={item} key={item.deviceId} />
                                                        )
                                                    })
                                                )

                                            }}
                                        />
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        {/* 頁面讀取中 */}
                        <BlocBuilder
                            initialValue={_bloc.padListDownloading.getValue()}
                            subject={_bloc.padListDownloading}
                            builder={(snapshot) => {
                                if (!snapshot.data) return (
                                    // <Column width='100%' height='auto'>
                                    //     <div className={classes.btnDivider} />
                                    //     <ButtonBase className={classes.btnSave}
                                    //         onClick={onSaveClick}>
                                    //         <span className={classes.btnSaveText}>{intl.get('save')}</span>
                                    //     </ButtonBase>
                                    // </Column>
                                    null
                                );
                                return (
                                    <Column width='100%' height='100%' className={classes.loadingLayout}>
                                        <Lottie
                                            isClickToPauseDisabled
                                            options={{
                                                loop: true,
                                                autoplay: true,
                                                animationData: AnimLoading,
                                                rendererSettings: {
                                                    preserveAspectRatio: 'xMidYMid slice'
                                                }
                                            }}
                                            width={`${Dimens.padConfigLoading}px`}
                                            height='200px' />
                                        <span className={classes.loadingText}>{intl.get('loading_data')}</span>
                                    </Column>
                                );
                            }} />

                    </Column>
                </Stack>
            </Dialog>
        );
    })();
}

const useStyles = makeStyles({
    root: {
        width: '250px',
        maxHeight: '700px',
    },
    titleLayout: {
        position: 'fixed',
        zIndex: 2,
        borderRadius: '3px 3px 0px 0px',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: Colors.primary,
    },
    dragDropContainer: {
        alignItems: 'center',
        marginTop: '20px',
        backgroundColor: '#f0f0f0',
        paddingTop: '30px'
    },
    loadingLayout: {
        padding: '10px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    // btnDivider: {
    //     width: '100%',
    //     height: `${Dimens.divider}px`,
    //     backgroundColor: Colors.dividerDark,
    // },
    // btnSave: {
    //     width: '100%',
    //     height: `${Dimens.arrangeShiftFooter}px`,
    // },
    // btnSaveText: {
    //     color: Colors.accent,
    //     fontSize: Dimens.textM,
    //     fontWeight: 'bold',
    // },
    headerBarIcon: {
        width: `${Dimens.padConfigHeaderBarAddIcon}px`,
        height: `${Dimens.padConfigHeaderBarAddIcon}px`,
        color: Colors.iconDefaultW,
        padding: '10px',
        // margin: '10px',
    },
});

const Title = styled.span`
    color: ${Colors.textWDark};
    font-size: ${Dimens.textL};
    padding: 8px;
`;

const DragItem = styled.div`
    border-radius: 10px;
    margin: 0px 20px 10px 20px;
    background-color: white;
    padding: 10px;
    display: flex;
    justify-content: space-around;
`;

export default ChangePadSeqDialog
