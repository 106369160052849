import intl from 'react-intl-universal'
import * as Colors from '../../styles/colors';

class PadNotification {

    get type() { return this._type; }
    get code() { return this._code; }
    get nameId() { return this._nameId; }
    get name() { return intl.get(this._nameId) }
    get soundId() { return this._soundId }
    get sound() { return intl.get(this._soundId) }
    get color() { return this._color; }

    constructor(type, code, nameId, soundId, color) {
        this._type = type;
        this._code = code;
        this._nameId = nameId;
        this._soundId = soundId;
        this._color = color;
    }
}

PadNotification.None = new PadNotification('stageAlert', -1, 'pad_notification_unknown', 'pad_notification_sound_unknown', Colors.padEventBlue);
PadNotification.Leave = new PadNotification('stageAlert', 0, 'pad_notification_off_bed', 'pad_notification_sound_off_bed', Colors.padEventBlue);
PadNotification.SitSide = new PadNotification('stageAlert', 1, 'pad_notification_side', 'pad_notification_sound_side', Colors.padEventRed);
PadNotification.Sit = new PadNotification('stageAlert', 2, 'pad_notification_sit', 'pad_notification_sound_sit', Colors.padEventGreen);
PadNotification.Lay = new PadNotification('stageAlert', 3, 'pad_notification_on_bed', 'pad_notification_sound_on_bed', Colors.padEventGreen);

PadNotification.ActivityLower = new PadNotification('activityAlert', 0, 'pad_notification_low_activity', 'pad_notification_sound_low_activity', Colors.padEventGreen);
PadNotification.ActivityOver = new PadNotification('activityAlert', 1, 'pad_notification_high_activity', 'pad_notification_sound_high_activity', Colors.padEventGreen);

PadNotification.ActivityRollOver = new PadNotification('rollOverAlert', 0, 'pad_notification_roll_over', 'pad_notification_sound_roll_over', Colors.padEventGreen);

PadNotification.LieOver = new PadNotification('lieAlert', 0, 'pad_notification_on_bed_over_time', 'pad_notification_sound_on_bed_over_time', Colors.padEventGreen);

Object.freeze(PadNotification);

class NotificationData {
    constructor(deviceId, name, type, notiCode, epoch, localEpoch) {
        this._deviceId = deviceId;
        this._name = name;
        this._type = type;
        this._notiCode = notiCode;
        this._epoch = epoch;
        this._localEpoch = localEpoch
    }

    get deviceId() { return this._deviceId; }

    get name() { return this._name; }

    get type() { return this._type; }

    get notiCode() { return this._notiCode; }

    get notification() {
        switch (this._type) {
            case 'stageAlert':
                switch (this._notiCode) {
                    case 0: return PadNotification.Leave;
                    case 1: return PadNotification.SitSide;
                    case 2: return PadNotification.Sit;
                    case 3: return PadNotification.Lay;
                    default: return PadNotification.None;
                }
            case 'activityAlert':
                switch (this._notiCode) {
                    case 0: return PadNotification.ActivityLower;
                    case 1: return PadNotification.ActivityOver;
                    default: return PadNotification.None;
                }
            case 'rollOverAlert': 
                switch (this._notiCode) {
                    case 0: return PadNotification.ActivityRollOver;
                    default: return PadNotification.None;
                }
            case 'lieAlert':
                switch (this._notiCode) {
                    case 0: return PadNotification.LieOver;
                    default: return PadNotification.None;
                }
            default:
                return PadNotification.None
        }
    }

    get epoch() { return this._epoch; }

    get epochSecond() { return Math.floor(this._epoch / 1000); }

    get localEpoch() { return this._localEpoch }

    get localEpochSec() { return Math.floor(this._localEpoch / 1000) }
}

export { PadNotification, NotificationData };