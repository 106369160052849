import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useCookies } from 'react-cookie'
import intl from 'react-intl-universal'
import { Auth } from 'aws-amplify';

import * as Dimens from '../../styles/dimens';
import * as Colors from '../../styles/colors';
// import * as Strings from '../../styles/strings';
import Stack from '../layout/stack';
import Row from '../layout/row';
import Column from '../layout/column';
import ButtonMenu from '../widgets/btn_menu';
import { useMediaQuery, AppBar as MaterialAppBar, Drawer, ButtonBase, Menu } from '@material-ui/core';


import IcHome from '@material-ui/icons/HomeRounded';
import IcSetting from '@material-ui/icons/SettingsRounded'
import IcManagement from '@material-ui/icons/FormatListBulletedRounded'
import IcHistory from '@material-ui/icons/InsertChartRounded'
import IcMenu from '@material-ui/icons/MenuRounded'
import IcLogout from '@material-ui/icons/ExitToAppRounded'
import IcLanguage from '@material-ui/icons/LanguageRounded'

let ImgWhizPad = undefined

const AppBarIds = {
    BtnLogout: 0,
    BtnManagement: 1,
    BtnHistory: 2,
    BtnHome: 3,
    BtnMenu: 4,
    BtnLanguage: 5,
}

function AppBar(props) {
    const isWeb = useMediaQuery('(min-width: 768px)')
    const [cookie, setCookie] = useCookies(['lang'])
    const [init, setInit] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        async function getUserId() {
            if (process.env.REACT_APP_LOCAL === "true") {
                import(`../../assets/${process.env.REACT_APP_LOGO_PATH}`).then((mod) => {
                    console.log(mod)
                    ImgWhizPad = mod
                    setInit(true)
                });
                return;
            }
            const session = await Auth.currentSession();
            const userId = session.getIdToken().decodePayload().sub;
            // 星安科技LOGO
            if (userId === '9f2261c3-8c8b-4a89-bd46-683331e92f88') {
                import(`../../assets/img_astec.svg`).then((mod) => {
                    console.log(mod)
                    ImgWhizPad = mod
                    setInit(true)
                });
            } else {
                import(`../../assets/${process.env.REACT_APP_LOGO_PATH}`).then((mod) => {
                    console.log(mod)
                    ImgWhizPad = mod
                    setInit(true)
                });
            }
        }
        getUserId();
    }, [])

    const [isDrawerOpened, setDrawerOpen] = useState(false);
    const Items = [
        { id: AppBarIds.BtnHome, textId: 'menu_home', icon: IcHome },
        { id: AppBarIds.BtnHistory, textId: 'menu_statistics', icon: IcHistory },
        { id: AppBarIds.BtnManagement, textId: 'menu_management', icon: IcManagement },
        { id: AppBarIds.BtnSetting, textId: 'menu_setting', icon: IcSetting }
    ]
    const SettingItems = process.env.REACT_APP_LOCAL === "true" ?
        [
            { id: AppBarIds.BtnLanguage, textId: 'menu_language', icon: IcLanguage }
        ] :
        [
            { id: AppBarIds.BtnLanguage, textId: 'menu_language', icon: IcLanguage },
            { id: AppBarIds.BtnLogout, textId: 'menu_logout', icon: IcLogout }
        ]

    const MobileMenuItems = process.env.REACT_APP_LOCAL === "true" ?
        [
            { id: AppBarIds.BtnHistory, textId: 'menu_statistics', icon: IcHistory },
            { id: AppBarIds.BtnManagement, textId: 'menu_management', icon: IcManagement },
            { id: AppBarIds.BtnLanguage, textId: 'menu_language', icon: IcLanguage },
        ] :
        [
            { id: AppBarIds.BtnHistory, textId: 'menu_statistics', icon: IcHistory },
            { id: AppBarIds.BtnManagement, textId: 'menu_management', icon: IcManagement },
            { id: AppBarIds.BtnLanguage, textId: 'menu_language', icon: IcLanguage },
            { id: AppBarIds.BtnLogout, textId: 'menu_logout', icon: IcLogout }
        ]

    const onClicked = (id) => (event) => {
        console.log(`appbar clicked id: ${id}`);
        switch (id) {
            case AppBarIds.BtnMenu:
                setDrawerOpen(true);
                return;
            case AppBarIds.BtnSetting:
                setAnchorEl(event.currentTarget);
                return;
            default:
                break;
        }
        setAnchorEl(null)
        setDrawerOpen(false);
        props.onclick(id);
    }

    const toggleDrawer = (open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    }

    return (() => {
        console.log(`AppBar cookie (lang): `, cookie)
        if (!init) return null
        console.log(`IMG WHIZPAD: ${ImgWhizPad.default}`)
        return (
            <Fragment>
                {/* Drawer */}
                <AutoSizer>
                    {({ width, height }) => {
                        const drawerWidth = `${width * 0.6}px`;
                        const itemIconSizeStr = `${Dimens.drawerItemSize * 0.5}px`;
                        return (
                            <Drawer anchor="right"
                                open={isDrawerOpened} onClose={toggleDrawer(false)}
                                onTouchMove={(event) => { event.preventDefault(); }} >
                                <Column width={drawerWidth} height='auto' >
                                    {
                                        MobileMenuItems.map((item, index) => {
                                            const Icon = item.icon;
                                            return (
                                                <BtnItem key={`drawer_${item.id}`} onClick={onClicked(item.id)} >
                                                    <Row width='100%' height='100%' >
                                                        <Icon style={{ alignSelf: 'center', color: Colors.iconDefault, height: itemIconSizeStr, width: 'auto', marginLeft: '10%' }} />
                                                        <span style={{ alignSelf: 'center', flex: '1', fontSize: Dimens.textM }}>{intl.get(item.textId)}</span>
                                                    </Row>
                                                </BtnItem>
                                            );
                                        })
                                    }
                                </Column>
                            </Drawer>
                        );
                    }}
                </AutoSizer>
                <MaterialAppBar position='fixed'>
                    <RootLayout>
                        <HomeImg src={ImgWhizPad.default} onClick={onClicked(AppBarIds.BtnHome)} />
                        <AutoSizer>
                            {({ width, height }) => {
                                if (width < 768) {
                                    // 手機畫面
                                    return (
                                        <BtnsLayout >
                                            <IcMenu
                                                onClick={onClicked(AppBarIds.BtnMenu)}
                                                style={{ alignSelf: 'center', color: '#fff', marginRight: `${Dimens.marginM}px`, cursor: 'pointer' }} />
                                        </BtnsLayout>
                                    );
                                } else {
                                    // 網頁畫面
                                    return (
                                        <BtnsLayout >
                                            {
                                                Items.map((item, index) => {
                                                    return (
                                                        <ButtonMenu key={`btns_${item.id}`} icon={item.icon} text={intl.get(item.textId)}
                                                            width='auto' height={`${Dimens.appbarBtnHeight}px`}
                                                            onclick={onClicked(item.id)}
                                                            style={{ alignSelf: 'center', marginRight: `${Dimens.marginL}px` }} />
                                                    );
                                                })
                                            }
                                        </BtnsLayout>
                                    );
                                }
                            }}
                        </AutoSizer>
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={() => { setAnchorEl(null) }} >
                            <Column width='auto' height='auto'>
                                {
                                    SettingItems.map((item, index) => {
                                        const Icon = item.icon;
                                        return (
                                            <BtnItem key={item.id} onClick={onClicked(item.id)} style={{ width: `${Dimens.appbarMenuItem}px`, height: `${Dimens.drawerItemSize}px` }} >
                                                <Row width='100%' height='100%' >
                                                    <Icon style={{ alignSelf: 'center', color: Colors.iconDefault, height: `${Dimens.drawerItemSize * 0.5}px`, width: 'auto', marginLeft: '10%' }} />
                                                    <span style={{ alignSelf: 'center', flex: '1', fontSize: Dimens.textM, textAlign: 'end', marginRight: '10%' }}>{intl.get(item.textId)}</span>
                                                </Row>
                                            </BtnItem>
                                        );
                                    })
                                }
                            </Column>
                        </Menu>
                    </RootLayout>
                </MaterialAppBar>
            </Fragment>
        );
    })()
}

AppBar.propTypes = {
    onclick: PropTypes.func,
}

const RootLayout = styled(Stack).attrs(props => ({
    width: '100%',
    height: 'auto',
    backgroundColor: Colors.primary,
}))`
    max-width: 100%;
    height: ${Dimens.appbarSize}px;
    @media(min-width: 768px) {
        height: ${Dimens.appbarSizeL}px;
    }
`;

const HomeImg = styled.img`
    width: auto;
    height: 60%;
    position: absolute;
    top: 20%;
    left: 3%;
    cursor: pointer;
`;

const BtnsLayout = styled(Row).attrs(props => ({
    width: 'auto',
    height: '100%',
}))`
    position: absolute;
    right: 0px;
`;

const BtnItem = styled(ButtonBase)`
    width: 100%;
    height: ${Dimens.drawerItemSize}px;
`;

export { AppBar, AppBarIds };