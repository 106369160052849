
class ArrangeShiftType {

    get code() { return this._code; }

    constructor(code) {
        this._code = code;
    }
}

ArrangeShiftType.Day = new ArrangeShiftType(0);
ArrangeShiftType.Noon = new ArrangeShiftType(1);
ArrangeShiftType.Night = new ArrangeShiftType(2);

Object.freeze(ArrangeShiftType);

class ArrangeShift {

    get dayRange() {
        const start = this._day;
        let end = this._noon;
        end = end < 0 ? 1440 + end : end;
        return {
            start: { h: parseInt(start / 60) % 24, m: start % 60 },
            end: { h: parseInt(end / 60) % 24, m: end % 60 },
        };
    }

    get noonRange() {
        const start = this._noon;
        let end = this._night;
        end = end < 0 ? 1440 + end : end;
        return {
            start: { h: parseInt(start / 60) % 24, m: start % 60 },
            end: { h: parseInt(end / 60) % 24, m: end % 60 },
        };
    }

    get nightRange() {
        const start = this._night;
        let end = this._day;
        end = end < 0 ? 1440 + end : end;
        return {
            start: { h: parseInt(start / 60) % 24, m: start % 60 },
            end: { h: parseInt(end / 60) % 24, m: end % 60 },
        };
    }

    get range() {
        return {
            day: this.dayRange,
            noon: this.noonRange,
            night: this.nightRange,
        };
    }

    type(min) {
        if (this._isInRange(this._day, this._noon - 1, min)) return ArrangeShiftType.Day;
        if (this._isInRange(this._noon, this._night - 1, min)) return ArrangeShiftType.Noon;
        return ArrangeShiftType.Night;
    }

    nowType() {
        const date = new Date();
        const min = date.getHours() * 60 + date.getMinutes();
        if (this._isInRange(this._day, this._noon - 1, min)) return ArrangeShiftType.Day;
        if (this._isInRange(this._noon, this._night - 1, min)) return ArrangeShiftType.Noon;
        return ArrangeShiftType.Night;
    }

    _isInRange(start, end, min) {
        let len = end - start;
        len = len < 0 ? len + 1440 : len;
        let diff = min - start;
        diff = diff < 0 ? diff + 1440 : diff;
        return diff <= len;
    }

    constructor(day, noon, night) {
        this._day = day;
        this._noon = noon;
        this._night = night;
    }

    toJson() {
        return {
            'day': this._day,
            'noon': this._noon,
            'night': this._night,
        }
    }
}

export { ArrangeShiftType, ArrangeShift };