import * as Rx from 'rxjs';
import { skip, debounceTime, tap, switchMap } from 'rxjs/operators';
import intl from 'react-intl-universal'

import * as LoginApi from '../../model/api/login_api';

const SignInText = {
    loginLoadingText: intl.get('loading_login'),
};

export default class SignInBloc {

    get loggedIn() { return this._loggedIn }

    get accountText() { return this._accountText; }

    get pwdText() { return this._pwdText; }

    get accountAnim() { return this._accountAnim; }

    get pwdAnim() { return this._pwdAnim; }

    get loginAvailable() { return this._loginAvailable; }

    get errorText() { return this._errorText; }

    get signInResult() { return this._signInResult; }

    get loadingDialog() { return this._loadingDialog; }

    init() {
        this._disposables = new Rx.Subscription();
        this._loggedIn = new Rx.Subject()

        this._accountText = new Rx.BehaviorSubject("");
        this._pwdText = new Rx.BehaviorSubject("");
        this._accountAnim = new Rx.BehaviorSubject('none');
        this._pwdAnim = new Rx.BehaviorSubject('none');
        this._loginAvailable = new Rx.BehaviorSubject(false);
        this._errorText = new Rx.BehaviorSubject('');
        this._signInResult = new Rx.Subject();

        this._loadingDialog = new Rx.Subject();

        this.setLoginAvailableObserver();
    }

    dispose() {
        this._disposables.unsubscribe();
    }

    isLoggedIn() {
        let it = LoginApi.isLoggedIn()
            .subscribe({
                next: loggedIn => {
                    this._loggedIn.next(loggedIn)
                },
                error: err => {
                    this._loggedIn.next(false)
                },
                complete: () => {
                }
            })
        this._disposables.add(it)
    }

    signIn() {
        let it = LoginApi.signIn(this._accountText.getValue(), this._pwdText.getValue())
            .subscribe({
                next: ({ user, result, code }) => {
                    this._signInResult.next({ result: result, code: code });
                    let message = '';
                    switch (code) {
                        case 'NEW_PASSWORD_REQUIRED':
                            message = intl.get('page_signin_message_new_pwd_required');
                            break;
                        case 'UserNotConfirmedException':
                            message = intl.get('page_signin_message_user_not_confirmed')
                            break;
                        case 'PasswordResetRequiredException':
                            message = intl.get('page_signin_message_new_pwd_required');
                            break;
                        case 'NotAuthorizedException':
                            message = intl.get('page_signin_message_not_authorized');
                            break;
                        case 'UserNotFoundException':
                            message = intl.get('page_signin_message_user_not_found');
                            break;
                        default:
                            message = intl.get('page_signin_message_unknown_error', { code: `${code}` });
                            break;
                    }
                    this._errorText.next(message);
                },
                error: err => {
                    this._loadingDialog.next({ show: false, text: SignInText.loginLoadingText });
                },
                complete: () => {
                    this._loadingDialog.next({ show: false, text: SignInText.loginLoadingText });
                },
            })
        this._disposables.add(it);
        this._loadingDialog.next({ show: true, text: SignInText.loginLoadingText });
    }

    setLoginAvailableObserver() {
        let it = Rx.combineLatest(this.getAccountObservable(), this.getPwdObservable())
            .subscribe({
                next: ([accountAvailable, pwdAvailable]) => {
                    console.log(`is available account: ${accountAvailable}, pwd: ${pwdAvailable}`);
                    this._loginAvailable.next(accountAvailable && pwdAvailable);
                },
                error: (err) => {
                    this._loginAvailable.next(false);
                }
            });
        this._disposables.add(it);
    }

    getAccountObservable() {
        return this._accountText.pipe(
            skip(1),
            debounceTime(1000),
            tap(email => console.log(`account text: ${email}`)),
            tap(email => this._accountAnim.next('loading')),
            switchMap(email => email.length === 0 ? Rx.of(false) : LoginApi.checkAccountExist(email)),
            tap(exist => {
                console.log(`email check result: ${exist}`);
                this._accountAnim.next(exist ? 'success' : 'failure');
            })
        );
    }

    getPwdObservable() {
        return this._pwdText.pipe(
            skip(1),
            debounceTime(1000),
            tap(pwd => this._pwdAnim.next('loading')),
            switchMap(pwd => LoginApi.checkPwd(pwd)),
            tap(available => this._pwdAnim.next(available ? 'success' : 'failure'))
        );
    }
}