import * as LocalServer from "./local/settings_api"
import * as AWSServer from './aws/settings_api'

var Lodash = require('lodash');

export function getPadList() {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.getPadList() : AWSServer.getPadList();
}

export function setPadListOrder(list) {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.setPadListOrder(list) : AWSServer.setPadListOrder(list);
}

export function getArrangeShift() {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.getArrangeShift() : AWSServer.getArrangeShift();
}

export function putArrangeShift(config) {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.putArrangeShift(config) : AWSServer.putArrangeShift(config);
}

export function getConfigs() {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.getConfigs() : AWSServer.getConfigs();
}

export function getConfig(deviceId) {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.getConfig(deviceId) : AWSServer.getConfig(deviceId);
}

export function updateConfigs(configs) {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.updateConfigs(configs) : AWSServer.updateConfigs(configs);
}

export function addConfig(config) {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.addConfig(config) : AWSServer.addConfig(config);
}

export function removeConfig(deviceId) {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.removeConfig(deviceId) : AWSServer.removeConfig(deviceId);
}

export function removeConfigs(deviceIds) {
    return process.env.REACT_APP_LOCAL === "true" ? LocalServer.removeConfigs(deviceIds) : AWSServer.getArrangeShift(deviceIds);
}