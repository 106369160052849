import intl from 'react-intl-universal'
import * as Colors from '../../styles/colors';

class PadEvent {

    get code() { return this._code; }
    get name() { return this._name; }
    get color() { return this._color; }

    constructor(code, name, color) {
        this._code = code;
        this._name = name;
        this._color = color;
    }
}

PadEvent.None = new PadEvent(-1, intl.get('pad_event_unknown'), Colors.padEventBlue);
PadEvent.Empty = new PadEvent(0, intl.get('pad_event_empty'), Colors.padEventBlue);
PadEvent.SitSideLeft = new PadEvent(1, intl.get('pad_event_left_side'), Colors.padEventRed);
PadEvent.SitSideRight = new PadEvent(2, intl.get('pad_event_right_side'), Colors.padEventRed);
PadEvent.Sit = new PadEvent(3, intl.get('pad_event_sit'), Colors.padEventGreen);
PadEvent.Lay = new PadEvent(4, intl.get('pad_event_on_bed'), Colors.padEventGreen);

Object.freeze(PadEvent);

export default PadEvent;