import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import PanResponder from 'react-panresponder-web';
import range from 'lodash.range';
import * as Utils from './utils';

TimeCircularSlider.propTypes = {
    initTime: PropTypes.any,
    onUpdate: PropTypes.func.isRequired,
    segments: PropTypes.number,
    strokeWidth: PropTypes.number,
    radius: PropTypes.number,
    gradientColorFrom: PropTypes.string,
    gradientColorTo: PropTypes.string,
    trackColor: PropTypes.string,
    stopIcon: PropTypes.element,
    startIcon: PropTypes.element,
}

TimeCircularSlider.defaultProps = {
    initTime: {
        start: {
            h: 0, m: 0,
        },
        end: {
            h: 0, m: 0,
        }
    },
    segments: 5,
    strokeWidth: 40,
    radius: 145,
    gradientColorFrom: '#ff9800',
    gradientColorTo: '#ffcf00',
    trackColor: '#9d9d9d',
    startIcon: Utils.getDefaultStartIcon(),
    stopIcon: Utils.getDefaultStopIcon(),
}

export default function TimeCircularSlider(props) {
    const _circle = useRef();

    const [circleCenter, setCircleCenter] = useState({
        x: getContainerSize() / 2,
        y: getContainerSize() / 2,
    });

    const [angle, setAngle] = useState({
        start: Utils.calculateStartFromTime(props.initTime.start),
        len: Utils.calculateLenFromTime(props.initTime),
    })

    useEffect(() => {
        setAngle({
            start: Utils.calculateStartFromTime(props.initTime.start),
            len: Utils.calculateLenFromTime(props.initTime),
        });
    }, [props.initTime]);

    /* --- Functions --- */

    const _startPanResponder = PanResponder.create({
        onMoveShouldSetPanResponder: (evt, gestureState) => true,
        onStartShouldSetResponderCapture: (evt, gestureState) => true,
        onPanResponderGrant: (evt, gestureState) => { },
        onPanResponderMove: (evt, { moveX, moveY }) => {
            const x = moveX - _circle.current.getBoundingClientRect().left;
            const y = moveY - _circle.current.getBoundingClientRect().top;
            const { circleCenterX, circleCenterY } = { circleCenterX: circleCenter.x, circleCenterY: circleCenter.y };
            const { onUpdate } = props;

            const currentAngleStop = (angle.start + angle.len) % (2 * Math.PI);

            let newAngle = Math.atan2(y - circleCenterY, x - circleCenterX) + Math.PI / 2;
            if (newAngle < 0) {
                newAngle += 2 * Math.PI;
            }

            let newAngleLength = currentAngleStop - newAngle;
            if (newAngleLength < 0) {
                newAngleLength += 2 * Math.PI;
            }

            // console.log(`sleep pan responder => start angle: ${newAngle}, angle len: ${newAngleLength % (2 * Math.PI)}`);
            setAngle({
                start: Utils.roundAngleToFives(newAngle),
                len: Utils.roundAngleToFives(newAngleLength % (2 * Math.PI)),
            });
            onUpdate({
                start: Utils.calculateTimeFromAngle(angle.start),
                end: Utils.calculateTimeFromAngle(angle.start + angle.len),
            });
        },
    });

    const _stopPanResponder = PanResponder.create({
        onMoveShouldSetPanResponder: (evt, gestureState) => true,
        onMoveShouldSetPanResponderCapture: (evt, gestureState) => true,
        onPanResponderGrant: (evt, gestureState) => { },
        onPanResponderMove: (evt, { moveX, moveY }) => {
            const x = moveX - _circle.current.getBoundingClientRect().left;
            const y = moveY - _circle.current.getBoundingClientRect().top;
            const { circleCenterX, circleCenterY } = { circleCenterX: circleCenter.x, circleCenterY: circleCenter.y };
            const { onUpdate } = props;

            let newAngle = Math.atan2(y - circleCenterY, x - circleCenterX) + Math.PI / 2;
            let newAngleLength = (newAngle - angle.start) % (2 * Math.PI);
            if (newAngleLength < 0) {
                newAngleLength += 2 * Math.PI;
            }

            // console.log(`sleep pan responder => start angle: ${newAngle}, angle length: ${newAngleLength}`);
            setAngle({
                start: Utils.roundAngleToFives(angle.start),
                len: Utils.roundAngleToFives(newAngleLength),
            });
            onUpdate({
                start: Utils.calculateTimeFromAngle(angle.start),
                end: Utils.calculateTimeFromAngle(angle.start + angle.len),
            });
        },
    });

    function getContainerSize() {
        const { strokeWidth, radius } = props;
        return strokeWidth + radius * 2 + 2;
    }

    /* --- Render --- */

    return (() => {
        const id = Utils.makeId(5);
        const { segments, strokeWidth, radius, gradientColorFrom, gradientColorTo, trackColor, startIcon, stopIcon } = props;

        const containerSize = getContainerSize();

        const start = Utils.calculateArcCircle(0, segments, radius, angle.start, angle.len);
        const stop = Utils.calculateArcCircle(segments - 1, segments, radius, angle.start, angle.len);

        const iconScale = strokeWidth / 30;

        return (
            <div style={{ width: containerSize, height: containerSize }} className={props.className}>
                {/* --- progress --- */}
                <svg
                    height={containerSize}
                    width={containerSize}
                    ref={circle => _circle.current = circle} >
                    <defs>
                        {
                            range(segments).map(i => {
                                const { fromX, fromY, toX, toY } = Utils.calculateArcCircle(i, segments, radius, angle.start, angle.len);
                                const { fromColor, toColor } = Utils.calculateArcColor(i, segments, gradientColorFrom, gradientColorTo);

                                return (
                                    <linearGradient
                                        key={i}
                                        id={Utils.getGradientId(id, i)}
                                        x1={fromX.toFixed(2)} y1={fromY.toFixed(2)}
                                        x2={toX.toFixed(2)} y2={toY.toFixed(2)} >
                                        <stop offset='0%' stopColor={fromColor} />
                                        <stop offset='1' stopColor={toColor} />
                                    </linearGradient>
                                );
                            })
                        }
                    </defs>
                    {/* --- circle --- */}
                    <g transform={`translate(${strokeWidth / 2 + radius + 1}, ${strokeWidth / 2 + radius + 1})`}>
                        <circle
                            r={radius}
                            strokeWidth={strokeWidth}
                            fill='transparent'
                            stroke={trackColor} />
                        {
                            range(segments).map(i => {
                                const { fromX, fromY, toX, toY } = Utils.calculateArcCircle(i, segments, radius, angle.start, angle.len);
                                const d = `M ${fromX.toFixed(2)} ${fromY.toFixed(2)} A ${radius} ${radius} 0 0 1 ${toX.toFixed(2)} ${toY.toFixed(2)}`;

                                return (
                                    <path
                                        d={d}
                                        key={i}
                                        strokeWidth={strokeWidth}
                                        stroke={`url(#${Utils.getGradientId(id, i)})`}
                                        fill='transparent' />
                                );
                            })
                        }
                        {/* --- stop icon --- */}
                        <g
                            fill={gradientColorTo}
                            transform={`translate(${stop.toX},${stop.toY})`}
                            {..._stopPanResponder.panHandlers} >
                            <circle
                                r={(strokeWidth - 1) / 2}
                                fill={trackColor}
                                stroke={gradientColorTo}
                                strokeWidth='1' />
                            <g transform={`translate(-${9 * iconScale}, -${9 * iconScale}) scale(${1.1 * iconScale}, ${1.1 * iconScale})`}>
                                {stopIcon}
                            </g>
                        </g>
                        {/* --- start icon --- */}
                        <g
                            fill={gradientColorFrom}
                            transform={`translate(${start.fromX},${start.fromY})`}
                            {..._startPanResponder.panHandlers} >
                            <circle
                                r={(strokeWidth - 1) / 2}
                                fill={trackColor}
                                stroke={gradientColorFrom}
                                strokeWidth='1' />
                            <g transform={`translate(-${9 * iconScale}, -${9 * iconScale}) scale(${1.1 * iconScale}, ${1.1 * iconScale})`}>
                                {startIcon}
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        );
    })();
}