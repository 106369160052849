import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import intl from 'react-intl-universal'
import { useCookies } from 'react-cookie'

import * as Dimens from '../styles/dimens'
import * as Colors from '../styles/colors'
import Column from '../components/layout/column'
import Row from '../components/layout/row'

import { Dialog, ButtonBase } from '@material-ui/core'

import ImgFlagTaiwan from '../assets/flags/img_flag_taiwan.svg'
import ImgFlagUSA from '../assets/flags/img_flag_usa.svg'
import ImgFlagChina from '../assets/flags/img_flag_china.svg'

LanguagesDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
}

const Languages = [
    { name: '繁體中文', code: 'zh-TW', icon: ImgFlagTaiwan },
    { name: 'English', code: 'en', icon: ImgFlagUSA },
    { name: '简体中文', code: 'zh-CN', icon: ImgFlagChina }
]

function LanguagesDialog(props) {

    const css = useCss(props)
    const [cookie, setCookie] = useCookies(['lang'])

    return (() => {
        return (
            <Dialog onClose={props.onClose} open={props.open}>
                <Column width={`${Dimens.languagesDialogWidth}px`} height='auto'
                    onTouchMove={(event) => { event.preventDefault(); }}>
                    <Row width='100%' height={`${Dimens.languagesDialogHeader}px`} className={css.headerLayout} >
                        <span className={css.titleText} >{intl.get('dialog_lang_title')}</span>
                    </Row>
                    {
                        Languages.map((item, index) => {
                            return (
                                <ButtonBase key={item.code} className={css.itemLayout}
                                    onClick={() => {
                                        setCookie('lang', item.code, { path: '/' })
                                        props.onClose()
                                    }}>
                                    <Row width='100%' height={`${Dimens.languagesDialogItem}px`} className={css.itemContent}>
                                        <img className={css.imgFlag} alt={item.name} src={item.icon} />
                                        <span className={css.itemText} >{item.name}</span>
                                    </Row>
                                </ButtonBase>
                            )
                        })
                    }
                </Column>
            </Dialog>
        )
    })()
}

const useCss = makeStyles({
    headerLayout: {
        alignItems: 'center',
        padding: `0px ${Dimens.marginM}px`
    },
    titleText: {
        fontSize: Dimens.textL,
        fontWeight: 'bold',
        color: Colors.textBNormal,
    },
    itemLayout: {
        width: '100%',
        height: `${Dimens.languagesDialogItem}px`
    },
    itemContent: {
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `0px ${Dimens.marginM}px`
    },
    imgFlag: {
        width: `${Math.floor(Dimens.languagesDialogItem / 2)}px`,
        height: `${Math.floor(Dimens.languagesDialogItem / 2)}px`,
    },
    itemText: {
        fontSize: Dimens.textM,
        fontWeight: 'bold',
        color: Colors.textBNormal,
    }
})

export default LanguagesDialog