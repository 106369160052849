export const textXL = '1.3rem';
export const textL = '1.1rem';
export const textM = '0.95rem';
export const textS = '0.85rem';
export const textXS = '0.75rem';
export const textXXS = '0.65rem';

export const marginS = 5;
export const marginM = 10;
export const marginL = 20;
export const marginXL = 30;

export const divider = 1;
export const dividerL = 2;

export const textFieldSize = 40;
export const btnSize = 40;

export const loginBoxRadius = 10;

export const appbarSize = 60;
export const appbarSizeL = 80;
export const appbarBtnHeight = 50;
export const appbarBtnWidth = 100;
export const appbarMenuItem = 120

export const drawerItemSize = 50;

export const notiBarMaxWidth = 550
export const notiBarHeader = 40
export const notiBarHeaderBtn = 22
export const notiBarTab = 40
export const notiBarItemSize = 60;
export const notiBarItemSizeL = 70;
export const notiBarIndicatorSize = 8;

export const managementHeaderSize = 50;
export const managementHeaderSizeL = 70;
export const managementHeaderIconSize = 25;
export const managementShiftSettingBtnSize = 100;
export const managementDeviceItemWidthL = 200;
export const managementDeviceItemWidth = 130;

export const padConfigLoading = 200;
export const padConfigHeaderBar = 55;
export const padConfigHeaderBarAddIcon = 20;
export const padConfigMinWidth = 320;
export const padConfigHeight = 910;
export const padConfigLanHeight = 725;
export const padConfigLayoutCorner = 8;
export const padConfigHeader = 40;
export const padConfigSlider = 35;
export const padConfigIcon = 20;

export const arrangeShiftIconSize = 20;
export const arrangeShiftFooter = 50;

export const padMinWidth = 200;
export const padHeight = 350;
export const padCorner = 8;
export const padAlertConfigBar = 35;
export const padAlertConfigBarIcon = 25;
export const padHeaderBar = 50;
export const padFooterBar = 35;
export const padIcon = 25;
export const padIconBtn = 21;
export const padIconBtnPadding = 2;
export const padActivityProgress = 20;
export const padLoading = 200;
export const padOfflineIcon = 80;
export const padMiTempIcon = 20

export const historyDeviceItemWidthL = 200;
export const historyDeviceItemWidth = 130;
export const historyDeviceItem = 55;
export const historyDeviceItemIndicator = 5;
export const historyChartHeaderBar = 60;
export const historyChartHeaderBarIconBtn = 24;
export const historyChartHeaderBarIconBtnPadding = 3;
export const historyChartItem = 250;
export const historyChartItemCorner = 8;
export const historyChartItemHeader = 40;
export const historyChartItemFooter = 40;

export const chartLegendIndicator = 10;

/* --- Widgets --- */
export const switchPadConfigWidth = 80;
export const switchPadConfigWidthL = 100;
export const switchPadConfigHeight = 35;

export const padAlertSelectorWidth = 120;
export const padAlertSelectorHeight = 35;
export const padAlertSelectorHeightL = 50;

export const alertTimeDialogWidth = 250;
export const alertTimeDialogWidthL = 300;

export const addPadDialogWidth = 280;
export const addPadDialogWidthL = 420;
export const addPadDialogHeight = 711;
export const addPadDialogTitle = 50;
export const addPadDialogFooter = 50;

export const datePickerDialogFooter = 40;

export const notiHistoryDialogWidth = 200;
export const notiHistoryDialogWidthL = 300;
export const notiHistoryDialogTitle = 45
export const notiHistoryDialogItem = 40

export const languagesDialogWidth = 200
export const languagesDialogHeader = 40
export const languagesDialogItem = 50

export const voiceLangDialogWidth = 300;
