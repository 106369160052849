import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

const CustomScrollbars = ({ onScroll, forwardedRef, style, children }) => {
    const refSetter = React.useCallback(
        scrollbarsRef => {
            if (scrollbarsRef) {
                forwardedRef(scrollbarsRef.view);
            } else {
                forwardedRef(null);
            }
        },
        [forwardedRef]
    );

    return (
        <Scrollbars
            ref={refSetter}
            style={{ ...style, overflow: "hidden" }}
            autoHide
            onScroll={onScroll}
        >
            {children}
        </Scrollbars>
    );
};

const CustomScrollbarsVirtualList = React.forwardRef((props, ref) => (
    <CustomScrollbars {...props} forwardedRef={ref} />
));

export default CustomScrollbarsVirtualList