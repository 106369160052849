import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Route, Redirect, withRouter, Switch } from "react-router-dom";
import { useCookies } from 'react-cookie'

import intl from 'react-intl-universal'
import zh_tw from './styles/strings/zh_tw'
import en from './styles/strings/en'
import zh_cn from './styles/strings/zh_cn'

import Amplify from 'aws-amplify';

import SignInPage from './pages/login/sign_in_page';
import LoginRootPage from './pages/login/login_root_page';
import MainPage from './pages/main/main_page';

Amplify.configure(process.env.REACT_APP_LOCAL === "true" ? {
    API: {
        endpoints: [
            {
                name: "Local_Application",
                // endpoint: "http://lan-test.kalenet.info:8081"
                endpoint: "http://" + window.location.hostname + ":8081"
                // endpoint: "http://" + "192.168.32.123" + ":8081"
            },
        ]
    },
} : {
        Auth: {

            // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
            identityPoolId: 'us-west-2:5cf8ed61-c24f-4d98-a1bb-75c1f4536b80',

            // REQUIRED - Amazon Cognito Region
            region: 'us-west-2',

            // OPTIONAL - Amazon Cognito User Pool ID
            userPoolId: 'us-west-2_nI2sAAWMg',

            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolWebClientId: '2d77rjh1oa7nqp2eu2t4br30a1',
        },
        API: {
            endpoints: [
                {
                    name: "WhizPad_Application",
                    endpoint: "https://uuz9vlvtvd.execute-api.us-west-2.amazonaws.com/whizpad_application"
                },
            ]
        }
    }
);

const locales = {
    'zh-TW': zh_tw,
    'en': en,
    'zh-CN': zh_cn,
}

function App(props) {

    const [cookie, setCookie, removeCookie] = useCookies(['lang'])
    const [init, setInit] = useState(false)

    useEffect(() => {
        console.log(`App init...`);
        let locale = 'zh-TW'
        if (cookie.hasOwnProperty('lang')) {
            locale = cookie['lang']
        }
        console.log(`App locale: ${locale}`)
        intl.init({
            currentLocale: locale,
            locales
        }).then(() => {
            setInit(true)
        })
        return () => {
            setInit(false)
        }
    }, [cookie])

    return (() => {
        console.log(`cookie (lang): `, cookie)
        if (!init) return null;
        return (
            <RootLayout>
                <Switch>
                    <Route path='/login' component={LoginRootPage} />
                    <Route path='/main' component={MainPage} />
                    <Route>
                        <Redirect exact to='/main/home' />
                    </Route>
                </Switch>
            </RootLayout>
        )
    })()
}

const RootLayout = styled.div`
  width: 100vw;
  height: 100vh;
  /* max-width: 100%; */
`;

export default withRouter(App);