import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export default function Column(props) {
    return (
        <RootLayout {...props}>
            {props.children}
        </RootLayout>
    );
}

Column.propTypes = {
    reverse: PropTypes.bool,
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string,
    borderRadius: PropTypes.string,
    borderWidth: PropTypes.string,
    borderColor: PropTypes.string,
}

const RootLayout = styled.div`
    box-sizing: border-box;
    width: ${props => props.width};
    height: ${props => props.height};
    display: flex;
    align-items: flex-start;
    flex-direction: ${props => props.reverse ? 'column-reverse' : 'column'};
    background-color: ${props => props.backgroundColor || 'transparent'};
    border-style: solid;
    border-radius: ${props => props.borderRadius || '0px'};
    border-width: ${props => props.borderWidth || '0px'};
    border-color: ${props => props.borderColor || 'transparent'};
`;