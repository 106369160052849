export const primary = '#404448';
export const accent = '#DD8482';
export const disabled = '#c0c0c0';

export const textWDark = '#ffffff';
export const textWNormal = '#ffffffc0';
export const textWLight = '#ffffff90';

export const textBDark = '#000000ff';
export const textBNormal = '#000000c0';
export const textBLight = '#00000090';
export const textBXLight = '#00000050';
export const textError = '#b71c1c';
export const textLower = '#fc7563';

export const dividerDark = '#d0d0d0';
export const dividerNormal = '#e0e0e0';
export const dividerLight = '#f0f0f0';

export const iconDefault = '#606060';
export const iconDefaultW = '#ffffff';

export const pageBg = '#f5f5f5';
export const notiBarBg = '#f9f9f9';

export const padEventBlue = '#3D83C1';
export const padEventRed = '#E77975';
export const padEventGreen = '#70BF8B';

export const padOfflineDim = '#000000';
export const padOfflineIcon = '#EF6567';

export const historyDeviceListItemSelected = '#E0E0E0';
export const historyPostureLie = '#3D83C1';
export const historyPostureLeave = '#87BCEB';
export const historyActivityLight = '#D59EDB';
export const historyActivityDark = '#B149D4';
export const historySleepLeave = '#87BCEB';
export const historySleepAwake = '#F8CEBF';
export const historySleepLight = '#f79078';
export const historySleepDeep = '#90C9C4';
export const historyWeekHigh = '#3D83C1';
export const historyWeekMid = '#87BCEB';
export const historyWeekLow = '#87CEEB';