import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as Colors from '../../styles/colors';
import * as Dimens from '../../styles/dimens';
import Row from '../layout/row';

import { Button } from '@material-ui/core';

SwitchPadConfig.propTypes = {
    width: PropTypes.number,
    texts: PropTypes.array.isRequired,
    checked: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
}

SwitchPadConfig.defaultProps = {
    width: Dimens.switchPadConfigWidthL,
}

export default function SwitchPadConfig(props) {

    const clicked = (index) => () => {
        props.onChange(index);
    }

    return (() => {
        return (
            <Row width={`${props.width}px`} height={`${Dimens.switchPadConfigHeight}px`} className={props.className}>
                {
                    props.texts.map((text, index) => {
                        let position = 'center';
                        if (index === 0) position = 'left';
                        else if (index === props.texts.length - 1) position = 'right';
                        return (
                            <SwitchButton
                                key={`switch_pad_config-${text}`}
                                variant='contained' size='small' position={position} selected={props.checked === index}
                                onClick={clicked(index)}>
                                {text}
                            </SwitchButton>
                        );
                    })
                }
            </Row>
        );
    })();
}

const SwitchButton = styled(Button)`
    outline: none;
    flex: 1;
    height: ${Dimens.switchPadConfigHeight}px;
    && {
        border-top-left-radius: ${props => props.position === 'left' ? '5px' : '0px'};
        border-bottom-left-radius: ${props => props.position === 'left' ? '5px' : '0px'};
        border-top-right-radius: ${props => props.position === 'right' ? '5px' : '0px'};
        border-bottom-right-radius: ${props => props.position === 'right' ? '5px' : '0px'};
        min-width: 20px;
    }
    &.MuiButton-contained {
        && {
            color: #fff;
            background-color: ${props => {
        return props.selected === true ? Colors.accent : '#cccccc';
    }};
            box-shadow: none;
        }
    }
`;