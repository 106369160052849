import React, { useState, useEffect, useRef, useContext } from 'react';
import { makeStyles } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import intl from 'react-intl-universal'
import { withRouter } from 'react-router-dom';

import * as Rx from 'rxjs'
import { debounceTime } from 'rxjs/operators';
import HomeBloc from '../../../bloc/main/home/home_bloc';
import { MainContext, HomeContext } from '../../../utils/context_utils';
import PadItem from './pad_item';

import * as Dimens from '../../../styles/dimens';
import * as Colors from '../../../styles/colors';
import Stack from '../../../components/layout/stack';
import Column from '../../../components/layout/column';
import NotificationsHistoryDialog from '../../../dialog/notifications_history_dialog'
import DatePickerDialog from '../../../dialog/date_picker_dialog'
import CustomScrollbarsVirtualList from '../../../components/widgets/scrollbar/CustomScrollbarsVirtualList'

import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeGrid as Grid, areEqual } from 'react-window';
import memorize from 'memorize-one';
import BlocBuilder from 'bloc-builder-react';
import Lottie from 'react-lottie';
import { Scrollbars } from 'react-custom-scrollbars';

import AnimLoading from '../../../assets/lottie/textfield_loading.json';

let c, w;
const GridItem = React.memo(withRouter((props) => {
    const { history, columnIndex, rowIndex, style, data } = props;
    const index = rowIndex * c + columnIndex;
    return (
        <PadItem
            style={style}
            index={index}
            cardWidth={w}
            data={data}
            onSettingClick={() => {
                console.log('data', data[index].config.deviceId);
                const deviceId = data[index].config.deviceId;
                history.push({
                    pathname: '/main/management',
                    state: { deviceId: deviceId }
                });
            }}
            onHistoryClick={() => {
                const deviceId = data[index].config.deviceId;
                history.push({
                    pathname: '/main/history',
                    state: { deviceId: deviceId }
                });
            }} />
    );
}, areEqual));

const createItemData = memorize((items) => ({
    items,
}));

export default function HomePage(props) {
    const classes = useStyles(props);
    const isWeb = useMediaQuery('(min-width: 768px)');

    const gridView = useRef(null);

    let _disposables = useRef(new Rx.Subscription());
    const _mainBloc = useContext(MainContext).bloc;
    let _bloc = useRef(new HomeBloc());

    const [init, setInit] = useState(false);

    useEffect(() => {
        const disposables = _disposables.current;
        const bloc = _bloc.current;
        bloc.init();
        _setGridViewSizeObserver();
        _mainBloc.getPads();
        setInit(true);
        return () => {
            bloc.dispose();
            disposables.unsubscribe();
            setInit(false);
        }
    }, []);

    /* --- Functions --- */

    function _setGridViewSizeObserver() {
        let it = _bloc.current.gridViewSize
            .pipe(
                debounceTime(300),
            )
            .subscribe({
                next: ({ width, height }) => {
                    // if (gridView.current !== null) gridView.current.resetAfterIndices(0, 0, true);
                    if (gridView.current !== null) gridView.current.resetAfterColumnIndex(0, true);
                    if (gridView.current !== null) gridView.current.resetAfterRowIndex(0, true);
                }
            });
        _disposables.current.add(it);
    }

    const handleScroll = ({ target }) => {
        const { scrollTop } = target;
        gridView.current.scrollTo(scrollTop);
    }

    function _isOSWin() {
        return navigator.platform.indexOf('Win') > -1;
    }


    /* --- render --- */

    return (() => {
        if (!init) return null;
        const bloc = _bloc.current;
        return (
            <HomeContext.Provider value={{ bloc: _bloc.current }} >
                <Stack width='100%' height='100%'>
                    <Column width='100%' height='100%' className={classes.contentLayout} >
                        <AutoSizer>
                            {({ height, width }) => {
                                _bloc.current.gridViewSize.next({ width: width, height: height });
                                return (
                                    <BlocBuilder
                                        initialValue={false}
                                        subject={_mainBloc.refreshPadsUi}
                                        builder={(snapshot) => {
                                            const configs = _mainBloc.padsData.configs.filter(function (item, index) {
                                                return item.resident < 2;
                                            })
                                            const dataLen = configs.length;
                                            const appbarSize = isWeb ? Dimens.appbarSizeL : Dimens.appbarSize;
                                            const colCount = parseInt((width + 2 * Dimens.marginM) / Dimens.padMinWidth);
                                            const rowCount = parseInt(dataLen / colCount) + (dataLen % colCount > 0 ? 1 : 0);
                                            const itemWidth = width / colCount;
                                            let cardWidth = itemWidth - 2 * Dimens.marginM;
                                            cardWidth = cardWidth > Dimens.padMinWidth / 0.9 ? cardWidth * 0.9 : cardWidth;
                                            const contentWidth = cardWidth - 2 * Dimens.marginS;
                                            const itemHeight = Dimens.padHeaderBar + Dimens.padFooterBar + Dimens.padAlertConfigBar + (contentWidth * 0.7 / 5 * 6 * 1.3);
                                            c = colCount;
                                            w = cardWidth;
                                            const itemData = [];
                                            for (let i = 0; i < configs.length; i++) {
                                                itemData.push({
                                                    config: configs[i],
                                                    pad: _mainBloc.padsDataMap[configs[i].deviceId],
                                                });
                                            }
                                            if (_isOSWin()) {
                                                return (
                                                    <Grid ref={gridView}
                                                        style={{ overflow: false }}
                                                        width={width} height={height - appbarSize}
                                                        outerElementType={CustomScrollbarsVirtualList}
                                                        columnCount={colCount}
                                                        columnWidth={index => itemWidth}
                                                        rowCount={rowCount}
                                                        rowHeight={index => itemHeight + 2 * Dimens.marginM}
                                                        itemData={createItemData(itemData).items}  >
                                                        {GridItem}
                                                    </Grid>
                                                );
                                            } else {
                                                return (
                                                    <Grid ref={gridView}
                                                        width={width} height={height - appbarSize}
                                                        columnCount={colCount}
                                                        columnWidth={index => itemWidth}
                                                        rowCount={rowCount}
                                                        rowHeight={index => itemHeight + 2 * Dimens.marginM}
                                                        itemData={createItemData(itemData).items} >
                                                        {/* {({ columnIndex, rowIndex, style, data }) => {
                                                            const index = rowIndex * colCount + columnIndex;
                                                            return (
                                                                <PadItem style={style} index={index} cardWidth={cardWidth} />
                                                            );
                                                        }} */}
                                                        {GridItem}
                                                    </Grid>
                                                );
                                            }
                                        }} />
                                );
                            }}
                        </AutoSizer>
                    </Column>
                    {/* 頁面讀取中 */}
                    <BlocBuilder
                        initialValue={_mainBloc.padLoading.getValue()}
                        subject={_mainBloc.padLoading}
                        builder={(snapshot) => {
                            if (!snapshot.data) return null;
                            return (
                                <Column width='100%' height='100%' className={classes.loadingLayout} >
                                    <Lottie
                                        isClickToPauseDisabled
                                        options={{
                                            loop: true,
                                            autoplay: true,
                                            animationData: AnimLoading,
                                            rendererSettings: {
                                                preserveAspectRatio: 'xMidYMid slice'
                                            }
                                        }}
                                        width={`${Dimens.padLoading}px`}
                                        height='auto' />
                                    <span className={classes.loadingText}>{intl.get('loading_data')}</span>
                                </Column>
                            );
                        }} />
                    <BlocBuilder
                        initialValue={bloc.notiHistoryDialog.getValue()}
                        subject={bloc.notiHistoryDialog}
                        builder={(snapshot) => {
                            const { show, config } = snapshot.data
                            return <NotificationsHistoryDialog
                                open={show}
                                onClose={() => { bloc.notiHistoryDialog.next({ show: false, config: undefined }) }}
                                config={config} />
                        }} />
                    <BlocBuilder
                        initialValue={bloc.datePickerDialog.getValue()}
                        subject={bloc.datePickerDialog}
                        builder={(snapshot) => {
                            const { show, date } = snapshot.data
                            return <DatePickerDialog
                                open={show}
                                onClose={() => { bloc.datePickerDialog.next({ show: false, date: date }) }}
                                date={date}
                                onDateSelected={(date) => {
                                    bloc.notiHistoryDate.next(date)
                                    bloc.datePickerDialog.next({ show: false, date: date })
                                }} />
                        }} />
                </Stack>
            </HomeContext.Provider>
        );
    })();
}

const useStyles = makeStyles({
    root: {},
    contentLayout: {
        position: 'absolute',
        top: '0px',
        left: '0px'
    },
    loadingLayout: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loadingText: {
        color: Colors.textBLight,
        fontSize: Dimens.textM,
        fontWeight: 'bold',
        marginTop: `${Dimens.marginM}px`,
    },
    grid: {
        msOverflowStyle: '-ms-autohiding-scrollbar'
    }
})
