import intl from 'react-intl-universal'

class Gender {

    static parseGender(code) {
        switch(code) {
            case 0: return Gender.Female;
            case 1: return Gender.Male;
            default: return Gender.None;
        }
    }

    get code() { return this._code; }
    get text() { return this._text; }

    constructor(code, text) {
        this._code = code;
        this._text = text;
    }
}

Gender.None = new Gender(-1, intl.get('none'));
Gender.Female = new Gender(0, intl.get('female'));
Gender.Male = new Gender(1, intl.get('male'));

Object.freeze(Gender);

export default Gender;