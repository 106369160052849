import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import intl from 'react-intl-universal'
import { useCookies } from 'react-cookie'

import { useMediaQuery, Dialog, ButtonBase } from '@material-ui/core';
import * as Dimens from '../styles/dimens';
import * as Colors from '../styles/colors';
import Column from '../components/layout/column';
import Row from '../components/layout/row';
import Stack from '../components/layout/stack';
import { MainContext } from '../utils/context_utils';

const VoiceLanguagesDialog = (props) => {
    const [cookie, setCookie] = useCookies(['voice_lang']);
    const css = useCss(props);
    const mainBloc = useContext(MainContext).bloc;

    return (() => {
        
        return (
            <Dialog open={props.open} onClose={props.onClose} scroll='paper'>
                <Column width={`${Dimens.voiceLangDialogWidth}px`} height='100%'>
                    <Row width='100%' height='auto' className={css.headerRoot}>
                        <span className={css.headerTitle} >{intl.get('dialog_voice_lang_title')}</span>
                    </Row>
                    <div className={css.divider} />
                </Column>
                <Column width='100%' height='55vh' style={{ overflow: 'scroll' }} >
                    <div style={{ width: '100%' }}>
                        {mainBloc.speechLanguages.getValue().map((item, index) => {
                            const selected = item.name === mainBloc.speechConfig.name;
                            return (
                                <>
                                    <ButtonBase
                                        style={{ width: '100%', height: 'auto' }}
                                        onClick={() => {
                                            setCookie('voice_lang', { name: item.name, lang: item.lang }, { path: '/' });
                                            mainBloc.speechConfig = { name: item.name, lang: item.lang };
                                            props.onClose();
                                        }}>
                                        <Row width='100%' height='45px' className={css.itemRoot}
                                            style={{ backgroundColor: (selected ? Colors.accent : 'transparent') }}>
                                            <span className={css.itemTxt}>{item.name}</span>
                                            <span className={css.itemTxt}>{item.lang}</span>
                                        </Row>
                                    </ButtonBase>
                                    <div className={css.itemDivider} />
                                </>
                            );
                        })}
                    </div>
                </Column>
            </Dialog>
        );
    })();
};

VoiceLanguagesDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
}

VoiceLanguagesDialog.defaultProps = {
    open: false,
    onClose: undefined,
}

const useCss = makeStyles({
    root: {
    },
    divider: {
        width: '100%',
        height: `${Dimens.divider}px`,
        backgroundColor: Colors.dividerNormal,
    },
    headerRoot: {
        flex: 1,
        padding: `${Dimens.marginM}px ${Dimens.marginM}px`,
        backgroundColor: Colors.primary,
    },
    headerTitle: {
        fontSize: Dimens.textL,
        color: Colors.textWDark,
    },
    itemRoot: {
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: `0px ${Dimens.marginM}px`,
    },
    itemDivider: {
        width: `calc(100% - ${2 * Dimens.marginM}px)`,
        height: `${Dimens.divider}px`,
        backgroundColor: Colors.dividerNormal,
        margin: `0px ${Dimens.marginM}px`,
    },
    itemTxt: {
        fontSize: Dimens.textM,
        color: Colors.textBNormal,
    }
})

export default VoiceLanguagesDialog;
