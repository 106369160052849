import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as Dimens from '../../styles/dimens';
import * as Colors from '../../styles/colors';

var Color = require('color');

export default function ButtonText(props) {
    return (
        <InputBtn type='button' {...props} value={props.text} className={props.className} />
    );
}

ButtonText.propTypes = {
    width: PropTypes.string.isRequired,
    textcolor: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    fontsize: PropTypes.string,
}

ButtonText.defaultProps = {
    width: 'auto',
    textcolor: Colors.textBNormal,
    text: '預設',
    fontsize: Dimens.textM,
}

const InputBtn = styled.input`
    :active {
        color: ${props => Color(props.textcolor).darken(0.3).hex()};
    }
    width: ${props => props.width};
    height: ${Dimens.btnSize}px;
    outline: none;
    border: none;
    font-size: ${props => props.fontsize === undefined ? Dimens.textM : props.fontsize};
    color: ${props => props.textcolor};
    background-color: transparent;
    cursor: pointer;
`;