import React, { useEffect, useState, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles'
import intl from 'react-intl-universal'

import * as Rx from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import PadConfig from '../../model/data/pad_config';

import * as Colors from '../../styles/colors';
import * as Dimens from '../../styles/dimens';
import Stack from '../../components/layout/stack';
import Column from '../../components/layout/column';
import Row from '../../components/layout/row';
import SwitchPadConfig from '../widgets/switch_pad_config';
import PadAlertSelector from '../widgets/pad_alert_selector';
import BlocBuilder from 'bloc-builder-react';
import AlertTimeDialog from '../../dialog/alert_time_dialog';

import { Slider, IconButton } from '@material-ui/core';
import Lottie from 'react-lottie';

import IcAlarm from '@material-ui/icons/AlarmRounded';
import IcDelete from '@material-ui/icons/DeleteRounded';
import AnimLoading from '../../assets/lottie/loading_01.json';

var printf = require('printf');
var Lodash = require('lodash');

PadConfigWidget.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    data: PropTypes.any.isRequired,
    isHeader: PropTypes.bool,
    isSaving: PropTypes.bool,
    isOtherSetting: PropTypes.bool,
    onDataUpdated: PropTypes.func,
    onDelete: PropTypes.func,
}

PadConfigWidget.defaultProps = {
    isHeader: true,
    isSaving: false,
    isOtherSetting: true,
}

/**
 * data => {
 *      deviceId: "WhizPad_RC-FFFFFFFFFFFF",
 *      name: "",
 * }
 **/

export default function PadConfigWidget(props) {

    const classes = useStyles(props);

    const [isInit, setInit] = useState(false);

    const _savedData = Lodash.cloneDeep(props.data);

    const _disposables = useRef(new Rx.Subscription());
    let _isDataChanged = useRef(new Rx.BehaviorSubject(false));
    const _name = useRef(new Rx.BehaviorSubject(props.data.name));
    const _resident = useRef(new Rx.BehaviorSubject(props.data.resident));
    const _stageAlert = useRef(new Rx.BehaviorSubject(props.data.stageAlert));
    const _activityAlertRange = useRef(new Rx.BehaviorSubject(props.data.activityAlert.range));
    const _activityAlertLie = useRef(new Rx.BehaviorSubject(props.data.activityAlert.lie));
    const _activityAlertOver = useRef(new Rx.BehaviorSubject(props.data.activityAlert.over));
    const _activityAlertLower = useRef(new Rx.BehaviorSubject(props.data._activityAlert.lower));
    const _activityAlertRollOver = useRef(new Rx.BehaviorSubject(props.data._activityAlert.rollOver ? props.data._activityAlert.rollOver : {
        checked: false,
        threshold: 20,
        duration: 120,
    }));
    const _miTempDeviceId = useRef(new Rx.BehaviorSubject(props.data.miTempDeviceId))
    const _alertTimeDialog = useRef(new Rx.BehaviorSubject({ show: false }));

    useEffect(() => {
        const disposable = _disposables.current;
        _setDataChangedObserver();
        setInit(true);
        return () => {
            disposable.unsubscribe();
            setInit(false);
        };
    }, []);

    /* --- Functions --- */

    function _setDataChangedObserver() {
        let it = _isDataChanged.current.pipe(
            debounceTime(500)
        ).subscribe({
            next: val => {
                if (!val) return;
                // console.log('update',props.data)
                const config = new PadConfig(
                    props.data.deviceId,
                    _name.current.getValue(),
                    PadConfig.residentToGender(_resident.current.getValue()),
                    _stageAlert.current.getValue(),
                    {
                        range: _activityAlertRange.current.getValue(),
                        lie: _activityAlertLie.current.getValue(),
                        over: _activityAlertOver.current.getValue(),
                        lower: _activityAlertLower.current.getValue(),
                        rollOver: _activityAlertRollOver.current.getValue(),
                    },
                    _miTempDeviceId.current.getValue(),
                    props.data.created,
                    props.data.index
                );
                const isDiff = _savedData.isDifferent(config);
                if (props.onDataUpdated !== undefined)
                    props.onDataUpdated(isDiff, config);
            }
        });
        _disposables.current.add(it);
    }

    return (() => {
        if (!isInit) return null;
        const switchSize = props.width > 450 ? Dimens.switchPadConfigWidthL : Dimens.switchPadConfigWidth;
        const padAlertSelectorHeight = Dimens.padAlertSelectorHeight;
        let height = props.height
        if (!props.isOtherSetting) {
            height -= 90
        }
        return (
            <Column width={`${props.width}px`} height={`${height}px`} className={classes.root} >
                {!props.isHeader ? null :
                    <Stack width='100%' height={`${Dimens.padConfigHeader}px`}>
                        <Row width='100%' height='100%'
                            backgroundColor={Colors.primary}
                            className={classes.headerLayout}>
                            <span className={`${classes.subTitleText} ${classes.title}`}>{`${intl.get('pad_config_widget_id_title')}: ${props.data.macAddress}`}</span>
                        </Row>
                        <SvgIconButton className={classes.deleteIconBtn}
                            onClick={() => { if (props.onDelete !== undefined) props.onDelete(props.data.deviceId) }}><IcDelete className={classes.deleteIcon} />
                        </SvgIconButton>
                    </Stack>
                }
                <Stack width='100%' height='auto' style={{ flex: '1' }}>
                    <Column width='100%' height='100%' className={classes.contentLayout} >
                        {/* --- 基本設定 --- */}
                        <span className={`${classes.titleText} ${classes.title2}`}>{intl.get('pad_config_widget_basic_title')}</span>
                        <Row width='100%' height='auto'>
                            <Column width='auto' height='auto' style={{ flex: '1', marginRight: `${Dimens.marginL}px` }}>
                                <span className={`${classes.subTitle} ${classes.subTitleText}`}>{intl.get('room')}</span>
                                <BlocBuilder
                                    initialValue={_name.current.getValue()}
                                    subject={_name.current}
                                    builder={(snapshot) => {
                                        return (
                                            <RoomTextField value={`${snapshot.data}`} onChange={(event) => {
                                                _name.current.next(event.target.value);
                                                _isDataChanged.current.next(true);
                                            }} />
                                        );
                                    }} />
                            </Column>
                            <Column width='auto' height='auto'>
                                <span className={`${classes.subTitle} ${classes.subTitleText}`}>{intl.get('pad_config_widget_basic_resident_title')}</span>
                                <BlocBuilder
                                    initialValue={_resident.current.getValue()}
                                    subject={_resident.current}
                                    builder={(snapshot) => {
                                        return (
                                            <SwitchPadConfig
                                                width={switchSize * 1.5}
                                                className={classes.residentStatus}
                                                texts={[intl.get('male'), intl.get('female'), intl.get('none')]}
                                                checked={snapshot.data}
                                                onChange={(index) => {
                                                    _resident.current.next(index);
                                                    _isDataChanged.current.next(true);
                                                }} />
                                        );
                                    }} />
                            </Column>
                        </Row>
                        {/* --- 三階段離床提醒設定 --- */}
                        <BlocBuilder
                            initialValue={_stageAlert.current.getValue()}
                            subject={_stageAlert.current}
                            builder={(snapshot) => {
                                const dayAlert = [snapshot.data.day.leave, snapshot.data.day.side, snapshot.data.day.sit];
                                const noonAlert = [snapshot.data.noon.leave, snapshot.data.noon.side, snapshot.data.noon.sit];
                                const nightAlert = [snapshot.data.night.leave, snapshot.data.night.side, snapshot.data.night.sit];
                                return (
                                    <Fragment>
                                        <span className={`${classes.titleText} ${classes.title2}`}>{intl.get('pad_config_widget_stage_alert_title')}</span>
                                        <Row width='100%' height='auto' className={classes.padAlertSelectorLayout}>
                                            <span className={`${classes.subTitle} ${classes.subTitleText}`}>{intl.get('arrange_shift_day')}</span>
                                            <PadAlertSelector
                                                className={`${classes.padAlertSelector}`}
                                                width='80%' height={`${padAlertSelectorHeight}px`}
                                                selected={dayAlert}
                                                onChange={(arr) => {
                                                    let alert = _stageAlert.current.getValue();
                                                    alert.day.leave = arr[0];
                                                    alert.day.side = arr[1];
                                                    alert.day.sit = arr[2];
                                                    _stageAlert.current.next(alert);
                                                    _isDataChanged.current.next(true);
                                                }}
                                            />
                                        </Row>
                                        <Row width='100%' height='auto' className={classes.padAlertSelectorLayout}>
                                            <span className={`${classes.subTitle} ${classes.subTitleText}`}>{intl.get('arrange_shift_noon')}</span>
                                            <PadAlertSelector
                                                className={`${classes.padAlertSelector}`}
                                                width='80%' height={`${padAlertSelectorHeight}px`}
                                                selected={noonAlert}
                                                onChange={(arr) => {
                                                    let alert = _stageAlert.current.getValue();
                                                    alert.noon.leave = arr[0];
                                                    alert.noon.side = arr[1];
                                                    alert.noon.sit = arr[2];
                                                    _stageAlert.current.next(alert);
                                                    _isDataChanged.current.next(true);
                                                }}
                                            />
                                        </Row>
                                        <Row width='100%' height='auto' className={classes.padAlertSelectorLayout} >
                                            <span className={`${classes.subTitle} ${classes.subTitleText}`}>{intl.get('arrange_shift_night')}</span>
                                            <PadAlertSelector
                                                className={`${classes.padAlertSelector}`}
                                                width='80%' height={`${padAlertSelectorHeight}px`}
                                                selected={nightAlert}
                                                onChange={(arr) => {
                                                    let alert = _stageAlert.current.getValue();
                                                    alert.night.leave = arr[0];
                                                    alert.night.side = arr[1];
                                                    alert.night.sit = arr[2];
                                                    _stageAlert.current.next(alert);
                                                    _isDataChanged.current.next(true);
                                                }}
                                            />
                                        </Row>
                                    </Fragment>
                                );
                            }} />
                        { /* --- 活動提醒設定 --- */}
                        <span className={`${classes.titleText} ${classes.title2}`}>{intl.get('pad_config_widget_activity_alert_title')}</span>
                        {/* 久臥提醒 */}
                        <BlocBuilder
                            initialValue={_activityAlertLie.current.getValue()}
                            subject={_activityAlertLie.current}
                            builder={(snapshot) => {
                                const lie = snapshot.data;
                                return (
                                    <Row width='100%' height='auto'>
                                        <Column width='auto' height='auto'>
                                            <span className={`${classes.subTitle} ${classes.subTitleText}`}>{intl.get('pad_config_widget_activity_alert_over_time_title')}</span>
                                            <SwitchPadConfig
                                                width={switchSize}
                                                className={classes.residentStatus}
                                                texts={[intl.get('on'), intl.get('off')]}
                                                checked={lie.checked ? 0 : 1}
                                                onChange={(index) => {
                                                    let alert = _activityAlertLie.current.getValue();
                                                    alert.checked = index === 0;
                                                    _activityAlertLie.current.next(alert);
                                                    _isDataChanged.current.next(true);
                                                }} />
                                        </Column>
                                        <Column width='auto' height='auto' className={classes.activityAlertSliderLayout}>
                                            <Row width='100%' height='auto' style={{ justifyContent: 'space-between' }}>
                                                <span className={`${classes.subTitle} ${classes.subTitleText}`}>{intl.get('pad_config_widget_activity_alert_over_time_setting')}</span>
                                                <span className={`${classes.numContentText}`}>
                                                    <span className={classes.numText}>{printf('%02d', parseInt(lie.threshold / 60))}</span> {intl.get('hour')} <span className={classes.numText}>{printf('%02d', lie.threshold % 60)}</span> {intl.get('minute')}
                                                </span>
                                            </Row>
                                            <Row width='100%' height={`${Dimens.padConfigSlider}px`} className={classes.activityAlertSlider}>
                                                <CustomSlider
                                                    disabled={!lie.checked}
                                                    defaultValue={lie.threshold}
                                                    min={15} max={600} step={15}
                                                    onChange={(event, newValue) => {
                                                        let alert = _activityAlertLie.current.getValue();
                                                        alert.threshold = newValue;
                                                        _activityAlertLie.current.next(alert);
                                                        _isDataChanged.current.next(true);
                                                    }} />
                                            </Row>
                                        </Column>
                                    </Row>
                                );
                            }} />
                        {/* 低活動量提醒 */}
                        <BlocBuilder
                            initialValue={_activityAlertLower.current.getValue()}
                            subject={_activityAlertLower.current}
                            builder={(snapshot) => {
                                const lower = snapshot.data;
                                const checked = lower.checked;
                                const threshold = lower.threshold;
                                let duration = lower.duration;
                                if (duration === null || duration === undefined) {
                                    duration = 120;
                                }
                                return (
                                    <Row width='100%' height='auto' style={{ marginTop: `${Dimens.marginL}px` }}>
                                        <Column width='auto' height='auto'>
                                            <span className={`${classes.subTitle} ${classes.subTitleText}`}>{intl.get('pad_config_widget_activity_alert_activity_lower_title')}</span>
                                            <SwitchPadConfig
                                                width={switchSize}
                                                className={classes.residentStatus}
                                                texts={[intl.get('on'), intl.get('off')]}
                                                checked={checked ? 0 : 1}
                                                onChange={(index) => {
                                                    let alert = _activityAlertLower.current.getValue();
                                                    alert.checked = index === 0;
                                                    _activityAlertLower.current.next(alert);
                                                    _isDataChanged.current.next(true);
                                                }} />
                                        </Column>
                                        <Column width='auto' height='auto' className={classes.activityAlertSliderLayout}>
                                            <Row width='100%' height='auto' style={{ justifyContent: 'space-between' }}>
                                                <span className={`${classes.subTitle} ${classes.subTitleText}`}>{intl.get('pad_config_widget_activity_alert_activity_low_threshold_setting')}</span>
                                                <span className={`${classes.numContentText}`}><span className={classes.numText}>{`${threshold}`}</span> ％</span>
                                            </Row>
                                            <Row width='100%' height={`${Dimens.padConfigSlider}px`} className={classes.activityAlertSlider}>
                                                <CustomSlider
                                                    disabled={!checked}
                                                    defaultValue={threshold}
                                                    min={1} max={100} step={1}
                                                    onChange={(event, newValue) => {
                                                        let alert = _activityAlertLower.current.getValue();
                                                        alert.threshold = newValue;
                                                        _activityAlertLower.current.next(alert);
                                                        _isDataChanged.current.next(true);
                                                    }} />
                                            </Row>
                                            {/* {process.env.REACT_APP_LOCAL !== "true" ? */}
                                                <>
                                                    <Row width='100%' height='auto' style={{ justifyContent: 'space-between' }}>
                                                        <span className={`${classes.subTitle} ${classes.subTitleText}`}>{intl.get('pad_config_widget_activity_alert_activity_low_time_setting')}</span>
                                                        <span className={`${classes.numContentText}`}>
                                                            <span className={classes.numText}>{printf('%02d', parseInt(duration / 60))}</span> {intl.get('hour')} <span className={classes.numText}>{printf('%02d', duration % 60)}</span> {intl.get('minute')}
                                                        </span>
                                                    </Row>
                                                    <Row width='100%' height={`${Dimens.padConfigSlider}px`} className={classes.activityAlertSlider}>
                                                        <CustomSlider
                                                            disabled={!checked}
                                                            defaultValue={duration}
                                                            min={15} max={480} step={15}
                                                            onChange={(event, newValue) => {
                                                                let alert = _activityAlertLower.current.getValue();
                                                                alert.duration = newValue;
                                                                _activityAlertLower.current.next(alert);
                                                                _isDataChanged.current.next(true);
                                                            }} />
                                                    </Row>
                                                </>
                                                {/* :<></>} */}
                                        </Column>
                                    </Row>
                                );
                            }} />
                        {/* 躁動提醒 */}
                        <BlocBuilder
                            initialValue={_activityAlertOver.current.getValue()}
                            subject={_activityAlertOver.current}
                            builder={(snapshot) => {
                                const over = snapshot.data;
                                return (
                                    <Row width='100%' height='auto' style={{ marginTop: `${Dimens.marginL}px` }}>
                                        <Column width='auto' height='auto'>
                                            <span className={`${classes.subTitle} ${classes.subTitleText}`}>{intl.get('pad_config_widget_activity_alert_activity_higher_title')}</span>
                                            <SwitchPadConfig
                                                width={switchSize}
                                                className={classes.residentStatus}
                                                texts={[intl.get('on'), intl.get('off')]}
                                                checked={over.checked ? 0 : 1}
                                                onChange={(index) => {
                                                    let alert = _activityAlertOver.current.getValue();
                                                    alert.checked = index === 0;
                                                    _activityAlertOver.current.next(alert);
                                                    _isDataChanged.current.next(true);
                                                }} />
                                        </Column>
                                        <Column width='auto' height='auto' className={classes.activityAlertSliderLayout}>
                                            <Row width='100%' height='auto' style={{ justifyContent: 'space-between' }}>
                                                <span className={`${classes.subTitle} ${classes.subTitleText}`}>{intl.get('pad_config_widget_activity_alert_activity_high_setting')}</span>
                                                <span className={`${classes.numContentText}`}><span className={classes.numText}>{`${over.threshold}`}</span> ％</span>
                                            </Row>
                                            <Row width='100%' height={`${Dimens.padConfigSlider}px`} className={classes.activityAlertSlider}>
                                                <CustomSlider
                                                    disabled={!over.checked}
                                                    defaultValue={over.threshold}
                                                    min={1} max={100} step={1}
                                                    onChange={(event, newValue) => {
                                                        let alert = _activityAlertOver.current.getValue();
                                                        alert.threshold = newValue;
                                                        _activityAlertOver.current.next(alert);
                                                        _isDataChanged.current.next(true);
                                                    }} />
                                            </Row>
                                        </Column>
                                    </Row>
                                );
                            }} />
                        {/* 翻身拍背提醒 */}
                        {/* {process.env.REACT_APP_LOCAL !== "true" ? */}
                            <BlocBuilder
                                initialValue={_activityAlertRollOver.current.getValue()}
                                subject={_activityAlertRollOver.current}
                                builder={(snapshot) => {
                                    const rollOver = snapshot.data;
                                    let checked = false;
                                    let threshold = 20;
                                    let duration = 120;
                                    if (rollOver) {
                                        if (rollOver.checked !== null && rollOver.checked !== undefined) {
                                            checked = rollOver.checked;
                                        }
                                        if (rollOver.threshold !== null && rollOver.threshold !== undefined) {
                                            threshold = rollOver.threshold;
                                        }
                                        if (rollOver.duration !== null && rollOver.duration !== undefined) {
                                            duration = rollOver.duration;
                                        }
                                    }
                                    return (
                                        <Row width='100%' height='auto' style={{ marginTop: `${Dimens.marginL}px` }}>
                                            <Column width='auto' height='auto'>
                                                <span className={`${classes.subTitle} ${classes.subTitleText}`}>{intl.get('pad_config_widget_activity_alert_activity_roll_over_title')}</span>
                                                <SwitchPadConfig
                                                    width={switchSize}
                                                    className={classes.residentStatus}
                                                    texts={[intl.get('on'), intl.get('off')]}
                                                    checked={checked ? 0 : 1}
                                                    onChange={(index) => {
                                                        let alert = _activityAlertRollOver.current.getValue();
                                                        alert.checked = index === 0;
                                                        _activityAlertRollOver.current.next(alert);
                                                        _isDataChanged.current.next(true);
                                                    }} />
                                            </Column>
                                            <Column width='auto' height='auto' className={classes.activityAlertSliderLayout}>
                                                <Row width='100%' height='auto' style={{ justifyContent: 'space-between' }}>
                                                    <span className={`${classes.subTitle} ${classes.subTitleText}`}>{intl.get('pad_config_widget_activity_alert_activity_roll_over_threshold_setting')}</span>
                                                    <span className={`${classes.numContentText}`}><span className={classes.numText}>{`${threshold}`}</span> ％</span>
                                                </Row>
                                                <Row width='100%' height={`${Dimens.padConfigSlider}px`} className={classes.activityAlertSlider}>
                                                    <CustomSlider
                                                        disabled={!checked}
                                                        defaultValue={threshold}
                                                        min={1} max={100} step={1}
                                                        onChange={(event, newValue) => {
                                                            let alert = _activityAlertRollOver.current.getValue();
                                                            alert.threshold = newValue;
                                                            _activityAlertRollOver.current.next(alert);
                                                            _isDataChanged.current.next(true);
                                                        }} />
                                                </Row>
                                                <Row width='100%' height='auto' style={{ justifyContent: 'space-between' }}>
                                                    <span className={`${classes.subTitle} ${classes.subTitleText}`}>{intl.get('pad_config_widget_activity_alert_activity_roll_over_time_setting')}</span>
                                                    <span className={`${classes.numContentText}`}>
                                                        <span className={classes.numText}>{printf('%02d', parseInt(duration / 60))}</span> {intl.get('hour')} <span className={classes.numText}>{printf('%02d', duration % 60)}</span> {intl.get('minute')}
                                                    </span>
                                                </Row>
                                                <Row width='100%' height={`${Dimens.padConfigSlider}px`} className={classes.activityAlertSlider}>
                                                    <CustomSlider
                                                        disabled={!checked}
                                                        defaultValue={duration}
                                                        min={15} max={480} step={15}
                                                        onChange={(event, newValue) => {
                                                            let alert = _activityAlertRollOver.current.getValue();
                                                            alert.duration = newValue;
                                                            _activityAlertRollOver.current.next(alert);
                                                            _isDataChanged.current.next(true);
                                                        }} />
                                                </Row>
                                            </Column>
                                        </Row>
                                    );
                                }} />
                            {/* : <></>} */}
                        <BlocBuilder
                            initialValue={_activityAlertRange.current.getValue()}
                            subject={_activityAlertRange.current}
                            builder={(snapshot) => {
                                const range = snapshot.data;
                                return (
                                    <Row width='100%' height='auto' style={{ marginTop: `${process.env.REACT_APP_LOCAL === "true"?Dimens.marginL:Dimens.marginM}px` }}>
                                        <Column width='auto' height='auto'>
                                            <span className={`${classes.subTitle} ${classes.subTitleText}`}>{intl.get('pad_config_widget_activity_alert_range_title')}</span>
                                            <SwitchPadConfig
                                                width={switchSize * 1.5}
                                                className={classes.residentStatus}
                                                texts={[intl.get('pad_config_widget_activity_alert_range_selector_all'), intl.get('pad_config_widget_activity_alert_range_selector_range')]}
                                                checked={range.allDay ? 0 : 1}
                                                onChange={(index) => {
                                                    let alert = _activityAlertRange.current.getValue();
                                                    alert.allDay = index === 0;
                                                    _activityAlertRange.current.next(alert);
                                                    _isDataChanged.current.next(true);
                                                }} />
                                        </Column>
                                        <Column width='auto' height='auto' className={classes.activityAlertSliderLayout}>
                                            <Row width='100%' height='auto' style={{ justifyContent: 'space-between', visibility: 'hidden' }}>
                                                <span className={`${classes.subTitle} ${classes.subTitleText}`}>{intl.get('setting')}</span>
                                            </Row>
                                            <Row width='100%' height={`${Dimens.padConfigSlider}px`} className={classes.activityAlertTimeLayout} >
                                                {range.allDay ?
                                                    <span className={`${classes.subTitleText}`}>{intl.get('pad_config_widget_activity_alert_range_message_all')}</span>
                                                    :
                                                    <Fragment>
                                                        <span className={`${classes.numContentText}`}>
                                                            <span className={classes.numTextL}>{printf('%02d:%02d', parseInt(range.from / 60), range.from % 60)}</span> {intl.get('date_until')} <span className={classes.numTextL}>{printf('%02d:%02d', parseInt(range.to / 60), range.to % 60)}</span>
                                                        </span>
                                                        <SvgIconButton onClick={() => { _alertTimeDialog.current.next({ show: true }); }}><IcAlarm className={classes.alarmIcon} /></SvgIconButton>
                                                    </Fragment>
                                                }
                                            </Row>
                                        </Column>
                                    </Row>
                                );
                            }} />
                        {/* 其他設定: - 溫濕度, - 血糖血壓 */}
                        {!props.isOtherSetting ? null :
                            <Fragment>
                                <span className={`${classes.titleText} ${classes.title2}`}>{intl.get('pad_config_widget_other_title')}</span>
                                <span className={`${classes.subTitle} ${classes.subTitleText}`}>{intl.get('pad_config_widget_other_mi_temp_title')}</span>
                                <BlocBuilder
                                    initialValue={_miTempDeviceId.current.getValue()}
                                    subject={_miTempDeviceId.current}
                                    builder={(snapshot) => {
                                        return (
                                            <RoomTextField value={`${snapshot.data}`} onChange={(event) => {
                                                _miTempDeviceId.current.next(event.target.value);
                                                _isDataChanged.current.next(true);
                                            }} />
                                        );
                                    }} />
                            </Fragment>
                        }
                    </Column>
                    {!props.isSaving ? null :
                        <Row width='100%' height='40px' className={classes.savingHintLayout} >
                            <span className={classes.savingHintText} >{intl.get('loading_save')}</span>
                            <Lottie
                                isClickToPauseDisabled
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: AnimLoading,
                                    rendererSettings: {
                                        preserveAspectRatio: 'xMidYMid slice'
                                    }
                                }}
                                style={{ margin: '0px' }}
                                width='40px'
                                height='30px' />
                        </Row>
                    }
                </Stack>
                <BlocBuilder
                    initialValue={_alertTimeDialog.current.getValue()}
                    subject={_alertTimeDialog.current}
                    builder={(snapshot) => {
                        const range = _activityAlertRange.current.getValue();
                        return (
                            <AlertTimeDialog
                                onClose={() => { _alertTimeDialog.current.next({ show: false }); }} open={snapshot.data.show}
                                initTime={{ start: { h: parseInt(range.from / 60), m: range.from % 60 }, end: { h: parseInt(range.to / 60), m: range.to % 60 } }}
                                onTimeSelected={({ start, end }) => {
                                    _activityAlertRange.current.next({ from: start.h * 60 + start.m, to: end.h * 60 + end.m });
                                    _isDataChanged.current.next(true);
                                }} />
                        )
                    }} />
            </Column>
        );
    })();
}

const useStyles = makeStyles({
    root: {
    },
    savingHintLayout: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        backgroundColor: '#000000',
        opacity: '0.7',
        justifyContent: 'center',
        alignItems: 'center'
    },
    savingHintText: {
        fontSize: Dimens.textS,
        color: Colors.textWDark,
    },
    headerLayout: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        borderTopLeftRadius: `${Dimens.padConfigLayoutCorner}px`,
        borderTopRightRadius: `${Dimens.padConfigLayoutCorner}px`,
        alignItems: 'center',
        justifyContent: 'center',
    },
    deleteIcon: {
        width: `${Dimens.padConfigIcon}px`,
        height: `${Dimens.padConfigIcon}px`,
        color: Colors.iconDefaultW,
    },
    deleteIconBtn: {
        position: 'absolute',
        top: '50%',
        right: `${Dimens.marginM}px`,
        transform: 'translate(0%, -50%)',
    },
    contentLayout: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        padding: `0px ${Dimens.marginM}px`,
    },
    title: {
        color: Colors.textWNormal,
    },
    title2: {
        marginTop: `${Dimens.marginM}px`,
        marginBottom: `${Dimens.marginS}px`,
        color: Colors.textBDark,
    },
    subTitle: {
        color: Colors.textBLight,
    },
    residentStatus: {
        marginTop: `${Dimens.marginS}px`,
    },
    padAlertSelectorLayout: {
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: `${Dimens.marginS}px`,
    },
    padAlertSelector: {
        marginLeft: `${Dimens.marginL}px`,
    },
    activityAlertSliderLayout: {
        flex: 1,
        marginLeft: `${Dimens.marginL}px`,
    },
    activityAlertSlider: {
        marginTop: `${Dimens.marginS}px`,
        alignItems: 'center',
        padding: `0px ${Dimens.marginS}px`,
    },
    activityAlertTimeLayout: {
        marginTop: `${Dimens.marginS}px`,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    alarmIcon: {
        width: `${Dimens.padConfigIcon}px`,
        height: `${Dimens.padConfigIcon}px`,
        color: Colors.iconDefault,
    },
    titleText: {
        fontSize: Dimens.textM,
        fontWeight: 'bold',
    },
    subTitleText: {
        fontSize: Dimens.textS,
        fontWeight: 'bold',
    },
    numContentText: {
        fontSize: Dimens.textXS,
        color: Colors.textBLight,
    },
    numText: {
        fontSize: Dimens.textM,
        fontWeight: 'bold',
        color: Colors.textBDark,
    },
    numContentTextL: {
        fontSize: Dimens.textS,
        color: Colors.textBLight,
    },
    numTextL: {
        fontSize: Dimens.textL,
        fontWeight: 'bold',
        color: Colors.textBDark,
    }
});

const RoomTextField = styled.input`
    /* Remove First */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);

    box-sizing: border-box;
    height: 35px;
    width: 100%;
    font-size: ${Dimens.textS};
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    border-color: ${Colors.dividerNormal};
    outline: none;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: ${Dimens.marginS}px;
`;

const CustomSlider = styled(({ ...otherProps }) => (
    <Slider {...otherProps} classes={{ root: 'root', rail: 'rail', track: 'track', thumb: 'thumb', disabled: 'disabled' }} />
))`
    &.disabled {
        & .thumb {
            height: 15px;
            width: 15px;
            background-color: #909090;
            margin-top: -4.5px;
            margin-left: -7.5px;
        }
    }
    & .rail {
        height: 6px;
        border-radius: 3px;
        background-color: #cccccc;
        outline: none;
    }
    & .track {
        height: 6px;
        border-radius: 3px;
    }
    & .thumb {
        height: 15px;
        width: 15px;
        background-color: #E87974;
        margin-top: -4.5px;
        margin-left: -7.5px;
    }
    & .thumb:hover {
        box-shadow: inherit;
    }
    & .thumb:active {
        box-shadow: inherit;
    }
    & .thumb.focus {
        box-shadow: inherit;
    }
    &.root {
        color: #EFBEBD;
        height: 6px;
    }
    & .MuiSlider-thumb.MuiSlider-active {
        box-shadow: 0px 0px 0px #00000000;
    }
    outline: none;
`;

const SvgIconButton = styled(IconButton)`
    && {
        align-self: center;
        padding: 3px;
    }
    &:focus {
        outline: none;
    }
`;