import * as Rx from 'rxjs';
import { Auth, API } from 'aws-amplify';

import * as ApiGateway from './../api_gateway';

import Pad from '../../data/pad';
import { NotificationData } from '../../data/pad_notification';
import moment from 'moment';

import intl from 'react-intl-universal'
import * as Colors from '../../../styles/colors';

export function padsInfo() {
    return Rx.Observable.create(async subscriber => {
        try {
            const params = {
                body: { 'userId': 'user' },
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            const items = await API.get(ApiGateway.HOST, ApiGateway.DATA_PADS, params);
            console.log(items);
            let pads = [];
            for (const key in items.pads) {
                const item = items.pads[key];
                console.log(item);
                const pad = new Pad(item.deviceId, item.firmwareVer ? item.firmwareVer : 0, item.online, [], item.event, item.activity, item.activity_1min ? parseInt((item.activity_1min / 60 * 100)) : 0, item.battery, item.miTemp === null ? undefined : item.miTemp)
                if (item.sensors.length >= 31) {
                    pad.setSensorsBase64(item.sensors)
                } else {
                    pad.setSensorsRaw(item.sensors)
                }
                console.log("Pad Struct", pad)
                pads.push(pad);
            }
            subscriber.next(pads);
            subscriber.complete();
        } catch (e) {
            subscriber.error(e);
        }
    });
}

export function notifications() {
    return Rx.Observable.create(async subscriber => {
        try {
            const params = {
                body: { 'userId': 'user' },
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            const items = await API.get(ApiGateway.HOST, ApiGateway.DATA_NOTIFICATIONS, params);
            let notifications = [];
            for (const index in items) {
                const item = items[index];
                notifications.push(new NotificationData(item.deviceId, item.name, item.type, item.event, item.epoch, item.epoch));
            }
            subscriber.next(notifications);
            subscriber.complete();
        } catch (e) {
            subscriber.error(e);
        }
    });
}

export function notificationsRecord() {
    return Rx.Observable.create(async subscriber => {
        try {
            const params = {
                body: { 'userId': 'user' },
                headers: {
                    'Content-Type': 'application/json',
                }
            };
            const items = await API.get(ApiGateway.HOST, ApiGateway.DATA_NOTIFICATIONS_RECORD, params);
            let notifications = [];
            for (const index in items) {
                const item = items[index];
                notifications.push(new NotificationData(item.deviceId, item.name, item.type, item.event, item.epoch, item.epoch));
            }
            notifications.sort(function (a, b) {
                return a.epoch - b.epoch
            })
            subscriber.next(notifications);
            subscriber.complete();
        } catch (e) {
            subscriber.error(e);
        }
    })
}

export function notificationsHistory(deviceId, date) {
    return Rx.Observable.create(async subscriber => {
        try {//單床單日
            let params = {
                headers: { 'Content-Type': 'application/json', },
                response: false,
                queryStringParameters: {
                    userId: 'user',
                    deviceId: deviceId,
                    date: date,
                    timezone: (0 - (new Date()).getTimezoneOffset()) / 60,
                }
            }
            const data = await API.get(ApiGateway.HOST, ApiGateway.DATA_NOTIFICATIONS_HISTORY, params)
            const localEpoch = (new Date()).getTime()
            const notifications = data.map(function (item, index) {
                return new NotificationData(item.deviceId, item.name, item.type, item.event, item.epoch, localEpoch)
            })
            subscriber.next(notifications)
            subscriber.complete()
        } catch (e) {
            subscriber.error(e)
        }
    })
}

export function postureHistory(deviceId, date) {
    return Rx.Observable.create(async subscriber => {
        try {
            const params = {
                body: { 'index': deviceId, 'date': date },
                headers: {
                    'Content-Type': 'application/json',
                }
            };
            const items = await API.post(ApiGateway.HOST, ApiGateway.HISTORY_DATA, params);
            let durations = [];
            let datas = [];
            let weights = [];
            let colors = [];
            let labels = [];
            for (const key in items) {
                datas.push(items[key].datas);
                weights.push(items[key].weights)
                colors.push(items[key].colors)
                let label = {
                    from: items[key].labels.from,
                    to: items[key].labels.to,
                };
                if (items[key].labels.state === 0) {
                    label['state'] = intl.get('page_history_chart_posture_message_offline');

                } else if (items[key].labels.state === 1) {
                    label['state'] = intl.get('page_history_chart_posture_message_off_bed');

                } else {
                    label['state'] = intl.get('page_history_chart_posture_message_on_bed');

                }
                labels.push(label)
            }

            console.log(datas);
            console.log(colors);
            console.log(weights);
            console.log(labels);
            console.log(deviceId)
            subscriber.next({
                graphicData: { data: datas, color: colors, weight: weights, label: labels },
                percentagesData: {
                    day: {
                        offline: 0,
                        offBed: 0,
                        inBed: 0,
                    },
                    night: {
                        offline: 0,
                        offBed: 0,
                        inBed: 0,
                    },
                    all: {
                        offline: 0,
                        offBed: 0,
                        inBed: 0,
                    }
                }
            });
            subscriber.complete();
        } catch (e) {
            subscriber.error(e);
        }
    });
}

export const weekPostureHistory = (deviceId, date) => {
    return Rx.Observable.create(async subscriber => {
        try {
            let params = {
                headers: { 'Content-Type': 'application/json', },
                response: false,
                body: {
                    userId: 'user',
                    deviceId: deviceId,
                    date: date,
                    timezone: (0 - (new Date()).getTimezoneOffset()) / 60,
                }
            }
            const data = await API.post(ApiGateway.HOST, ApiGateway.DATA_PADS_WEEK_STAGE_HISTORY, params);
            // const data = {
            //     "range": {
            //         "from": 1618185600000,
            //         "day": 14
            //     },
            //     "weekStage": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 8, 13, 14, 13, 14, 14, 14, 14, 13, 13, 13, 12, 9, 9, 5, 3, 1, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 2, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 6, 9, 8, 11, 14, 14, 14, 14, 13, 14, 14, 14, 14, 13, 14, 14, 13, 14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 13, 14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 10, 8, 7, 5, 4, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            //     "statistic": {
            //         "day": { "hour": 2, "min": 24 },
            //         "night": { "hour": 9, "min": 3 },
            //         "all": { "hour": 11, "min": 26 }
            //     },
            //     "correlation": "0.944",
            //     "display": true
            // };

            let datas = [];
            let colors = [];
            let weights = [];
            let labels = [];
            let points = 0;
            let checkSum = 0;
            const days = data.range.day;
            for (let i = 0; i < 144; i++) {
                const weekStage = data.weekStage[i];
                const average = ((weekStage / days) * 100).toFixed(1);
                weights.push(1);
                datas.push(average);
                colors.push(postureChartColor(average));
                // 生活指標分數計算
                if (average >= 70 || average < 30) {
                    points++;
                }
                checkSum += parseInt(average);
                // colors.push(average <= 50 ? Colors.historyPostureLeave : Colors.historyPostureLie);
                labels.push({
                    from: (480 + i * 10) % 1440,
                    to: (480 + (i + 1) * 10) % 1440,
                    weekStage: average,
                });
            }
            // at least 1/4
            points = checkSum > 3600 ? Math.round((points / 144) * 100) : 0;
            const displayChart = data.display === undefined ? false : data.display;
            subscriber.next({ data: datas, color: colors, weight: weights, label: labels, displayChart: displayChart, correlation: data.correlation, statistic: data.statistic, range: getRange(data.range), points: points });
            subscriber.complete();
        } catch (e) {
            subscriber.error(e);
        }
    });
}
const postureChartColor = (average) => {
    if (average >= 70) {
        return Colors.historyWeekHigh;
    } else if (average < 30) {
        return Colors.historyWeekLow;
    } else {
        return Colors.historyWeekMid;
    }
}
const getRange = (range) => {
    const day = range.day - 1;
    const start = range.from;
    const end = start + (day * 24 * 3600 * 1000);
    return {
        from: moment(start).format('YYYY/MM/DD'),
        to: moment(end).format('YYYY/MM/DD')
    }
}

export function activityHistory(deviceId, date) {
    return Rx.Observable.create(async subscriber => {
        try {
            const params = {
                body: { 'index': deviceId, 'date': date },
                headers: {
                    'Content-Type': 'application/json',
                }
            };
            const items = await API.post(ApiGateway.HOST, ApiGateway.HISTORY_ACT, params);
            let datas = [];
            let colors = [];
            let weights = [];
            let labels = [];
            for (let i = 0; i < 144; i++) {
                weights.push(1);
                datas.push(items[i].datas);
                colors.push(i % 2 === 0 ? Colors.historyActivityLight : Colors.historyActivityDark);
                labels.push({
                    from: (480 + i * 10) % 1440,
                    to: (480 + (i + 1) * 10) % 1440,
                    activity: items[i].datas,
                });
            }

            subscriber.next({ data: datas, color: colors, weight: weights, label: labels });
            subscriber.complete();
        } catch (e) {
            subscriber.error(e);
        }
    });
}

export function sleepHistory(deviceId, date) {
    return Rx.Observable.create(async subscriber => {
        try {
            await new Promise(function (resolve, reject) {
                setTimeout(function () {
                    resolve();
                }, 1000);
            });
            let params = {
                headers: { 'Content-Type': 'application/json' },
                response: false,
                body: {
                    userId: 'user',
                    deviceId: deviceId,
                    date: date,
                    timezone: (0 - (new Date()).getTimezoneOffset()) / 60,
                }
            }
            console.log('debug sleep')
            const data = await API.post(ApiGateway.HOST, ApiGateway.DATA_PADS_SLEEP_HISTORY, params);
            // const data = {
            //     "stages": [
            //         { "sleep": -1, "second": 480, "duration": 3 },
            //         { "sleep": 2, "second": 483, "duration": 221 },
            //         { "sleep": 1, "second": 704, "duration": 119 },
            //         { "sleep": 2, "second": 823, "duration": 376 },
            //         { "sleep": 0, "second": 1199, "duration": 11 },
            //         { "sleep": 1, "second": 1210, "duration": 589 },
            //         { "sleep": 2, "second": 359, "duration": 121 }],
            //     "percentages": {
            //         "day": 119, "night": 589, "all": 708
            //     }
            // }
            const sleepData = data.stages;
            if (isToday(date)) {
                const d = new Date()
                sleepData.push({
                    second: d.getHours() * 60 + d.getMinutes(),
                    sleep: -1
                })
            }

            let durations = [];
            let datas = [];
            let weights = [];
            let colors = [];
            let labels = [];
            for (let i = 0; i < sleepData.length; i++) {
                const sd = sleepData[i];
                let b = (i + 1) < sleepData.length ? sleepData[i + 1].second : 480;
                let a = sd.second;
                let duration = b - a > 0 ? b - a : 1440 - a + b;
                let label = {
                    from: a,
                    to: b,
                };
                durations.push(duration);
                weights.push(duration / 1440);

                if (sd.sleep === -1) {
                    label['state'] = intl.get('page_history_chart_sleep_legend_awake');
                    colors.push(Colors.historySleepAwake);
                    datas.push(0);
                }
                else if (sd.sleep === 0) {
                    label['state'] = intl.get('page_history_chart_sleep_legend_awake');
                    colors.push(Colors.historySleepAwake);
                    datas.push(1.5);
                }
                else if (sd.sleep === 1) {
                    label['state'] = intl.get('page_history_chart_sleep_legend_light');
                    colors.push(Colors.historySleepLight);
                    datas.push(3);
                }
                else if (sd.sleep === 2) {
                    label['state'] = intl.get('page_history_chart_sleep_legend_none')
                    colors.push(Colors.historySleepLeave);
                    datas.push(0.9);
                }
                else {
                    label['state'] = intl.get('page_history_chart_sleep_legend_deep');
                    colors.push(Colors.historySleepDeep);
                    datas.push(3);
                }
                labels.push(label);
            }
            subscriber.next({
                graphicData: { data: datas, color: colors, weight: weights, label: labels },
                percentagesData: data.percentages
            });
            subscriber.complete();

        } catch (e) {
            subscriber.error(e);
        }
    });
}

export function isToday(dateStr) {
    const d = new Date()
    let today = d
    if (d.getHours() >= 8) {
        today = d
    } else {
        const newD = moment(d).add(-1, 'days')
        today = newD.toDate()
    }
    return dateStr === moment(today).format('YYYY/MM/DD')
}