import React from 'react';

const MainContext = React.createContext({
    bloc: undefined,
})

const ManagementContext = React.createContext({
    bloc: undefined,
});

const HomeContext = React.createContext({
    bloc: undefined,
});

export { MainContext, ManagementContext, HomeContext };