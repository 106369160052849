import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import intl from 'react-intl-universal'

import * as Dimens from '../styles/dimens';
import * as Colors from '../styles/colors';
import Column from '../components/layout/column';
import Row from '../components/layout/row';

import { Dialog, ButtonBase, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

DatePickerDialog.propTypes = {
    onDateSelected: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    date: PropTypes.any,
}

DatePickerDialog.defaultProps = {
    open: false,
    date: (new Date()),
}

export default function DatePickerDialog(props) {

    const css = useStyles(props);
    const [date, setDate] = useState(props.date);

    const onDateChanged = date => {
        setDate(date);
    };

    return (
        <Dialog onClose={props.onClose} open={props.open} >
            <MuiThemeProvider theme={DatePickerTheme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Column width='auto' height='auto' onTouchMove={(event) => { event.preventDefault(); }}>
                        <DatePicker
                            disableFuture
                            autoOk
                            orientation="landscape"
                            variant="static"
                            openTo="date"
                            value={date}
                            onChange={onDateChanged} />
                        <div className={css.divider} />
                        <ButtonBase className={css.btnConfirmRoot}
                            onClick={() => {
                                if (props.onDateSelected !== undefined)
                                    props.onDateSelected(date);
                                props.onClose();
                            }} >
                            <Row width='100%' height='100%' className={css.btnConfirmLayout} >
                                <span className={css.btnConfirmText} >{intl.get('confirm')}</span>
                            </Row>
                        </ButtonBase>
                    </Column>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        </Dialog>
    );
}

const useStyles = makeStyles({
    divider: {
        width: '100%',
        height: `${Dimens.divider}px`,
        backgroundColor: Colors.dividerNormal,
    },
    btnConfirmRoot: {
        width: '100%',
        height: `${Dimens.datePickerDialogFooter}px`,
    },
    btnConfirmLayout: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    btnConfirmText: {
        fontWeight: 'bold',
        fontSize: Dimens.textM,
        color: Colors.primary,
    }
});

export const DatePickerTheme = createMuiTheme({
    palette: {
        primary: {
            main: Colors.primary,
        },
        secondary: {
            main: Colors.accent,
        },
    },
})