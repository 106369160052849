import * as Rx from 'rxjs'

export default class HomeBloc {

    get gridViewSize() { return this._gridViewSize; }

    get notiHistoryClicked() { return this._notiHistoryClicked }
    get notiHistoryDialog() { return this._notiHistoryDialog }
    get notiHistoryDate() { return this._notiHistoryDate }

    get datePickerDialog() { return this._datePickerDialog }

    init() {
        this._disposables = new Rx.Subscription();

        this._gridViewSize = new Rx.Subject();

        this._notiHistoryClicked = new Rx.Subject()
        this._notiHistoryDialog = new Rx.BehaviorSubject({ show: false, config: undefined })
        this._notiHistoryDate = new Rx.BehaviorSubject(new Date())

        this._datePickerDialog = new Rx.BehaviorSubject({ show: false, date: new Date() })

        this._setNotiHistoryClickedObserver()
    }

    dispose() {
        this._disposables.unsubscribe();
    }

    _setNotiHistoryClickedObserver() {
        let it = this._notiHistoryClicked.subscribe({
            next: (config) => {
                console.log(`noti history clicked: ${config}`)
                this._notiHistoryDate.next(new Date())
                this._notiHistoryDialog.next({ show: true, config: config })
            },
            error: err => { },
            complete: () => { }
        })
        this._disposables.add(it)
    }
}