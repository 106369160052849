import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AutoSizer from 'react-virtualized-auto-sizer';
import intl from 'react-intl-universal'
import * as Rx from 'rxjs';

import SignInBloc from '../../bloc/login/sign_in_bloc';

import * as Dimens from '../../styles/dimens';
import * as Colors from '../../styles/colors';
import Stack from '../../components/layout/stack';
import Column from '../../components/layout/column';
import BlocBuilder from 'bloc-builder-react';
import TextField from '../../components/widgets/text_field';
import ButtonRound from '../../components/widgets/btn_round';
import ButtonText from '../../components/widgets/btn_text';
import LoadingDialog from '../../dialog/loading_dialog';
import ImgWhizPad from '../../assets/img_whizpad.svg';
import ImgAstec from '../../assets/img_astec.svg';
import IcAccount from '../../assets/ic_account.svg';
import IcPwd from '../../assets/ic_pwd.svg';

var Lodash = require('lodash');

const _bloc = new SignInBloc();

export default function SignInPage(props) {
    const ID_TF_ACCOUNT = 0, ID_TF_PWD = 1, ID_BTN_LOGIN = 2, ID_BTN_FORGOT_PWD = 3;

    const _isWeb = useMediaQuery('(min-width: 768px)');
    const _disposables = new Rx.Subscription();

    const [init, setInit] = useState(false);
    const [loadingDialog, setLoadingDialog] = useState({
        show: false,
        text: intl.get('loading_login'),
    });

    useEffect(() => {
        console.log(`SignInPage init...`);
        _bloc.init();
        setLoggedInObserver()
        setSignInResultObserver();
        setLoadingDialogObserver();
        _bloc.isLoggedIn()
        setInit(true);
        return () => {
            console.log(`SignInPage deinit...`);
            _bloc.dispose();
            _disposables.unsubscribe();
            setInit(false);
        };
    }, [])

    const setLoggedInObserver = () => {
        let it = _bloc.loggedIn.subscribe({
            next: loggedIn => {
                if (loggedIn)
                    props.history.replace('/main')
            }
        })
        _disposables.add(it)
    }

    const onTextChanged = (id) => (event) => {
        switch (id) {
            case ID_TF_ACCOUNT:
                _bloc.accountText.next(event.target.value);
                break;
            case ID_TF_PWD:
                _bloc.pwdText.next(event.target.value);
                break;
            default:
                break;
        }
    }

    const onClicked = (id) => () => {
        switch (id) {
            case ID_BTN_LOGIN:
                _bloc.signIn();
                break;
            case ID_BTN_FORGOT_PWD:
                break;
            default:
                break;
        }
    }

    return (() => {
        if (!init) return <Stack width='100%' height='100%' backgroundColor={Colors.primary} ></Stack>;
        const w = _isWeb ? 50 : 85
        const l = (100 - w) / 2
        const tfWidthPercent = _isWeb ? 70 : 90
        const tfWidthPercentStr = `${tfWidthPercent}%`
        return (
            <RootLayout>
                <ContentLayout width={`${w}%`} height='auto' left={`${l}%`}>
                    {process.env.REACT_APP_LOGO_PATH === 'img_whizpad.svg' ? <TitleImg src={ImgWhizPad} /> : <TitleImg src={ImgAstec} />}
                    {/* <TitleImg src={ImgAstec} /> */}
                    <LoginBox width='100%' height='auto' borderRadius={`${Dimens.loginBoxRadius}px`} borderWidth={`${Dimens.dividerL}px`} borderColor={Colors.dividerNormal}>
                        <TitleText>{intl.get('page_signin_title')}</TitleText>
                        <BlocBuilder
                            initialValue={_bloc.accountAnim.getValue()}
                            subject={_bloc.accountAnim}
                            builder={(snapshot) => {
                                return (
                                    <AccountTextField
                                        width={tfWidthPercentStr}
                                        leadingicon={IcAccount}
                                        inputtype='email'
                                        animstate={snapshot.data}
                                        onChange={onTextChanged(ID_TF_ACCOUNT)} />
                                );
                            }} />
                        <BlocBuilder
                            initialValue={_bloc.pwdAnim.getValue()}
                            subject={_bloc.pwdAnim}
                            builder={(snapshot) => {
                                return (
                                    <PwdTextField
                                        width={tfWidthPercentStr}
                                        leadingicon={IcPwd}
                                        inputtype='password'
                                        animstate={snapshot.data}
                                        onChange={onTextChanged(ID_TF_PWD)} />
                                );
                            }} />
                        <BlocBuilder
                            initialValue={_bloc.errorText.getValue()}
                            subject={_bloc.errorText}
                            builder={(snapshot) => {
                                return (
                                    <ErrorText>{snapshot.data}</ErrorText>
                                );
                            }} />
                        <BlocBuilder
                            initialValue={_bloc.loginAvailable.getValue()}
                            subject={_bloc.loginAvailable}
                            builder={(snapshot) => {
                                return (
                                    <ButtonRound
                                        onClick={onClicked(ID_BTN_LOGIN)}
                                        enable={snapshot.data}
                                        width={tfWidthPercentStr}
                                        btncolor='#7C8589'
                                        style={{ alignSelf: 'center', marginTop: '20px' }}>
                                        {intl.get('login')}
                                    </ButtonRound>
                                );
                            }} />
                        {/* <ForgotPwdBtn
                            onClick={onClicked(ID_BTN_FORGOT_PWD)}
                            width='auto'
                            textcolor='#ffffff' text='忘記密碼？'
                            fontsize={Dimens.textS} style={{ marginRight: `${(100 - tfWidthPercent) / 2}%` }} /> */}
                    </LoginBox>
                </ContentLayout>
                <LoadingDialog onClose={() => { }} open={loadingDialog.show} text={intl.get('loading_login')} />
            </RootLayout>
        );
    })();

    function setLoadingDialogObserver() {
        let it = _bloc.loadingDialog
            .subscribe({
                next: (data) => {
                    setLoadingDialog(Lodash.cloneDeep(data));
                }
            });
        _disposables.add(it);
    }

    function setSignInResultObserver() {
        let it = _bloc.signInResult
            .subscribe({
                next: ({ result, code }) => {
                    console.log(`sign in result => result: ${result}, code: ${code}`)
                    if (result) {
                        props.history.replace('/main/home')
                    } else {
                        if (code === 'NEW_PASSWORD_REQUIRED') {
                            props.history.replace('/login/change_pwd', { email: _bloc.accountText.getValue() })
                        }
                    }
                }
            });
        _disposables.add(it);
    }
}

const RootLayout = styled(Stack).attrs(props => ({
    width: '100%',
    height: '100%',
    backgroundColor: Colors.primary,
}))`
    overflow-y: scroll;
`;

const ContentLayout = styled(Column)`
    position: absolute;
    left: ${props => props.left || '0%'};
    top: 10%;
`;

const TitleImg = styled.img`
    height: 50px;
    width: 85%;
    align-self: center;
    @media (min-width: 768px) {
        height: 60px;
    }
    @media (min-width: 1200px) {
        height: 80px;
    }
`;

const LoginBox = styled(Column)`
    margin-top: 3vh;
    padding-top: ${Dimens.marginL}px;
    padding-bottom: ${Dimens.marginL}px;
`;

const TitleText = styled.span`
    align-self: center;
    font-size: ${Dimens.textM};
    font-weight: bold;
    color: #fff;
`;

const AccountTextField = styled(TextField)`
    align-self: center;
    margin-top: ${Dimens.marginL}px;
`;

const PwdTextField = styled(TextField)`
    align-self: center;
    margin-top: ${Dimens.marginM}px;
`;

const ErrorText = styled.span`
    align-self: center;
    margin-top: ${Dimens.marginM}px;
    font-size: ${Dimens.textS};
    color: ${Colors.accent};
`;

const ForgotPwdBtn = styled(ButtonText)`
    align-self: flex-end;
    margin-top: ${Dimens.marginM}px;
`;