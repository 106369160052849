import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles'
import intl from 'react-intl-universal'
import AutoSizer from 'react-virtualized-auto-sizer';
import printf from 'printf';

import * as Rx from 'rxjs';

import * as Dimens from '../../styles/dimens';
import * as Colors from '../../styles/colors';
import Stack from '../layout/stack';
import Column from '../layout/column';
import Row from '../layout/row';

import BlocBuilder from 'bloc-builder-react';

NotificationBarItem.propTypes = {
    datasize: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired,
}

export default function NotificationBarItem(props) {

    const classes = useStyles(props);
    const _disposables = useRef(new Rx.Subscription());
    const timer = Rx.interval(5000)

    const [blinkAnim, setBlinkAnim] = useState(false);

    useEffect(() => {
        const disposables = _disposables.current;
        console.log(`add notification name: ${props.data.name}, event: ${props.data.notification.name}, epoch: ${props.data.epoch}, localEpoch: ${props.data.localEpoch}`);
        setBlinkAnim(false);
        const timeout = setTimeout(() => {
            setBlinkAnim(true);
        }, 500);
        return () => {
            clearTimeout(timeout)
            disposables.unsubscribe();
        }
    }, [props.data]);

    return (
        <AutoSizer>
            {({ width, height }) => {
                const now = (new Date()).getTime();
                const isBlink = blinkAnim && (Math.floor((now - props.data.localEpoch) / 1000) < 6);
                if (width > 300) {
                    // isLast={(props.datasize - 1) === props.index
                    return (
                        <RootLayout style={props.style} >
                            <ContentLayout w={width} h={props.style.height} >
                                <Row width='100%' height='100%' style={{ alignItems: 'center' }} >
                                    <Indicator color={props.data.notification.color} />
                                    <RoomName style={{ width: '40%' }}>
                                        {`${props.data.name}`}
                                    </RoomName>
                                    <EventName style={{ flex: '1' }} align='center'
                                        className={`${isBlink ? classes.blink : ''}`}
                                        color={props.data.notification.color} >
                                        {`${props.data.notification.name}${intl.get('notification_bar_format_alert')}`}
                                    </EventName>
                                    <BlocBuilder
                                        initValue={0}
                                        subject={timer}
                                        builder={(snapshot) => {
                                            const duration = Math.floor(((new Date()).getTime() - props.data.localEpoch) / 1000)
                                            const sec = duration % 60
                                            const min = Math.floor(duration / 60)
                                            let str = ''
                                            if (min === 0) {
                                                str = `${sec} ${intl.get('before_second')}`
                                            } else {
                                                str = `${min} ${intl.get('before_minute')}`
                                            }
                                            console.log(`now: ${(new Date()).getTime()}, epoch: ${props.data.localEpoch}`)
                                            console.log(`min: ${min}, sec: ${sec}`)
                                            return (
                                                <DateTime style={{ marginRight: `${Dimens.marginS}px` }} >
                                                    {(() => {
                                                        return str
                                                        // const date = new Date(props.data.localEpoch);
                                                        // return printf('%02d:%02d:%02d', date.getHours(), date.getMinutes(), date.getSeconds());
                                                    })()}
                                                </DateTime>
                                            )
                                        }} />
                                </Row>
                            </ContentLayout>
                        </RootLayout>
                    );
                } else {
                    return (
                        <RootLayout style={props.style} >
                            <ContentLayout w={width} h={props.style.height} >
                                <Row width='100%' height='auto' style={{ flex: '1', alignItems: 'center', paddingRight: `${Dimens.marginS}px` }} >
                                    <Indicator color={props.data.notification.color} />
                                    <RoomName style={{ flex: '1', marginRight: '15px' }} >
                                        {`${props.data.name}`}
                                    </RoomName>
                                    <EventName align='right' color={props.data.notification.color}
                                        style={{ marginRight: `${Dimens.marginS}px` }}
                                        className={`${isBlink ? classes.blink : ''}`} >
                                        {`${props.data.notification.name}${intl.get('notification_bar_format_alert')}`}
                                    </EventName>
                                </Row>
                                <Row reverse width='100%' height='auto' style={{ flex: '1', alignItems: 'center', paddingRight: `${Dimens.marginS}px` }} >
                                    <BlocBuilder
                                        initialValue={0}
                                        subject={timer}
                                        builder={(snapshot) => {
                                            const duration = Math.floor(((new Date()).getTime() - props.data.localEpoch) / 1000)
                                            const sec = duration % 60
                                            const min = Math.floor(duration / 60)
                                            let str = ''
                                            if (min === 0) {
                                                str = `${sec} ${intl.get('before_second')}`
                                            } else {
                                                str = `${min} ${intl.get('before_minute')}`
                                            }
                                            return (
                                                <DateTime style={{ marginRight: `${Dimens.marginS}px` }} >
                                                    {(() => {
                                                        return str
                                                        // const date = new Date(props.data.localEpoch);
                                                        // return printf('%02d:%02d:%02d', date.getHours(), date.getMinutes(), date.getSeconds());
                                                    })()}
                                                </DateTime>
                                            )
                                        }} />
                                </Row>
                            </ContentLayout>
                        </RootLayout>
                    );
                }
            }}
        </AutoSizer>
    );
}

const useStyles = makeStyles({
    '@keyframes blink-animation': {
        '0%': { opacity: 0, },
        '50%': { opacity: 0.5, },
        '100%': { opacity: 1, },
    },
    blink: {
        animationName: '$blink-animation',
        animationDuration: '1s',
        animationTimingFunction: 'linear',
        animationIterationCount: '5',
    },
});


const RootLayout = styled(Stack).attrs(props => ({
    width: props.style.width,
    height: `${props.style.height}px`,
    backgroundColor: Colors.notiBarBg,
}))`
`;

const ContentLayout = styled(Column).attrs(props => ({
    width: `${props.w - Dimens.marginS * 2}px`,
    height: `${props.h - Dimens.marginS}px`,
    backgroundColor: '#fff',
}))`
    position: absolute;
    top: ${Dimens.marginS}px;
    left: ${Dimens.marginS}px;
`;

const Indicator = styled.div`
    width: ${Dimens.notiBarIndicatorSize}px;
    height: ${Dimens.notiBarIndicatorSize}px;
    border-radius: ${Dimens.notiBarIndicatorSize / 2}px;
    background-color: ${props => props.color};
    margin-left: ${Dimens.marginM}px;
    margin-right: ${Dimens.marginM}px;
`;

const RoomName = styled.span`
    color: ${Colors.textBDark};
    font-size: ${Dimens.textM};
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const EventName = styled.span`
    text-align: ${props => props.align === undefined ? 'center' : props.align};
    font-size: ${Dimens.textM};
    font-weight: bold;
    color: ${props => props.color};
`;

const DateTime = styled.span`
    flex: 0.4;
    font-size: ${Dimens.textXS};
    color: ${Colors.textBXLight};
    text-align: right;
`;