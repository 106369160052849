import * as Rx from 'rxjs';
import { flatMap } from 'rxjs/operators';
import intl from 'react-intl-universal'
import Speech from 'speak-tts'

import * as LoginApi from '../../model/api/login_api';
import * as PadApi from '../../model/api/pad_api';
import * as SettingApi from '../../model/api/settings_api';

import Amplify, { Auth, PubSub } from 'aws-amplify';
import { MqttOverWSProvider } from '@aws-amplify/pubsub/lib/Providers';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';

import { ArrangeShift } from '../../model/data/arrange_shift';
import { PadNotification, NotificationData } from '../../model/data/pad_notification';

export default class MainBloc {

    get languagesDialog() { return this._languagesDialog }

    get voiceLangDialog() { return this._voiceLangDialog }

    get isSpeachInit() {
        return this._isSpeechInit
    }

    get speechLanguages() { return this._speechLanguages }

    get speechConfig() { return this._speechConfig }

    set speechConfig(val) { this._speechConfig = val }

    get loginLoading() {
        return this._loginLoading;
    }

    get routePage() {
        return this._routePage;
    }

    get padLoading() {
        return this._padLoading;
    }

    get refreshPadsUi() {
        return this._refreshPadsUi;
    }

    get padsData() {
        return this._padsData;
    }

    get padsDataMap() {
        return this._padsDataMap;
    }

    get notificationVolume() {
        return this._notificationVolume
    }

    get notifications() {
        return this._notifications;
    }

    get notificationRecords() {
        return this._notificationRecords;
    }

    init(cookie) {
        console.log('speech cookie', cookie)
        this._disposables = new Rx.Subscription();

        this._isSpeechInit = new Rx.BehaviorSubject(false)
        this._voiceLangDialog = new Rx.BehaviorSubject({ show: false });
        this._speechLanguages = new Rx.BehaviorSubject([]);
        this._speech = new Speech()
        this._speechConfig = { lang: 'zh-TW', name: '' }
        if (this._speech.hasBrowserSupport()) {
            this._speech.init({
                'volume': 1,
                'lang': 'zh-TW',
                'rate': 1.1,
                'pitch': 1,
                'voice': 'Microsoft Hanhan Destop - Chinese (Taiwan)',
                'splitSentences': true,
                'listeners': {
                    'onvoiceschanged': (voices) => {
                        console.log("Speech => all", voices)
                        let availables = []
                        let availableVoices = {}
                        voices.forEach(item => {
                            if (item.lang.includes('en') || item.lang.includes('zh')) {
                                if (process.env.REACT_APP_LOCAL !== "true" || item.localService) {
                                    availableVoices[item.lang] = { voiceUri: item.voiceURI, name: item.name }
                                    availables.push(item);
                                }
                            }
                        });
                        console.log("Speech => available", availables)
                        this._speechLanguages.next(availables);
                        if (cookie.hasOwnProperty('voice_lang')) {
                            this._speechConfig = { lang: cookie.voice_lang.lang, name: cookie.voice_lang.name };
                        } else {
                            if (availableVoices['zh-CN']) {
                                console.log(`tts use zh-CN: ${JSON.stringify(availableVoices['zh-CN'])}`)
                                this._speechConfig = { lang: 'zh-CN', name: availableVoices['zh-CN'].name }
                            } else if (availableVoices['zh-TW']) {
                                console.log(`tts use zh-TW: ${JSON.stringify(availableVoices['zh-TW'])}`)
                                this._speechConfig = { lang: 'zh-TW', name: availableVoices['zh-TW'].name }
                            } else {
                                console.log(`tts not support`)
                            }
                        }
                    }
                }
            }).then((date) => {
                this._isSpeechInit.next(true)
            }).catch(e => {
                this._isSpeechInit.next(false)
            })
        }

        if (process.env.REACT_APP_LOCAL === 'true') {
            console.log(`host name: ${window.location.hostname}`)
        }
        this._iotProvider = process.env.REACT_APP_LOCAL === "true" ? new MqttOverWSProvider({
            aws_pubsub_endpoint: 'ws://' + window.location.hostname + ':' + process.env.REACT_APP_MQTT_PORT + '/mqtt',
            // aws_pubsub_endpoint: 'ws://' + '220.130.136.124' + ':' + process.env.REACT_APP_MQTT_PORT + '/mqtt',
            aws_appsync_dangerously_connect_to_http_endpoint_for_testing: true,
        }) : new AWSIoTProvider({
            aws_pubsub_region: 'us-west-2',
            aws_pubsub_endpoint: 'wss://anf0kpqd6m9yz-ats.iot.us-west-2.amazonaws.com/mqtt',
        });
        Amplify.addPluggable(this._iotProvider)
        this._internetConnection = new Rx.BehaviorSubject(navigator.onLine)
        this._mqttSubscribedTopics = []
        this._mqttConnection = false

        // Dialogs
        this._languagesDialog = new Rx.BehaviorSubject({ show: false })

        this._loginLoading = new Rx.BehaviorSubject(false)
        this._routePage = new Rx.Subject()

        this._padLoading = new Rx.BehaviorSubject(false)
        this._refreshPadsUi = new Rx.Subject()

        this._padsData = { pads: [], configs: [], arrangeShift: new ArrangeShift(420, 900, 1380), }
        this._padsDataMap = {}

        this._notificationVolume = new Rx.BehaviorSubject(true)
        this._notifications = new Rx.BehaviorSubject([])
        this._notificationRecords = new Rx.BehaviorSubject([])
        this._setRefreshUiTimer(true)
        this._internetConnectionCallback = this._internetConnectionCallback.bind(this)
        window.addEventListener('online', this._internetConnectionCallback)
        window.addEventListener('offline', this._internetConnectionCallback)
    }

    dispose() {
        console.log('main bloc dispose');
        window.removeEventListener('online', this._internetConnectionCallback);
        window.removeEventListener('offline', this._internetConnectionCallback);
        this._setRefreshUiTimer(false);
        this._setRefreshDataTimer(false);
        this._refreshPads(false);
        this._unsubscribeAllPads();
        this._disposables.unsubscribe();
    }

    _internetConnectionCallback() {
        const isOnline = navigator.onLine;
        console.log(`internet connection changed => is online: ${isOnline}`);
        this._internetConnection.next(isOnline);
    }

    isLoggedIn() {
        this.loginLoading.next(true);
        let it = LoginApi.isLoggedIn().subscribe({
            next: loggedIn => {
                if (!loggedIn) {
                    console.log(`is logged in: false`);
                    this._routePage.next('/login');
                } else {
                    console.log(`is logged in: true`);
                    if (process.env.REACT_APP_LOCAL === 'true') {
                        this.getPads();
                        this._getNotifications();
                    } else {
                        this._refreshSession();
                    }
                }
            },
            error: err => {
                console.log('is logged in with error: ');
                console.log(err);
                this.loginLoading.next(false);
            },
            complete: () => {
                console.log('is logged in complete');
                this.loginLoading.next(false);
            }
        });
        this._disposables.add(it);
    }

    logout() {
        let it = LoginApi.signOut().subscribe({
            next: result => {
                this._routePage.next('/login');
            }
        });
        this._disposables.add(it);
    }

    _refreshSession() {
        let it = LoginApi.refreshSession()
            .pipe(
                flatMap(session => {
                    return LoginApi.currentCredentials();
                }),
                flatMap(credentials => {
                    return LoginApi.attachAwsIotPolicy(credentials.identityId);
                })
            )
            .subscribe({
                next: response => {
                },
                error: err => {
                    console.log('refresh session error:');
                    console.log(err);
                },
                complete: () => {
                    console.log('refresh session success')
                    this.getPads();
                    this._getNotifications();
                }
            });
        this._disposables.add(it);
    }

    _setRefreshUiTimer(enable) {
        if (!enable) {
            if (this._refreshUiTimer !== undefined)
                this._refreshUiTimer.unsubscribe();
            return;
        }
        this._refreshUiTimer = Rx.interval(1500)
            .subscribe({
                next: count => {
                    this._refreshPadsUi.next(true);
                }
            });
    }

    _setRefreshDataTimer(enable) {
        if (!enable) {
            if (this._refreshDataTimer !== undefined)
                this._refreshDataTimer.unsubscribe();
            return;
        }
        this._refreshDataTimer = Rx.interval(60000)
            .subscribe({
                next: count => {
                    this._refreshPads(true)
                    this._cleanNotification()
                    this._clearNotificationsRecord()
                }
            });
    }

    _refreshPads(enable) {
        if (this._refreshPadsDisposables !== undefined)
            this._refreshPadsDisposables.unsubscribe();
        if (!enable) return;
        this._refreshPadsDisposables = Rx.zip(
            PadApi.padsInfo(),
            SettingApi.getConfigs(),
            SettingApi.getArrangeShift(),
        ).subscribe({
            next: val => {
                this._padsData = { pads: val[0], configs: val[1], arrangeShift: val[2] };
                for (let i = 0; i < this._padsData.pads.length; i++) {
                    const pad = this._padsData.pads[i];
                    this._padsDataMap[pad.deviceId] = pad;
                }
                this._refreshPadsUi.next(true);
                this._subscribeAllPads();
                console.log(`pads data refresh success`);
            },
            error: err => {
            },
            complete: () => {
            }
        });
    }

    getPads() {
        if (this._padLoading.getValue()) return;
        this._padLoading.next(true);
        this._setRefreshDataTimer(false);
        this._refreshPads(false);
        this._padsData = {
            pads: [], configs: [], arrangeShift: new ArrangeShift(420, 900, 1380),
        };
        this._unsubscribeAllPads();
        this._refreshPadsUi.next(true);
        let it = Rx.zip(
            PadApi.padsInfo(),
            SettingApi.getConfigs(),
            SettingApi.getArrangeShift(),
        ).subscribe({
            next: val => {
                this._padsData = { pads: val[0], configs: val[1], arrangeShift: val[2] };
                for (let i = 0; i < this._padsData.pads.length; i++) {
                    const pad = this._padsData.pads[i];
                    this._padsDataMap[pad.deviceId] = pad;
                }
                this._refreshPadsUi.next(true);
                this._subscribeAllPads();
                console.log(`pads data get success`);
            },
            error: err => {
                this._setRefreshDataTimer(true);
                this._padLoading.next(false);
            },
            complete: () => {
                this._setRefreshDataTimer(true);
                this._padLoading.next(false);
            }
        });
        this._disposables.add(it);
    }

    _getNotifications() {
        console.log(`get notificaitons`)
        let it = Rx.zip(PadApi.notifications(), PadApi.notificationsRecord())
            .subscribe({
                next: data => {
                    this._notifications.next(data[0])
                    this._notificationRecords.next(data[1])
                    this._notifications.getValue().forEach(element => {
                        console.log(`local epoch: ${element.localEpoch}`)
                    });
                },
                error: err => {
                },
                complete: () => {

                }
            })
        this._disposables.add(it)
    }

    _cleanNotification() {
        const array = this._notifications.getValue()
        const epoch = Math.floor((new Date()).getTime() / 1000)
        console.log(`clear notification epoch: ${epoch}`);
        const newArray = array.filter(function (item, index, arr) {
            if (epoch - Math.floor(item.localEpoch / 1000) > 300) return false;
            return true;
        });
        this._notifications.next(newArray);
    }

    _clearNotificationsRecord() {
        const array = this._notificationRecords.getValue()
        const epoch = Math.floor((new Date()).getTime() / 1000)
        console.log(`clear notifications record epoch: ${epoch}`)
        const newArray = array.filter(function (item, index, arr) {
            if (epoch - Math.floor(item.localEpoch / 1000) > 300) return false
            return true
        });
        this._notificationRecords.next(newArray)
    }

    /* --- AWS IoT --- */

    async _subscribeAllPads() {
        let ts = [];
        for (const index in this._padsData.pads) {
            const deviceId = this._padsData.pads[index].deviceId;
            const topic = `G-Tech/WhizPad/${deviceId}/sensor`;
            ts.push(topic);
        }
        let userId = ''
        if (process.env.REACT_APP_LOCAL !== 'true') {
            const session = await Auth.currentSession();
            userId = session.getIdToken().decodePayload().sub
        } else {
            // todo call get userId api
            userId = await LoginApi.getUserId();
        }
        ts.push(`G-Tech/WhizPad_App/${userId}/notification`);
        if (this._mqttConnection && this._mqttSubscribedTopics.length === ts.length
            && this._mqttSubscribedTopics.sort().every(function (value, index) {
                return value === ts.sort()[index]
            })) {
            return;
        }
        this._mqttSubscribedTopics = ts;
        this._mqttConnection = true;
        console.log(`AWS IoT subscribe all pads`);
        this._pubSubSubscribed = PubSub.subscribe(ts).subscribe({
            next: data => {
                const topic = data.value[Object.getOwnPropertySymbols(data.value)[0]];
                const payload = data.value;

                const type = topic.split('/')[1];
                const action = topic.split('/')[3];
                if (type === 'WhizPad') {
                    if (action === 'sensor') {
                        const deviceId = topic.split('/')[2];
                        const pad = this._padsData.pads.find(function (item, index) {
                            return item.deviceId === deviceId;
                        });
                        pad.eventCode = payload.event;
                        if (payload.sensors.length >= 31) {
                            pad.setSensorsBase64(payload.sensors);
                        } else {
                            pad.setSensorsRaw(payload.sensors);
                        }

                        pad.activity = Math.floor(payload.activity / 600 * 100);
                        // 1分鐘即時活動量
                        pad.activity1Min = Math.floor(payload.activity_1min / 60 * 100);
                        if (pad.activity1Min > 100) {
                            pad.activity1Min = 100;
                        }
                        console.log(`data type: ${payload.dataType}`, pad);
                    }
                } else if (type === 'WhizPad_App') {
                    if (action === 'notification') {
                        console.log('notification', JSON.stringify(payload));
                        // console.log('notification',payload);
                        const data = new NotificationData(payload.deviceId, payload.name, payload.type, payload.event, payload.epoch, (new Date().getTime()));
                        const array = this._notifications.getValue();
                        const savedNoti = array.find(function (item, index) {
                            return item.deviceId === data.deviceId && item.type === data.type && item.notiCode === data.notiCode
                        })
                        if (savedNoti !== undefined && data.localEpoch - savedNoti.localEpoch < 1000) return
                        const newArray = array.filter(function (item, index, arr) {
                            // if (Math.floor(new Date().getTime() / 1000) - Math.floor(item.epoch / 1000) > 300) return false;
                            return !(item.deviceId === data.deviceId && item.type === data.type);
                        });

                        // add records first
                        const records = this._notificationRecords.getValue()
                        records.push(data)
                        this._notificationRecords.next(records)

                        newArray.push(data);
                        this._notifications.next(newArray);

                        if (this._notificationVolume.getValue()) {
                            console.log(`speek alert`)
                            this._speech.setLanguage(this._speechConfig.lang)
                            this._speech.setVoice(this._speechConfig.name)
                            this._speech.speak({
                                text: `${data.name} ${data.notification.sound}${intl.get('alert')}`
                            }).then((data) => {
                            }).catch(e => {
                                console.log(`speak alert with error:`, e)
                            })
                        }
                    }
                }
            },
            error: err => {
                console.log(`AWS IoT error:`);
                console.log(err);
                this._unsubscribeAllPads();
                this._clearMqttConfig();
            }
        });
        console.log(this._pubSubSubscribed);
    }

    _unsubscribeAllPads() {
        if (this._pubSubSubscribed !== undefined) {
            console.log(`AWS IoT unsubscribe all pads`);
            this._pubSubSubscribed.unsubscribe();
            this._pubSubSubscribed = undefined;
            this._clearMqttConfig();
        }
    }

    _clearMqttConfig() {
        this._mqttConnection = false;
        this._mqttSubscribedTopics = [];
    }
}