import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery'

import * as Dimens from '../styles/dimens';
import * as Colors from '../styles/colors';
import Column from '../components/layout/column';
import Row from '../components/layout/row';
import Stack from '../components/layout/stack';
import { Dialog, ButtonBase } from '@material-ui/core';
import Lottie from 'react-lottie';

import AnimLoading from '../assets/lottie/textfield_loading.json';

LoadingDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
    text: PropTypes.string.isRequired,
}

LoadingDialog.defaultProps = {
    open: false,
    text: '請稍後',
}

export default function LoadingDialog(props) {

    const classes = useStyles(props);
    const matches = useMediaQuery('(min-width: 768px)');

    return (() => {
        const size = matches ? '200px' : '200px';
        return (
            <Dialog onClose={props.onClose} open={props.open}>
                <Stack width={size} height={size} className={classes.root} onTouchMove={(event) => { event.preventDefault(); }}>
                    <Column width={size} height={size} className={classes.contentLayout}>
                        <Lottie
                            isClickToPauseDisabled
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: AnimLoading,
                                rendererSettings: {
                                    preserveAspectRatio: 'xMidYMid slice'
                                }
                            }}
                            width='50%'
                            height='auto' />
                        <span className={classes.animText}>{props.text}</span>
                    </Column>
                </Stack>
            </Dialog>
        );
    })()
}

const useStyles = makeStyles({
    root: {
    },
    contentLayout: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    animText: {
        color: Colors.textBLight,
        fontSize: Dimens.textM,
        fontWeight: 'bold',
        marginTop: `${Dimens.marginM}px`,
    }
});