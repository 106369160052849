import React from 'react';
import { interpolateHcl as interpolateGradient } from 'd3-interpolate';

export function makeId(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function calculateArcColor(index0, segments, graidenColorFrom, gradientColorTo) {
    const interpolate = interpolateGradient(graidenColorFrom, gradientColorTo);

    return {
        fromColor: interpolate(index0 / segments),
        toColor: interpolate((index0 + 1) / segments),
    }
}

export function calculateArcCircle(index0, segments, radius, startAngle0 = 0, angleLength0 = 2 * Math.PI) {
    // Add 0.0001 to the possible angle so when start = stop angle, whole circle is drawn
    // console.log(`calculateArcCircle => index0: ${index0}, segments: ${segments}, radius: ${radius}, startAngle0: ${startAngle0}, angleLength0: ${angleLength0}`);
    const startAngle = startAngle0 % (2 * Math.PI);
    const angleLength = angleLength0 % (2 * Math.PI);
    const index = index0 + 1;
    const fromAngle = angleLength / segments * (index - 1) + startAngle;
    const toAngle = angleLength / segments * index + startAngle;
    const fromX = radius * Math.sin(fromAngle);
    const fromY = -radius * Math.cos(fromAngle);
    const realToX = radius * Math.sin(toAngle);
    const realToY = -radius * Math.cos(toAngle);

    // add 0.005 to start drawing a little bit earlier so segments stick together
    const toX = radius * Math.sin(toAngle + 0.005);
    const toY = -radius * Math.cos(toAngle + 0.005);

    return {
        fromX,
        fromY,
        toX,
        toY,
        realToX,
        realToY,
    };
}

export function getGradientId(id, index) {
    return `${id}-graidnet${index}`;
}

export function getDefaultStartIcon() {
    return (
      <g>
        <path d="M11.7,10.5c-3.6,0-6.4-2.9-6.4-6.4c0-0.7,0.1-1.4,0.4-2.1C3.1,2.9,1.2,5.3,1.2,8.1c0,3.6,2.9,6.4,6.4,6.4
        c2.8,0,5.2-1.8,6.1-4.4C13.1,10.4,12.4,10.5,11.7,10.5z" />
        <path d="M8,7.6l2-2.5H8V4.4H11v0.6L9,7.6h2v0.7H8V7.6z" />
        <path d="M11.7,5.4l1.5-1.9h-1.4V3h2.2v0.5l-1.5,1.9h1.5v0.5h-2.2V5.4z" />
        <path d="M9.4,3l1.1-1.4h-1V1.3H11v0.4L9.9,3H11v0.4H9.4V3z" />
      </g>
    );
}

export function getDefaultStopIcon() {
    return (
      <g>
        <path d="M2,12.9h1.7h3h2.7h3H14c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c-0.9,0-1.7-0.7-1.7-1.7v-4
        c0-2.1-1.5-3.8-3.4-4.2C9,1.6,9,1.4,9,1.3c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1c0,0.2,0,0.3,0.1,0.4c-2,0.4-3.4,2.1-3.4,4.2v4
        c0,0.9-0.7,1.7-1.7,1.7c-0.4,0-0.7,0.3-0.7,0.7C1.3,12.6,1.6,12.9,2,12.9z" />
        <path d="M8,15.7c1.1,0,2.1-0.9,2.1-2.1H5.9C5.9,14.8,6.9,15.7,8,15.7z" />
      </g>
    );
}

export function roundAngleToFives(angle) {
    const fiveMinuteAngle = 2 * Math.PI / 720;
  
    return Math.round(angle / fiveMinuteAngle) * fiveMinuteAngle;
}

function calculateMinutesFromAngle(angle) {
  return Math.round(angle / (2 * Math.PI / (2 * 12 * 12))) * 5;
}

export function calculateTimeFromAngle(angle) {
  const minutes = calculateMinutesFromAngle(angle);
  const h = Math.floor(minutes / 60);
  const m = minutes - h * 60;

  return { h: h % 24, m: m };
}

export function calculateStartFromTime({h, m}) {
  const min = h * 60 + m;
  return Math.PI * 0.5 / 6 / 60 * min;
}

export function calculateLenFromTime({start, end}) {
  let min = (end.h * 60 + end.m) - (start.h * 60 + start.m);
  if (min < 0) min = min + 1440;
  return Math.PI * 0.5 / 6 / 60 * min;
}